import React, { memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useMedia } from "../../hooks/useMedia";
import { CardState } from "../../../../../store/gameBoard/game.constants";
import { Preview, usePreview } from "react-dnd-preview";
import { getOffSet } from "../../common/_helpers";

const createPreviewCards = (draggedCard, columnPiles) => {
  if (draggedCard) {
    const indexOfCard = columnPiles[draggedCard.index].findIndex(
      (card) => card.id === draggedCard.id
    );
    return columnPiles[draggedCard.index].filter(
      (card, index) => index >= indexOfCard
    );
  }
  return [];
};

const CardDragPreview = () => {
  const { columnPiles } = useSelector((state) => state.gameBoard);
  const [draggedCard, setDraggedCard] = useState(null);

  const memoizedPreview = useMemo(
    () => createPreviewCards(draggedCard, columnPiles),
    [draggedCard, columnPiles]
  );

  const xs = useMedia("(max-width: 525px)");
  const sm = useMedia("(max-width: 710px)");
  const md = useMedia("(max-width: 940px)");

  const getOffsetByMedia = useCallback(() => {
    if (xs) {
      return 15;
    } else if (sm) {
      return 21;
    } else if (md) {
      return 27;
    } else return 33;
  }, [xs, sm, md]);

  const generatePreview = ({ itemType, item, style }) => {
    if (item && item.card) {
      setDraggedCard(item.card);
    }

    if (item && item.card.state === CardState.TableuPile) {
      return (
        <div style={{ ...style, zIndex: 50 }}>
          {memoizedPreview.map((card, i) => (
            <img
              alt={item.card.id}
              key={card.id}
              className="card__image"
              src={require(`../../../../../assets/cards/${card.id}.png`)}
              style={{ top: getOffSet(i, getOffsetByMedia()) }}
            />
          ))}
        </div>
      );
    }
    return (
      <img
        alt={item.card.id}
        className="card__image"
        src={require(`../../../../../assets/cards/${item.card.id}.png`)}
        style={{ ...style, top: getOffSet(0, getOffsetByMedia()), zIndex: 50 }}
      />
    );
  };

  return (
    <>
      <Preview generator={generatePreview} />
    </>
  );
};

export default memo(CardDragPreview);
