import { Icon } from "@iconify/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TOTAL_ALLOCATE_GAME_REDO_COUNT } from "../../../../../store/gameBoard/game.constants";
import gameBoardActions from "../../../../../store/gameBoard/gameBoard.actions";

const UndoButton = ({ refreshNewGame }) => {
  const dispatch = useDispatch();
  const { gamePreviousMoves, gameNextMoves, isGameOver } = useSelector(
    ({ gameBoard }) => ({
      gamePreviousMoves: gameBoard.gamePreviousMoves,
      gameNextMoves: gameBoard.gameNextMoves,
      isGameOver: gameBoard.isGameOver,
    })
  );

  // commented this code after change logic by client on 19-08-2023
  const handleUndo = () => {
    const nMoves = gamePreviousMoves.length;
    // can only undo when there are moves to go back
    if (nMoves > 0) {
      const { source, target, cards, targetIndex, sourceIndex } =
        gamePreviousMoves[nMoves - 1];
      // undo moves that were from the deck pile to another pile
      // basically, send the card back from that pile to the deck pile

      if (source === "deckPile") {
        if (target === "flippedPile") {
          // call deck function to send back a flipped card to the deck pile
          dispatch(gameBoardActions.undoFlipDeckPile());
        } else if (target.includes("goal")) {
          // goal pile -> deck
          // call goal function to remove card from the respective goal pile
          dispatch(gameBoardActions.removeCardFromGoal(targetIndex));
          // then add it to the flipped pile
          dispatch(gameBoardActions.addCardToFlipped(cards[0]));
        } else if (target.includes("column")) {
          // column pile -> deck
          // call column function to remove card from the respective column pile
          dispatch(
            gameBoardActions.removeNCardsFromColumn(targetIndex, cards.length)
          );
          // then add it to the flipped pile
          dispatch(gameBoardActions.addCardToFlipped(cards[0]));
        }
      } else if (source.includes("column")) {
        if (target.includes("column")) {
          // call column function to remove card from one card pile to another directly
          dispatch(
            gameBoardActions.undoSwapColumns(
              sourceIndex,
              targetIndex,
              cards.length
            )
          );
        } else if (target.includes("goal")) {
          // goal pile -> column pile
          // call goal function to remove card from the respective goal pile
          dispatch(gameBoardActions.removeCardFromGoal(targetIndex));
          dispatch(gameBoardActions.addCardToColumn(sourceIndex, cards[0]));
        }
      } else if (source.indexOf("goal") === 0) {
        if (target.indexOf("column") === 0) {
          // column pile -> goal pile
          // call goal function to remove card from the repective column pile
          dispatch(gameBoardActions.removeNCardsFromColumn(targetIndex, 1));
          // add removed card to the corresponding goal
          dispatch(gameBoardActions.addCardToGoal(sourceIndex, cards[0]));
        }
      }

      // remove the movement from the moves array
      dispatch(gameBoardActions.removeGameMove());
      // create new refresh game on undo click as per discussion - 19-08-2023
      refreshNewGame();
    }
  };

  return (
    <div className="first_box" style={{ padding: "4px 10px" }}>
      <button
        disabled={
          gamePreviousMoves.length === 0 ||
          gameNextMoves.length === TOTAL_ALLOCATE_GAME_REDO_COUNT ||
          isGameOver
        }
        type="button"
        className={`main_hand b__none ${
          gamePreviousMoves.length === 0 ||
          gameNextMoves.length === TOTAL_ALLOCATE_GAME_REDO_COUNT ||
          isGameOver
            ? "disable__opacity"
            : ""
        }`}
        // onClick={handleUndo}
        onClick={handleUndo}
      >
        <Icon icon="material-symbols:refresh" />
        <span>Redo Hand</span>
      </button>
      <div>
        <span>
          Remaining :{TOTAL_ALLOCATE_GAME_REDO_COUNT - gameNextMoves.length}
        </span>
      </div>
    </div>
  );
};

export default UndoButton;
