import React from "react";
import { Modal } from "react-bootstrap";
import { TOTAL_ALLOCATE_GAME_HAND } from "../../../../../store/gameBoard/game.constants";
import { useSelector } from "react-redux";

const GameCompleteModal = ({ show, info }) => {
  const { stockPiles, foundations } = useSelector(({ gameBoard }) => ({
    foundations: gameBoard.foundations,
    stockPiles: gameBoard.stockPiles,
  }));

  function removeMatchingCards(allCard, foundationCard) {
    const foundationIds = foundationCard.flatMap((stack) =>
      stack.map((card) => card.id)
    );
    return allCard.filter((card) => !foundationIds.includes(card.id));
  }

  const updatedDeckpile = removeMatchingCards(stockPiles, foundations);

  return (
    <div>
      <Modal
        centered
        className="border-0 border-radius-0 lobby_modal"
        show={show}
        backdrop
      >
        <Modal.Body className="border-0 p-0">
          <div className="pass_word_c">
            <p>
              Hand {info.currentGameHand} of {TOTAL_ALLOCATE_GAME_HAND}
            </p>
            <p>Moves: {info.gameMoves}</p>
            <p>Cards left: {updatedDeckpile.length}</p>
            <p className="mt-3">Game complete!</p>
            <p className="mb-3 mt-1 time_red">
              You will automatically proceed to the results when all other
              players are finished.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GameCompleteModal;
