import React from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export const PaymentConfirmModal = (props) => {
  let history = useHistory();

  return (
    <div>
      <Modal
        centered
        className="border-0 border-radius-0 lobby_modal"
        show={props.show}
        onHide={props.onHide}
      >
        <Modal.Header className="border-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0 p-0">
          <div className="pass_word_c">
            <h5 className="mb-1">
              Balance: ${props.user_condition.wallet_balance}
            </h5>
            <span className="mb-2 d-block"></span>
            <p className="mb-5">
              Players must have at least ${props.user_condition.mini_balance} in
              their wallet to join
            </p>
          </div>

          <div className="main_btn close_kl bank_btn mt-3">
            <button onClick={props.onHide} className="">
              Cancel
            </button>
            <button className="" onClick={() => history.push("/lobby/payment")}>
              <span>Top Up</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
