import React from "react";
import { useSelector } from "react-redux";
import Card from "../Card/Card";

const FlippedPile = () => {
  // const dispatch = useDispatch();
  const { flippedPile } = useSelector((state) => state.gameBoard);

  const getLastThree = (index, flippedPile) => {
    if (index === flippedPile.length - 1) {
      return flippedPile.length > 2 ? 40 : 20;
    } else if (index === flippedPile.length - 2) {
      return flippedPile.length > 2 ? 20 : 0;
    } else {
      return 0;
    }
  };

  const getCards = () => {
    return flippedPile?.map((card, index) => {
      return (
        <Card
          key={card.id}
          card={{ ...card, positionIndex: index }}
          isLastCard={index === flippedPile.length - 1}
          nCards={1}
          leftOffset={getLastThree(index, flippedPile)}
        />
      );
    });
  };

  return (
    <>
      {flippedPile.length > 0 ? (
        <div className="drop-target">{getCards()}</div>
      ) : (
        <div className="card_put"></div>
      )}
    </>
  );
};

export default FlippedPile;
