import React from "react";
import Deck from "./Deck";
import GoalPileWrapper from "./GoalPileWrapper";

const GameTopRow = () => {
  return (
    <div className="mini_open_op mb-3">
      <GoalPileWrapper />
      <Deck />
    </div>
  );
};

export default GameTopRow;
