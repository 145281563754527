import React, { useCallback } from "react";
import {
  CardState,
  typeOfCard,
} from "../../../../../store/gameBoard/game.constants";
import { useMedia } from "../../hooks/useMedia";
import Card from "../Card/Card";
import { useDrop } from "react-dnd";
import { getOffSet } from "../../common/_helpers";

const getDroppable = (item, cards) => {
  if (cards.length === 0 && item && item.card && item.card.rank === 13) {
    return true;
  } else if (cards.length === 0) {
    return false;
  } else if (
    item &&
    item.card &&
    item.card.rank === cards[cards.length - 1].rank - 1 &&
    item.card.color !== cards[cards.length - 1].color
  ) {
    return true;
  }
  return false;
};

const ColumnPile = ({ cards, index }) => {
  const nextState = CardState.TableuPile;
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: typeOfCard,
    canDrop: (item) => getDroppable(item, cards),
    drop: () => ({ index, nextState }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const xs = useMedia("(max-width: 525px)");
  const sm = useMedia("(max-width: 710px)");
  const md = useMedia("(max-width: 940px)");

  const getOffsetByMedia = useCallback(() => {
    if (xs) {
      return 15;
    } else if (sm) {
      return 21;
    } else if (md) {
      // return 27;
      return 18;
    } else {
      // return 33;
      return 18;
    }
  }, [xs, sm, md]);

  return (
    <>
      {/* {cards.length > 0 ? ( */}
      <div ref={drop} className="drop-target ">
        <div className="card-border">
          {cards?.map((card, i) => (
            <Card
              offset={getOffSet(i, getOffsetByMedia())}
              key={card.id}
              card={card}
              isLastCard={i === cards.length - 1 ? true : false}
              nCards={cards.length - i} //temp
            />
          ))}
        </div>
      </div>
      {/* ) : ( */}
      {/* <div className="mine_card"></div> */}
      {/* )} */}
    </>
  );
};

export default ColumnPile;
