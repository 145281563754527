// import * as Yup from "yup";
// import { imageObj } from "../utiles/images";
// import { useFormik } from "formik";
// import { Alert, Button, Col, Form, Row } from "react-bootstrap";
// import { getTransactionLists } from "../store/actions/userAction";
// import { paymentBankToWallet } from "../store/actions/paymentAction";
// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// import { getSession } from "../store/actions/authAction";
// import { useHistory } from "react-router-dom";

import "./Css-file.css";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import SiteLoader from "./shared/SiteLoader/SiteLoader";
import TransferSuccessModal from "./models/TransferSuccessModal";
import imageurl from "./common/images";


function BankTransfer() {
  // const history = useHistory();
  // let { user_id } = getSession();
  // const dispatch = useDispatch();
  // const { walletList } = useSelector((state) => state.transaction);
  const [addedAmount, setAddedAmount] = useState(0);
  const [open, setOpen] = useState(false);
  const { loading } = useSelector((state) => state.payment);

  // useEffect(() => {
  //   dispatch(getTransactionLists());
  // }, [dispatch]);

  // const formik = useFormik({
  //   initialValues: {
  //     account_holder_name: "",
  //     account_number: "",
  //     bank_name: "",
  //     amount: "",
  //     user_id: user_id,
  //     description: "",
  //     // bank_ifsc: ''
  //   },

  //   validationSchema: Yup.object({
  //     account_number: Yup.string()
  //       .required("Card number is required")
  //       .matches(phoneRegExp, "Card number is not valid"),

  //     bank_name: Yup.string().required("Enter Bank Name"),
  //     // bank_ifsc: Yup.string().required('Enter Bank IFSC Code'),
  //     account_holder_name: Yup.string().required("Enter Account Holder Name"),

  //     amount: Yup.string()
  //       .required("Amount is required")
  //       .test("is-valid-year", "Insufficient balance", (value) => {
  //         const numericValue = parseInt(value, 10);
  //         return (
  //           !isNaN(numericValue) && numericValue <= walletList.wallet_balance
  //         );
  //       }),
  //     description: Yup.string().required("Enter Description"),
  //   }),

  //   onSubmit: (values, action) => {
  //     dispatch(paymentBankToWallet(values, history)).then((result) => {
  //       if (result.success) {
  //         setOpen(true);
  //         setAddedAmount(formik.values.amount);
  //         action.resetForm();
  //       } else {
  //         setOpen(false);
  //       }
  //     });
  //   },
  // });

  // useEffect(() => {
  //   if (dibetData.status) {
  //     history.push('/lobby')
  //   }
  // }, [dibetData])

  const handleCloseModal = () => {
    setOpen(false);
    setAddedAmount(0);
  };

  return (
    <section className="card-box-section d-flex justify-content-center pb-5 login_box">
      <TransferSuccessModal
        show={open}
        amount={addedAmount}
        handleClose={handleCloseModal}
      />
      <div
        className=" card_box_1 d-flex align-items-center justify-content-center mt-5"
        style={{ background: "white" }}
      >
        {loading ? <SiteLoader /> : null}

        <div className="card_box_2">
          <img style={{ width: "25%",  }} src={imageurl.coin} alt="" />

          <div className="h4">
            Currency & Funds
          </div>
          <div className="h6 text-center">
            As of the current version of Casino Solitiare, all funds used within the platform are game currency,not USD currency.
          </div>


          {/* <Form onSubmit={formik.handleSubmit}>
            <Form.Group>
              <Form.Label
                className=" heading_label p-2 "
                style={{ fontSize: "18px", color: "#000" }}
              >
                Account Number
              </Form.Label>

              <Form.Control
                type="text"
                name="account_number"
                value={formik.values.account_number}
                onChange={formik.handleChange}
                maxLength={20}
              />

              {formik.touched.account_number &&
                formik.errors.account_number && (
                  <span className="text-danger">
                    {formik.errors.account_number}
                  </span>
                )}
            </Form.Group>
            <Form.Group>
              <Form.Label
                className=" heading_label p-2 "
                style={{ fontSize: "18px", color: "#000" }}
              >
                Bank Name
              </Form.Label>

              <Form.Control
                type="text"
                name="bank_name"
                value={formik.values.bank_name}
                onChange={formik.handleChange}
              />

              {formik.touched.bank_name && formik.errors.bank_name && (
                <span className="text-danger">{formik.errors.bank_name}</span>
              )}
            </Form.Group>

        
            <Form.Group>
              <Form.Label
                className="heading_label p-2"
                style={{ fontSize: "18px" }}
              >
                Full Name on a Card
              </Form.Label>
              <Form.Control
                type="text"
                name="account_holder_name"
                value={formik.values.account_holder_name}
                onChange={formik.handleChange}
                placeholder="Enter Holder Name"
              />
              {formik.touched.account_holder_name &&
                formik.errors.account_holder_name && (
                  <span className="text-danger">
                    {formik.errors.account_holder_name}
                  </span>
                )}
            </Form.Group>

            <Form.Group>
              <Form.Label
                className="heading_label p-2"
                style={{ fontSize: "18px" }}
              >
                Amount
              </Form.Label>
              <Form.Control
                type="text"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                placeholder="Enter amount"
              />
              {formik.touched.amount && formik.errors.amount && (
                <span className="text-danger">{formik.errors.amount}</span>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label
                className=" heading_label p-2 "
                style={{ fontSize: "18px", color: "#000" }}
              >
                Description
              </Form.Label>

              <Form.Control
                type="text"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                placeholder="Enter description"
              />

              {formik.touched.description && formik.errors.description && (
                <span className="text-danger">{formik.errors.description}</span>
              )}
            </Form.Group>

            <Row>
              <Col>
                <Link
                  to="/lobby/my-wallet"
                  style={{
                    width: "90%",
                    marginTop: "10px",
                    background: "#E6EBF0",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                  className="btn btn-primary"
                >
                  Cancel{" "}
                </Link>
              </Col>
              <Col>
                <Button
                  type="submit"
                  style={{
                    width: "90%",
                    marginTop: "10px",
                    marginLeft: "30px",
                    background: "#000",
                  }}
                >
                  Complete Transaction{" "}
                </Button>
              </Col>
            </Row>
          </Form> */}
        </div>
      </div>

    </section>
  );
}

export default BankTransfer;
