import React, { useEffect, useState } from "react";
// import "./Gameboard.css";
import GameTopRow from "./components/BoardField/GameTopRow";
import GameColumnWrapper from "./components/BoardField/GameColumnWrapper";
import GameOptions from "./components/BoardField/GameOptions";
import { useDispatch, useSelector } from "react-redux";
import gameBoardActions from "../../../store/gameBoard/gameBoard.actions";
import CardDragPreview from "./components/Card/CardDragPreview";
import { useHistory, useLocation } from "react-router-dom";
import {
  getGameLobbyInfo,
  resetGameLobbyInfo,
} from "../../../store/common/utils";
import { MESSAGES, NAVIGATION } from "../../common/constant";
import RouteLeavingGuard from "../../../routes/RouteLeavingGuard";
import { TOTAL_ALLOCATE_GAME_HAND } from "../../../store/gameBoard/game.constants";
// import { observeLobbyForCheckGameStatus } from "../../../store/actions/firebaseAction";
import {
  completeGame,
  updateWinLossAmount,
} from "../../../store/actions/gameBoardAction";
import { toast } from "react-toastify";
// import CustomDragLayer from "./components/Card/CustomDragLayer";

const GameBoard = () => {
  const dispatch = useDispatch();
  const [showGameCompleteModal, setGameCompleteModal] = useState(false);
  const [startObserve, setStartObserve] = useState(false);
  const [canResultAnounced, setCanResultAnounced] = useState(false);
  const history = useHistory();
  
  const { currentGameHand, isGameOver } = useSelector(({ gameBoard }) => ({
    gameMoves: gameBoard.gameMoves,
    currentGameHand: gameBoard.currentGameHand,
    isGameOver: gameBoard.isGameOver,
  }));

  const query = new URLSearchParams(useLocation().search);
  let { lobby_unique_identifier, lobby_game_current_hand } = getGameLobbyInfo();

  /**
   * Triggered when the component is mounted
   * And either creates a new random game
   */

  const mountGameBoard = () => {
    //game access when identifier exist in session
    if (lobby_unique_identifier === null || !query.has("gameId")) {
      history.push(NAVIGATION.LOBBY);
      return;
    }
    //if identifier exist but not match with session id
    if (
      query.has("gameId") &&
      query.get("gameId") !== lobby_unique_identifier
    ) {
      history.push(NAVIGATION.LOBBY);
      return;
    }
    // if (history.action !== "POP") {
    // create new deck
    dispatch(gameBoardActions.createGame());
    //start one game
    dispatch(gameBoardActions.addGame());

    //set existing game move
    // }

    if (lobby_game_current_hand > 1)
      dispatch(gameBoardActions.setGameCurrentHand(lobby_game_current_hand));
  };

  useEffect(mountGameBoard, []);

  /**
   * Triggered by the game current hand
   * When a *new* game starts, it is only added to the users count when at least a move is done
   */
  const checkCurrentHand = () => {
    if (
      isGameOver &&
      currentGameHand === TOTAL_ALLOCATE_GAME_HAND &&
      !showGameCompleteModal
    ) {
      setGameCompleteModal(true);
      //start process of firebase listner
      setStartObserve(true);
    }
    //check if game is anounces
    if (
      isGameOver &&
      currentGameHand === TOTAL_ALLOCATE_GAME_HAND &&
      showGameCompleteModal &&
      canResultAnounced
    ) {
      setGameCompleteModal(false);
      resetGameLobbyInfo();
      updateWinLossAmount()
        .then((result) => {
          if (result.success) {
            //call complete game api
            dispatch(completeGame());
            history.push(
              `${NAVIGATION.GAME_RESULT}/${lobby_unique_identifier}`
            );
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };
  useEffect(checkCurrentHand, [currentGameHand, isGameOver, canResultAnounced]);

  /**
   * Triggered by the game moves
   * When a *new* game starts, it is only added to the users count when at least a move is done
   */
  // const addGameToUser = () => {
  //   if (gameMoves === 1) {
  //     dispatch(gameBoardActions.addGame());
  //   }
  // };
  // useEffect(addGameToUser, [gameMoves]);

  //timer observer
  useEffect(() => {
    let interval;
    if (lobby_unique_identifier && startObserve) {
      //custom timer interval logic
      interval = setInterval(() => {
        const currentTime = Math.round(Date.now() / 1000);
        //start comparision time
        if (Number(query.get("endGameTime")) < currentTime) {
          setCanResultAnounced(true);
        }
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [startObserve, canResultAnounced]);

  //function to check all players complete game or not

  // let unsbscribe;

  // async function checkAllPlayerCompleteGame() {
  //   unsbscribe = await observeLobbyForCheckGameStatus(
  //     lobby_unique_identifier,
  //     setCanResultAnounced
  //   );
  // }

  //firebase observer
  // useEffect(() => {
  //   if (lobby_unique_identifier && startObserve) {
  //     checkAllPlayerCompleteGame();
  //   }
  //   //unsubscribe condition
  //   if (unsbscribe && !startObserve) {
  //     unsbscribe();
  //   } else if (unsbscribe && startObserve && canResultAnounced) {
  //     unsbscribe();
  //   }

  //   return () => {
  //     if (unsbscribe) unsbscribe();
  //   };
  // }, [startObserve, canResultAnounced]);

  //prevent page refresh
  // useEffect(() => {
  //   const unloadCallback = (event) => {
  //     console.log(event);
  //     event.preventDefault();
  //     event.returnValue = "";
  //     return "";
  //   };

  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => window.removeEventListener("beforeunload", unloadCallback);
  // }, []);

  return (
    <div>
      <RouteLeavingGuard
        when={true}
        navigate={(path) => {
          history.push(path);
        }}
        shouldBlockNavigation={(location) => {
          if (
            location.pathname ===
            `${NAVIGATION.GAME_RESULT}/${lobby_unique_identifier}`
          ) {
            return false;
          } else if (lobby_unique_identifier === null) {
            return false;
          } else if (location.pathname === NAVIGATION.GAME_BOARD) {
            return false;
          }
          return true;
        }}
        yes="Yes"
        no="No"
        message={MESSAGES.LOBBY_LEAVE_MSG}
      />

      <div className="game_bord">
        <div className="open_border_bord">
          <div className="open_border_bord" style={{ padding: "70px" }}>
            <div className="w-100">
              {/* Game top row */}
              <GameTopRow />
              {/* <CustomDragLayer /> */}
              {/* bottom row of the game, includes all the 7 columns */}
              <GameColumnWrapper />
              {/* Drag card preview */}
              <CardDragPreview />
            </div>
          </div>
        </div>
        <div className="design_box" style={{ zIndex: -1 }}></div>
      </div>

      {/* game controll options */}
      <GameOptions
        showGameCompleteModal={showGameCompleteModal}
        setGameCompleteModal={setGameCompleteModal}
      />
    </div>
  );
};

export default GameBoard;
