import { BASE_NAME } from "../../store/common/constants";

const imageurl = {
  logo: BASE_NAME + "/assets/images/casinologo.svg",
  hero_banner: BASE_NAME + "/assets/images/banner.svg",
  leftleaf: BASE_NAME + "/assets/images/left_btn.svg",
  footlogo: BASE_NAME + "/assets/images/footlogo.svg",
  rightleaf: BASE_NAME + "/assets/images/right_btn.svg",
  profilrdefault: BASE_NAME + "/assets/images/profiledefault.png",
  pokercard: BASE_NAME + "/assets/images/poker-cards.svg",
  bankimg: BASE_NAME + "/assets/images/bank.png",

  history: BASE_NAME + "/assets/images/clock.svg",
  trophy: BASE_NAME + "/assets/images/trophy.svg",
  wallet: BASE_NAME + "/assets/images/wallet.svg",
  question: BASE_NAME + "/assets/images/question.svg",

  acc_set: BASE_NAME + "/assets/images/user.svg",
  terms: BASE_NAME + "/assets/images/terms-and-conditions.svg",
  privacy: BASE_NAME + "/assets/images/compliant.svg",
  contact: BASE_NAME + "/assets/images/support.svg",
  logout: BASE_NAME + "/assets/images/logout.svg",

  connectstripe: BASE_NAME + "/assets/images/stripe.png",

  slide1: BASE_NAME + "/assets/images/slideone.png",
  slide2: BASE_NAME + "/assets/images/slidetwo.png",
  slide3: BASE_NAME + "/assets/images/slide3.png",
  slide4: BASE_NAME + "/assets/images/slide4.png",
  slide5: BASE_NAME + "/assets/images/slide5.png",
  res: BASE_NAME + "/assets/images/confetti.svg",
  res1: BASE_NAME + "/assets/images/nakul.svg",

  success: BASE_NAME + "/assets/images/success.gif",
  connection_lost: BASE_NAME + "/assets/images/connection-lost.png",
  success_tick: BASE_NAME + "/assets/images/success-tick.svg",
  coin: BASE_NAME + "/assets/images/coin-img.png",


  banner1: BASE_NAME + "/assets/images/banner1.png",
  banner2: BASE_NAME + "/assets/images/banner2.png",
  banner3: BASE_NAME + "/assets/images/banner3.png",
  banner4: BASE_NAME + "/assets/images/banner4.png",
  banner5: BASE_NAME + "/assets/images/banner5.png",
  banner6: BASE_NAME + "/assets/images/banner6.png",
  banner7: BASE_NAME + "/assets/images/banner7.png",


};
export default imageurl;
