import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOrdinalSuffix } from "../../store/common/utils";

const RankModal = ({ show, handleClose }) => {
  const history = useHistory();
  const { rank_info } = useSelector((state) => state.game);
  return (
    <>
      <Modal
        className="border-0 border-radius-0 custom_modal"
        show={show}
        centered
        size="md"
        onHide={handleClose}
      >
        <Modal.Body className="border-0 p-0">
          <div className="p-5">
            {rank_info.rank === 1 ? (
              <div className="text-center">
                <h6>Congratulations, you placed </h6>

                <h5 className="pb-4 mb-3 dashed_border_bottom">
                  {getOrdinalSuffix(rank_info.rank)}
                </h5>

                <h4
                  className={`${
                    rank_info.amount >= 0 ? "amount" : "lost_amount"
                  } mb-3`}
                >
                  ${rank_info.amount}
                </h4>
                <p className="mb-3">
                  {rank_info.amount > 0
                    ? "has been added to your balance."
                    : "has been deducted to your balance."}
                </p>
                <h4 className="amount mb-3">{rank_info.point}</h4>
                <p>points have been added to your ranked score.</p>
              </div>
            ) : (
              <div className="text-center">
                <h6>Tough luck, you lost the game. Don't be discouraged!</h6>

                <h5 className="pb-4 mb-2 dashed_border_bottom">
                  {getOrdinalSuffix(rank_info.rank)}
                </h5>

                <h4 className="lost_amount mb-3">${rank_info.amount}</h4>
                <p className="mb-3">has been removed to your balance.</p>
                <h4 className="mb-3">{rank_info.point}</h4>
                <p>points have been added to your ranked score.</p>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center main_btn close_kl button bank_btn">
            <button
              onClick={() => {
                history.push("/lobby/my-wallet");
                handleClose();
              }}
            >
              View Balance
            </button>
            <button
              onClick={() => {
                history.push("/lobby");
                handleClose();
              }}
            >
              Play Again
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RankModal;
