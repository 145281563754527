import {
  PAYMENT_LOADING,
  PAYMENT_METHOD_ADD_WALLET,
  PAYMENT_METHOD_ADD_WALLET_TO_BANK,
} from "../common/types";

const initialState = {
  error: "",
  loading: false,
  paymentData: {},
  dibetData: {},
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PAYMENT_METHOD_ADD_WALLET:
      return {
        ...state,
        paymentData: action.payload,
        loading: false,
      };
    case PAYMENT_METHOD_ADD_WALLET_TO_BANK:
      return {
        ...state,
        dibetData: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
