import React from "react";
import { Modal } from "react-bootstrap";
import imageurl from "../../../common/images";
export const Profilesuccess = (props) => {
  return (
    <div>
      <Modal
        className="border-0 border-radius-0"
        show={props.show}
        onHide={props.onHide}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0">
          <div className="pass_word_c">
            <img src={imageurl.success} alt="success-img" />
          </div>
          <div className="pass_word_c">
            <span>Success!</span>
            <h5>Your information has been updated.</h5>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
