import GameBoardActionTypes from "./gameBoard.types";

/*
 * Creates an initial distribution of the cards
 */
const createGame = () => ({
  type: GameBoardActionTypes.CREATE_GAME,
});

/**
 * Flips one card from the deck pile to the flipped pile
 */
const flipDeckPile = () => ({
  type: GameBoardActionTypes.FLIP_DECK_PILE,
});

/**
 * Resets the deck, setting all the flipped cards back to the deck
 */
const resetDeck = () => ({
  type: GameBoardActionTypes.RESET_DECK,
});

/**
 * Starts dragging the top card of the flipped pile
 */
const dragFlippedCard = (payload) => ({
  type: GameBoardActionTypes.DRAG_FLIPPED_CARD,
  payload,
});

/**
 * Adds the cards that were being dragged to the selected column
 */
const addDraggingCardsToColumn = (payload) => ({
  type: GameBoardActionTypes.ADD_DRAGGING_CARDS_TO_COLUMN,
  payload,
});

/**
 * Adds the cards that were being dragged to the selected goal
 */
const addDraggingCardsToGoal = (payload) => ({
  type: GameBoardActionTypes.ADD_DRAGGING_CARDS_TO_GOAL,
  payload,
});

//TODO: deleted after test
const turnCard = (payload) => ({
  type: GameBoardActionTypes.TURN_CARD,
  payload,
});

const handleDoubleClick = (payload) => ({
  type: GameBoardActionTypes.CHECK_DOUBLE_CLICK_VALID,
  payload,
});

// ********************************************************
// GAME MOVES' HISTORY ACTIONS

/**
 * Adds a move to the total moves of the game
 * @param source field where the move started
 * @param target field where the move ended
 * @param cards cards that where exchanged (can be undefined for deck reset moves)
 */
const addGameMove = (move) => ({
  type: GameBoardActionTypes.ADD_GAME_MOVE,
  move,
});

//user & game related action
const addGame = () => ({
  type: GameBoardActionTypes.ADD_GAME,
});

// deck action
/**
 * Flips one card back from the flipped pile to the deck pile
 */
const undoFlipDeckPile = () => ({
  type: GameBoardActionTypes.UNDO_FLIP_DECK_PILE,
});

/**
 * Removes a move from the list of game moves
 */
const removeGameMove = () => ({
  type: GameBoardActionTypes.REMOVE_GAME_MOVE,
});

/**
 * Removes 1 cards from a goal pile
 * @param goalId id of the column to be reduced of cards
 */
const removeCardFromGoal = (foundationIndex) => ({
  type: GameBoardActionTypes.REMOVE_CARD_FROM_GOAL,
  foundationIndex,
});

/**
 * Sends a card to a column pile
 * @param columnId id of the column receiving the card
 * @param card card to be added to a column pile
 * @param flip previous move caused a flip of the column
 */
const addCardToColumn = (columnIndex, card) => ({
  type: GameBoardActionTypes.ADD_CARD_TO_COLUMN,
  columnIndex,
  card,
});

/**
 * Removes N cards from a column pile
 * @param columnId id of the column to remove the cards from
 * @param nCards number of cards to remove
 */
const removeNCardsFromColumn = (columnIndex, nCards) => ({
  type: GameBoardActionTypes.REMOVE_N_CARDS_FROM_COLUMN,
  columnIndex,
  nCards,
});

/**
 * Sends a card to a flipped pile
 * @param card card to be added to the flipepd pile
 */
const addCardToFlipped = (card) => ({
  type: GameBoardActionTypes.ADD_CARD_TO_FLIPPED,
  card,
});

/**
 * Undo swap of columns, sends back nCards from the target column to the source column
 * @param source column where the cards originally came from
 * @param target column where the cards originally were sent to
 * @param nCards number of cards that were swapped
 */
const undoSwapColumns = (sourceIndex, targetIndex, nCards) => ({
  type: GameBoardActionTypes.UNDO_SWAP_COLUMNS,
  sourceIndex,
  targetIndex,
  nCards,
});

/**
 * Sends a card to a goal pile
 * @param goalId id of the goal receiving the card
 * @param card card to be added to a goal pile
 */
const addCardToGoal = (sourceIndex, card) => ({
  type: GameBoardActionTypes.ADD_CARD_TO_GOAL,
  sourceIndex,
  card,
});

/**
 * Stores the game current time
 */
const saveGameTime = (time) => ({
  type: GameBoardActionTypes.SAVE_GAME_TIME,
  time,
});

/**
 * Mark game is over now
 */
const markGameTimeOver = (payload) => ({
  type: GameBoardActionTypes.MARK_GAME_OVER,
  payload,
});

/**
 * recreate new game
 */
const reCreateNewGame = () => ({
  type: GameBoardActionTypes.RESTART_NEW_GAME,
});

/**
 * refresh and recreate new game on same hand
 */
const refreshCreateNewGame = () => ({
  type: GameBoardActionTypes.REFRESH_RESTART_NEW_GAME,
});

/*
 * set existing game move
 */
const setGameCurrentHand = (currentGameHand) => ({
  type: GameBoardActionTypes.SET_CURRENT_GAME_HAND,
  currentGameHand,
});

/*
 * store history
 */
const storeScoreHistory = (score) => ({
  type: GameBoardActionTypes.STORE_GAME_AS_HISTORY,
  score,
});
const gameBoardActions = Object.freeze({
  createGame,
  flipDeckPile,
  resetDeck,
  dragFlippedCard,
  addDraggingCardsToColumn,
  addDraggingCardsToGoal,
  turnCard,
  handleDoubleClick,
  addGameMove,
  addGame,
  undoFlipDeckPile,
  removeGameMove,
  removeCardFromGoal,
  addCardToColumn,
  removeNCardsFromColumn,
  addCardToFlipped,
  undoSwapColumns,
  addCardToGoal,
  saveGameTime,
  markGameTimeOver,
  reCreateNewGame,
  setGameCurrentHand,
  storeScoreHistory,
  refreshCreateNewGame,
});

export default gameBoardActions;
