import React, { useState } from "react";
import "../auth.css";
import { Container, Row, Form, Col } from "react-bootstrap";
import { Header } from "../../Landingpage/Header";
import { Link, useHistory } from "react-router-dom";
import { ErrorMessage, Field, Formik } from "formik";
import { Icon } from "@iconify/react";
import imageurl from "../../common/images";
import BtnSpinner from "../../common/BtnSpinner";
import { login } from "../../../store/actions/authAction";
import { loginValidation } from "../../common/validation";
import { connect } from "react-redux";
import TextError from "../../common/TextError";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const Login = ({ submitting, login }) => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    login(values, resetForm, history);
    setSubmitting(false);
  };

  return (
    <div>
      <Header />
      <div className="login_box">
        <div className="grey_box">
          <Container>
            <Row>
              <Col className="p-0">
                <h5>Log In</h5>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={loginValidation}
                  onSubmit={handleSubmit}
                >
                  {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="main_make mb-3">
                      <Form.Group
                        className="mb-3 group"
                        controlId="formBasicEmail"
                      >
                        <div className="position-relative">
                          <Icon icon="circum:mail" />
                          <Field
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            autoComplete="off"
                            name="email"
                          />
                        </div>
                        <ErrorMessage name="email" component={TextError} />
                      </Form.Group>
                      <Form.Group
                        className="mb-2 group"
                        controlId="formBasicPassword"
                      >
                        <div className="position-relative">
                          <Icon icon="ph:lock-simple-light" />
                          <Field
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="******"
                            autoComplete="off"
                            name="password"
                          />
                          <span
                            className="Icon-eye"
                            onClick={() =>
                              setShowPassword(showPassword ? false : true)
                            }
                          >
                            {!showPassword ? (
                              <AiFillEye />
                            ) : (
                              <AiFillEyeInvisible />
                            )}
                            {/* <AiFillEyeInvisible onClick={()=>setShowPassword(showPassword?false:true)}/> */}
                          </span>
                        </div>

                        <ErrorMessage name="password" component={TextError} />
                      </Form.Group>
                      <div style={{ textAlign: "right" }}>
                        <Link
                          to="/forgot-password"
                          className="forgotpass me-4 mb-2 mt-2"
                        >
                          Forgot Password?
                        </Link>
                      </div>
                      <button
                        type="submit"
                        className="login_btn w-100"
                        disabled={submitting}
                      >
                        <img src={imageurl.rightleaf} alt="" />
                        {submitting ? <BtnSpinner /> : <span>Log In</span>}

                        <img src={imageurl.leftleaf} alt="" />
                      </button>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ form }) => {
  return {
    submitting: form.submitting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload, resetForm, history) =>
      dispatch(login(payload, resetForm, history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
