import {
  FETCH_GAME_HISTORY_LIST,
  FETCH_GAME_RESULT_ERROR,
  FETCH_GAME_RESULT_LIST,
  SET_GAME_HISTORY_LIST,
  SET_GAME_RESULT_LIST,
  FETCH_RANK_INFO,
  SET_RANK_INFO,
  FETCH_RANK_INFO_ERROR,
  FETCH_GAME_ROUND_TIME_DIFFRENCE,
  SET_GAME_ROUND_TIME_DIFFRENCE,
  FETCH_GAME_ROUND_TIME_DIFFRENCE_ERROR,
} from "../common/types";
const initialState = {
  error: "",
  loading: false,
  time_diffrence_loading: false,
  lists: [],
  rankInfo: {},
  game_round_time_diffrence: 0,
  lobby_info: {
    date: "",
    time: "",
  },
};

const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GAME_RESULT_LIST:
      return {
        ...state,
        loading: true,
      };
    case SET_GAME_RESULT_LIST:
      return {
        ...state,
        lists: [...action.payload],
        lobby_info: action.lobby_info,
        loading: false,
      };

    case FETCH_GAME_RESULT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FETCH_RANK_INFO:
      return {
        ...state,
        loading: true,
      };
    case SET_RANK_INFO:
      return {
        ...state,
        rank_info: action.payload,
        loading: false,
      };

    case FETCH_RANK_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FETCH_GAME_ROUND_TIME_DIFFRENCE:
      return {
        ...state,
        time_diffrence_loading: true,
      };
    case SET_GAME_ROUND_TIME_DIFFRENCE:
      return {
        ...state,
        game_round_time_diffrence: action.payload,
        time_diffrence_loading: false,
      };

    case FETCH_GAME_ROUND_TIME_DIFFRENCE_ERROR:
      return {
        ...state,
        time_diffrence_loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

const initialState2 = {
  error: "",
  loading: false,
  lists: [],
};
export const gameHistoryReducer = (state = initialState2, action) => {
  switch (action.type) {
    case FETCH_GAME_HISTORY_LIST:
      return {
        ...state,
        loading: true,
      };
    case SET_GAME_HISTORY_LIST:
      return {
        ...state,
        lists: [...action.payload],

        loading: false,
      };

    case FETCH_GAME_RESULT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default gameReducer;
