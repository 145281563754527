import { toast } from "react-toastify";
import AXIOS, { setToken } from "../common/api_helper";
import { APIs } from "../common/constants";
import {
  FETCH_LOTTERY_LIST_ERROR,
  FETCH_LOTTERY_LIST_LIST,
  FETCH_TRANSACTION_LIST_ERROR,
  FETCH_TRANSACTION_LIST_LIST,
  SET_LOTTERY_LIST_LIST,
  SET_TRANSACTION_LIST_LIST,
} from "../common/types";

//function to get transaction records
export const getTransactionLists = (payload) => async (dispatch) => {
  try {
    setToken();
    dispatch({ type: FETCH_TRANSACTION_LIST_LIST });
    const { data } = await AXIOS.get(`${APIs.GET_TRANSACTION_LISTS}`);

    if (data.success) {
      dispatch({
        type: SET_TRANSACTION_LIST_LIST,
        payload: data.data || [],
        wallate_list:data||{}
      });
    } else {
      dispatch({
        type: SET_TRANSACTION_LIST_LIST,
        payload: [],
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_TRANSACTION_LIST_ERROR,
      payload: err,
    });
  }
};

//function to get lotter records
export const getLotteryLists = (payload) => async (dispatch) => {
  try {
    setToken();
    dispatch({ type: FETCH_LOTTERY_LIST_LIST });
    const { data } = await AXIOS.get(`${APIs.GET_LOTERY_LIST}`);

    if (data.success) {
      dispatch({
        type: SET_LOTTERY_LIST_LIST,
        payload: data.data || [],
        lottery_total: data.lottery_total || 0,
      });
    } else {
      dispatch({
        type: SET_LOTTERY_LIST_LIST,
        payload: [],
        lottery_total: 0,
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_LOTTERY_LIST_ERROR,
      payload: err,
    });
  }
};
