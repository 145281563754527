import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import imageurl from "../common/images";
import VolumeControllModal from "../models/VolumeControllModal";
import { Addbalance } from "./CommonModal/Addbalance";
import { Sliderpopup } from "./CommonModal/Sliderpopup";

export const GameHeader = () => {
  const history = useHistory();

  const [show, setShow] = useState(false);
  const profile = useSelector((state) => state.profile);
  const profile_info = profile.details;
  // const [showmodal, setShowmodal] = useState(false);
  const [volumeModalOpen, setVolumeModalOpen] = useState(false);

  return (
    <div className="gameheader">
      <Navbar className="header" expand="lg">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img className="img-fluid" src={imageurl.logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="m-auto my-2 my-lg-0">
              <Nav.Link className="signup_btn neo_btn" as={Link} to="/lobby">
                <img src={imageurl.pokercard} alt="" />
                <span>Play</span>
              </Nav.Link>
              <Nav.Link
                className="signup_btn neo_btn"
                as={Link}
                to="/lobby/history"
              >
                <img src={imageurl.history} alt="" />
                <span>History</span>
              </Nav.Link>
              <Nav.Link
                className="signup_btn neo_btn"
                as={Link}
                to="/lobby/lottery"
              >
                <img src={imageurl.trophy} alt="" />
                <span>Lottery</span>
              </Nav.Link>
              <Nav.Link
                className="signup_btn neo_btn"
                as={Link}
                to="/lobby/my-wallet"
              >
                <img src={imageurl.wallet} alt="" />
                <span>My Wallet</span>
              </Nav.Link>
              <Nav.Link
                className="signup_btn neo_btn"
                as={Link}
                to="/lobby/rules"
                // onClick={() => setShowmodal(true)}
              >
                <img src={imageurl.question} alt="" />
                <span>Rules</span>
              </Nav.Link>
            </Nav>
            <Nav className="ms-auto my-2 my-lg-0">
              <Nav.Link className="fund">
                <span>${profile_info.wallet_balance}</span>
                <Icon
                  // onClick={() => setShow(true)}
                  onClick={() => history.push("/lobby/payment")}
                  icon="teenyicons:plus-circle-solid"
                />
              </Nav.Link>

              <Nav.Link
                className="main_kl me-0 sound-bag"
                onClick={() => setVolumeModalOpen(!volumeModalOpen)}
              >
                <Icon icon="charm:sound-up" />
              </Nav.Link>
              <Nav.Link
                className="main_kl me-0 sound-bag"
                as={Link}
                to="/lobby/settings"
              >
                <Icon icon="ant-design:setting-outlined" />
              </Nav.Link>
              <Nav.Link className="main_kl me-0" as={Link} to="/lobby/profile">
                <img
                  src={
                    profile_info.profile_img
                      ? profile_info.profile_img
                      : imageurl.profilrdefault
                  }
                  alt=""
                />
              </Nav.Link>

              {show && <Addbalance show={show} onHide={() => setShow(false)} />}
              {/* {showmodal && (
                <Sliderpopup
                  show={showmodal}
                  onHide={() => setShowmodal(false)}
                />
              )} */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <VolumeControllModal
        show={volumeModalOpen}
        handleClose={() => setVolumeModalOpen(false)}
      />
    </div>
  );
};
