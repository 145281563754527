import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";

import { SliderPlay } from "./SliderPlay";

export const PlaySolitire = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="hero_section login_box new_hawkye d-block py-5" style={{minHeight:"auto"}}>
      <Container>
        <Row>
          <Col>
            <SliderPlay />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
