import React, { useEffect, useMemo, useState } from "react";
import { displayTimer } from "./_helper";
import moment from "moment";

const LobbyTimer = ({ time_diffrence, actionHandler, showZero = true }) => {
  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(true);

  useEffect(() => {
    let interval;
    if (running) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!running) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [running]);

  useEffect(() => {
    if (running && time_diffrence < time) {
      setRunning(false);
    }
    if (running && time_diffrence > 0 && actionHandler) {
      if (time_diffrence - time === 0)
        actionHandler(time_diffrence - time, setTime);
    }
  }, [actionHandler, running, time, time_diffrence]);

  const showTimer = useMemo(
    () => displayTimer(time_diffrence - time),
    [time, time_diffrence]
  );
  return (
    <span className="time_red">
      {moment(showTimer, "H:mm").isSame(moment("0:0", "H:mm")) && !showZero
        ? null
        : showTimer}
    </span>
  );
};

export default LobbyTimer;
