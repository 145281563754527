import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { getLotteryLists } from "../../../store/actions/userAction";
import { useEffect } from "react";
import SiteLoader from "../../shared/SiteLoader/SiteLoader";
//import { LogoutModal } from "./Modal/LogoutModal"
//import { Profilesuccess } from './Modal/Profilesuccess'
const tabsdetails = [
  { id: 1, tit: "Current Rankings" },
  {
    id: 2,
    tit: "Winnings",
  },
];
const winnings = [
  { id: 1, 
    rank: "1st", 
    tit: "25%" 
  },
  {
    id: 4,
    rank: "4th",
    tit: "10%",
  },
  { id: 2, 
    rank: "2nd", 
    tit: "20%" 
  },
  {
    id: 5,
    rank: "5th and so on",
    tit: "5%",
  },
  { id: 3, 
    rank: "3rd", 
    tit: "15%" 
  },
  
  
  
  
];
const Lottery = ({ getLotteryLists, loading, lists, lottery_total }) => {
  const [tabs, setTabs] = useState(1);
  useEffect(() => {
    getLotteryLists();
  }, [getLotteryLists]);
  return (
    <div>
      <div
        className="settiings open_prf lottery"
        style={{ marginBlock: "26px" }}
      >
        <h4>Lottery</h4>
        <div className="para_box">
          <p>ENDS MONDAY OR WHEN POOL HITS $10,000</p>
          <div className="open_lot">
            <span className="points">{lottery_total}</span>
          </div>
        </div>
        {loading ? <SiteLoader /> : null}

        <div className="tabs_de">
          {tabsdetails.map((item) => (
            <button
              className={tabs === item.id ? "activetab" : ""}
              onClick={() => setTabs(item.id)}
              key={item.id}
            >
              {item.tit}
            </button>
          ))}
        </div>
        {/* current rank */}

        {tabs === 1 && (
          <>
            <ul className="gaming_list">
              {lists &&
                lists.length > 0 &&
                lists.map((list, i) => (
                  <li key={`lottery__${i}`}>
                    <div
                      className={`game_detail_card lotery_list ${
                        list.status == 1 && "points_lottery"
                      }`}
                    >
                      <div className="nine_game ">
                        <span className="lottery_rank">{i + 1}</span>
                        <h3 className="mb-0 lottery_rank">{list.user_name}</h3>
                      </div>
                      <span className="points">{list.points}</span>
                    </div>
                  </li>
                ))}
              {lists.length === 0 && (
                <li className="text-center">No lottery found</li>
              )}
            </ul>
            <ul className="gaming_list">
              {
                <Table className="table">
                  <thead className="item_lottry">
                    <tr className="item_lottry_inner">
                      {/* <th className="item_lottry_inner_01" scope="col">
                      </th> */}
                      <td className="item_lottry_inner_01">
                        <span>YOUR PLACEMENT</span>
                      </td>
                      <td className="item_lottry_inner_01"></td>
                      <td className="item_lottry_inner_01">
                        <span style={{ float: "right" }}>SCORE</span>
                      </td>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundCfolor: "#e6ebf0" }}>
                    {lists.map((item, i) => {
                      return (
                        item?.status === 1 && (
                          <tr>
                            {/* <th scope="row" className="points_th_soore">
                              1
                            </th> */}
                            <td>
                              <div className="d-flex ">
                                <span className="lottery_rank">{i + 1} </span>
                                <h3 className="points_th_soore ms-5 lottery_rank">
                                  {item.user_name}
                                </h3>
                              </div>
                            </td>
                            <td></td>
                            <td>
                              <h3
                                className="points_th_soore"
                                style={{ float: "right" }}
                              >
                                {item.points}
                              </h3>
                            </td>
                          </tr>
                        )
                      );
                    })}
                    <span></span>
                  </tbody>
                </Table>
              }
              {/* {lists && lists.length > 0 && (
                <li>
                  <div className="game_detail_card lotery_list">
                    <div className="nine_game points">
                      <span>1</span>
                      <h3 className="mb-0">{lists[0].user_name}</h3>
                    </div>
                    <span className="points">
                      {lists[0].total_remaining_cards}
                    </span>
                    <span className="points">{lists[0].total_payout}</span>
                  </div>
                </li>
              )} */}
            </ul>
          </>
        )}
        {/* Winnings */}
        {tabs === 2 && (
          <>
            <ul className="gaming_list">
              <Row>
                {winnings.map((item) => (
                  <Col lg={6} key={item.id}>
                    {" "}
                    <li>
                      <div className="game_detail_card rank_list">
                        <span>{item.rank}</span>
                        <span className="">{item.tit}</span>
                      </div>
                    </li>
                  </Col>
                ))}
              </Row>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ lottery: { lists, loading, lottery_total } }) => {
  return {
    loading,
    lists,
    lottery_total,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLotteryLists: (payload) => dispatch(getLotteryLists(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Lottery));
