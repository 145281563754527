import React from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Accordion, Form, Col, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import {
  updateNewPassword,
  updateProfile,
} from "../../../store/actions/authAction";
import { useState } from "react";
const AccountSettings = () => {
  const [initialData, setInitialData] = useState({
    new_password: "",
    old_password: "",
    confirm_password: "",
    current_username: "",
  });

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,

    validationSchema: Yup.object({
      old_password: Yup.string().required("Old Password is required"),
      new_password: Yup.string().required("New Password is required"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "Confirm passwords must match")
        .required("Confirm password is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      if (
        values.new_password &&
        values.old_password &&
        values.confirm_password
      ) {
        dispatch(updateNewPassword(values, resetForm));
      } else if (values.current_username) {
        dispatch(updateProfile(values, 0, resetForm));
      }
    },
  });

  const formik1 = useFormik({
    initialValues: initialData,
    enableReinitialize: true,

    onSubmit: (values, { resetForm }) => {
      if (
        values.new_password &&
        values.old_password &&
        values.confirm_password
      ) {
        dispatch(updateNewPassword(values, resetForm));
      } else if (values.current_username) {
        dispatch(updateProfile(values, 0, resetForm));
      }
    },
  });

  return (
    <div className="settiings">
      <h5>
        <span>
          <Link className="pe-1" to="/lobby/settings">
            <Icon icon="teenyicons:arrow-left-small-outline" />
          </Link>{" "}
          Account Settings
        </span>
      </h5>
      <ul className="list-style-none">
        <Accordion>
          {/* <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Icon icon="quill:mail" />
                <span>Change Email</span>
              </Accordion.Header>
              <Accordion.Body>
                <div className="">
                  <Form.Group as={Col} md="6">
                    <Form.Control
                      type="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      placeholder="Enter email"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    type="submit"
                    className="mt-2"
                    style={{
                      background: "#000000",
                      color: "#fff",
                      border: "none",
                    }}
                  >
                    Submit{" "}
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item> */}
          <form onSubmit={formik1.handleSubmit}>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {" "}
                <Icon icon="mingcute:user-1-line" />
                <span>Change Username</span>
              </Accordion.Header>
              <Accordion.Body>
                <div className="">
                  <Form.Group as={Col} md="6">
                    <Form.Control
                      type="text"
                      name="current_username"
                      value={formik1.values.current_username}
                      onChange={formik1.handleChange}
                      placeholder="Enter username"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    type="submit"
                    className="mt-2"
                    style={{
                      background: "#000000",
                      color: "#fff",
                      border: "none",
                    }}
                  >
                    Submit{" "}
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </form>
          <Form onSubmit={formik.handleSubmit}>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {" "}
                <Icon icon="ph:lock-simple-light" />
                <span>Change Password</span>
              </Accordion.Header>
              <Accordion.Body>
                <div className="">
                  <Form.Group as={Col} md="6" className="my-2">
                    <Form.Control
                      type="password"
                      name="old_password"
                      value={formik.values.old_password}
                      onChange={formik.handleChange}
                      placeholder="Enter Old Password"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    {formik.errors.old_password &&
                    formik.touched.old_password ? (
                      <span style={{ color: "red" }}>
                        {formik.errors.old_password}
                      </span>
                    ) : null}
                  </Form.Group>
                  <Form.Group as={Col} md="6" className="my-2">
                    <Form.Control
                      type="password"
                      name="new_password"
                      value={formik.values.new_password}
                      onChange={formik.handleChange}
                      placeholder="Enter New Password"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    {formik.errors.new_password &&
                    formik.touched.new_password ? (
                      <span style={{ color: "red" }}>
                        {formik.errors.new_password}
                      </span>
                    ) : null}
                  </Form.Group>
                  <Form.Group as={Col} md="6" className="my-2">
                    <Form.Control
                      type="password"
                      name="confirm_password"
                      value={formik.values.confirm_password}
                      onChange={formik.handleChange}
                      placeholder="Enter Confirm Password"
                    />
                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                    {formik.errors.confirm_password &&
                    formik.touched.confirm_password ? (
                      <span style={{ color: "red" }}>
                        {formik.errors.confirm_password}
                      </span>
                    ) : null}
                  </Form.Group>
                  <Button
                    type="submit"
                    className="mt-2"
                    style={{
                      background: "#000000",
                      color: "#fff",
                      border: "none",
                    }}
                  >
                    Submit{" "}
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Form>
        </Accordion>
      </ul>
    </div>
  );
};
export default withRouter(AccountSettings);
