import React, { useRef } from "react";
import { Modal, Carousel } from "react-bootstrap";
import { SliderPlay } from "../../Landingpage/SliderPlay";

import imageurl from "../../common/images";
const sliderimages = [
  {
    id: 1,
    image: imageurl.slide1,
    content:
      "Solitaire is a single player card game that is played with a deck of 52 cards. The goal of the game is to move all of the cards to the foundations, which are four piles at the top of the playing area. Each foundation must be built up in suit from Ace to King.",
  },
  {
    id: 2,
    image: imageurl.slide2,
    content:
      "To begin the game, shuffle the deck of cards and deal them into a tableau, which is a series of seven piles of cards arranged in a row. The first pile should have one card, the second pile should have two cards, and so on until the seventh pile has seven cards. The remaining cards are placed in a stock pile at the top left of the playing area.",
  },
  {
    id: 3,
    image: imageurl.slide3,
    content:
      "The game is played by moving cards around the tableau and to the foundations. You can move cards between the tableau piles by building down in alternating colors. For example, you can move a red Queen onto a black King, or a black 6 onto a red 7. You can also move a complete pile of cards from one tableau pile to another as long as the cards are built down in alternating colors.",
  },
  {
    id: 4,
    image: imageurl.slide4,
    content:
      "You can turn over cards from the stock pile to the waste pile, which is a single face-up pile at the top right of the playing area. You can then move cards from the waste pile to the tableau or foundations. If you run out of cards in the stock pile, you can shuffle the waste pile and turn it over to become the new stock pile.",
  },
  {
    id: 5,
    image: imageurl.slide5,
    content:
      "Each player must play 7 solitaire hands for one game. The cards left after each hand are added for a total, and the player with the least amount of cards receives a dollar difference per card throughout all the players. Any player who wins or beats solitaire in any hand will collect $10 from all players in the game, minus 10% which goes into the lotto pool. The lotto pool will be paid to the players with the most points. Points are awarded to the players with the least amount of cards after each game. Each player can lay out his table up to seven times; he must play the seventh layout.",
  },
];

export const Sliderpopup = (props) => {
  return (
    <div>
      <Modal
        centered
        className="border-0 border-radius-0 lobby_modal slider_modal"
        show={props.show}
        onHide={props.onHide}
      >
        <Modal.Body className="border-0 p-0 ">
          <div className="min_lamp">
            <div className="play_ins">
              <h5 className="mb-3 mt-3">
                How to Play <b>Solitaire</b>
              </h5>
              <Carousel fade interval={10000} className="mb-3">
                {sliderimages.map((item) => (
                  <Carousel.Item key={item.id}>
                    <div className="katil">
                      <div class="row">
                        <div class="col-sm">
                          <div className="caption">{item.content}</div>
                        </div>
                        <div class="col-sm">
                          <img className="d-block w-100" src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
              {/* {
          sliderimages.map((item) => (
            showcontent === item.id && <p data-aos="fade-up" data-aos-duration="3000" key={item.id}>{item.content}</p>
          ))
        } */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
