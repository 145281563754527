import React from "react";
import { Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { sendContactQuery } from "../../../store/actions/authAction";
import { ErrorMessage, Field, Formik } from "formik";
import { contactValidation } from "../../common/validation";
import BtnSpinner from "../../common/BtnSpinner";
import { connect } from "react-redux";
import TextError from "../../common/TextError";
const Contactus = ({ sendContactQuery, submitting, history }) => {
  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    sendContactQuery(values, resetForm, history);
    setSubmitting(false);
  };

  return (
    <div className="settiings contact_us">
      <h5>
        <span>Contact Us</span>
      </h5>
      <Formik
        initialValues={{
          name: "",
          email: "",
          message: "",
        }}
        validationSchema={contactValidation}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit} className="main_make">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div className="group">
                <Icon icon="mingcute:user-1-line" />
                <Field
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  autoComplete="off"
                  name="name"
                />{" "}
                <ErrorMessage name="name" component={TextError} />
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div className="group">
                <Icon icon="circum:mail" />
                <Field
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  autoComplete="off"
                  name="email"
                />{" "}
                <ErrorMessage name="email" component={TextError} />
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div className="group">
                <Field
                  as="textarea"
                  className="form-control"
                  placeholder="Message"
                  rows={8}
                  name="message"
                />{" "}
              </div>
              <ErrorMessage name="message" component={TextError} />
            </Form.Group>
            <div className="main_btn bank_btn mt-3">
              <Link to="/lobby/settings">
                {" "}
                <button className="">Cancel</button>
              </Link>

              <button type="submit" disabled={submitting}>
                {submitting ? <BtnSpinner /> : <span>Send</span>}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ form }) => {
  return {
    submitting: form.submitting,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendContactQuery: (payload, resetForm, history) =>
      dispatch(sendContactQuery(payload, resetForm, history)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Contactus));
