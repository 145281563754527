import { updateCurrentHand } from "../common/utils";
import GameBoardActionTypes from "./gameBoard.types";
import {
  addCardToColumn,
  addCardToFlipped,
  addCardToGoal,
  addDragginCardsToColumn,
  addDragginCardsToGoal,
  addGameMove,
  checkDoubleClickValid,
  createRandomGame,
  flipDeckCard,
  removeCardFromGoal,
  removeGameMove,
  removeNCardsFromColumn,
  resetDeck,
  setCardDragging,
  setTurnCard,
  undoSwapColumns,
  unflipDeckCard,
} from "./gameBoard.utils";
import { sortedCards } from "./test";

const INITIAL_GAME_BOARD = {
  foundations: [[], [], [], []], //it will be replaced by goal
  columnPiles: [], //it will be replaced by column
  stockPiles: [], //This is default Deck card (copy of deck cards/piles)
  deckPileIds: [], //add for new requirement
  deckPile: [],
  flippedPile: [],
  //winner keys
  initialTimer: 0,
  gameMoves: 0,
  gameTime: 0,
  gamePreviousMoves: [],
  gameNextMoves: [],
  isGameOver: false,
  isGameStart: false,
  gameOverType: null,
  currentGameHand: 0,
  score_histories: [],
};

const gameBoardReducer = (state = INITIAL_GAME_BOARD, action) => {
  switch (action.type) {
    /**
     * Creates an initial distribution of the cards
     */
    case GameBoardActionTypes.CREATE_GAME:
      return {
        ...state,
        ...createRandomGame(),
      };
    /**
     * Flips one card from the deck pile to the flipped pile
     *    - removes the top card of the deck pile;
     *    - adds it to the flipped pile;
     */
    case GameBoardActionTypes.FLIP_DECK_PILE:
      const flipResult = flipDeckCard(state.deckPile, state.flippedPile);
      return {
        ...state,
        ...flipResult,
      };
    /**
     * Resets the deck, setting all the flipped cards back to the deck
     *    - sends all the flipped cards to the deck (reversed);
     *    - empties the flipped pile;
     *    - the y translation is the current number of cards of the flipped pile;
     */
    case GameBoardActionTypes.RESET_DECK:
      const resetResult = resetDeck(state.flippedPile);
      return {
        ...state,
        ...resetResult,
      };

    // ********************************************************
    // DRAGGING ACTIONS

    /**
     * Starts dragging one card
     *    - gets the card from the top of the flipped pile and save it in the cardsDragging state;
     */
    case GameBoardActionTypes.DRAG_FLIPPED_CARD:
      const dragResult = setCardDragging(state, action.payload);

      return {
        ...state,
        ...dragResult,
      };

    /**
     * Adds the cards that were being dragged to the selected column
     *    - if the movement was valid, then:
     *        - add the cards to the corresponding column pile;
     *        - sets sendBack to false;
     *        - resets cardsDragging;
     *    - if the movement was invalid, then simply set the sendBack value to true;
     */
    case GameBoardActionTypes.ADD_DRAGGING_CARDS_TO_COLUMN:
      const addResult = addDragginCardsToColumn(state, action.payload);
      return {
        ...state,
        ...addResult,
      };
    /**
     * Adds the cards that were being dragged to the selected goal
     *    - if the movement was valid, then:
     *        - add the cards to the corresponding goal pile;
     *        - sets sendBack to false;
     *        - resets cardsDragging;
     *    - if the movement was invalid, then simply set the sendBack value to true;
     */
    case GameBoardActionTypes.ADD_DRAGGING_CARDS_TO_GOAL:
      const addResultToGoal = addDragginCardsToGoal(state, action.payload);

      return {
        ...state,
        ...addResultToGoal,
      };

    /**
     * Checks if there is a column pile a card from another type of pile can be moved to
     *    - check if there is any valid spot (if more than one option is available, first choice is a not empty pile)
     *    - save the target column id result
     *    - if there were no possible moves, the target result works as a flag
     */
    case GameBoardActionTypes.CHECK_DOUBLE_CLICK_VALID:
      const checkDoubleClickResult = checkDoubleClickValid(
        state,
        action.payload
      );
      return { ...state, ...checkDoubleClickResult };

    //deleted after configuration
    case GameBoardActionTypes.TURN_CARD:
      return {
        ...state,
        ...setTurnCard(state, action.payload),
      }; // ********************************************************
    // GAME MOVES' HISTORY ACTIONS

    /**
     * Adds a move to the list of previous moves and reset the list of next moves
     */
    case GameBoardActionTypes.ADD_GAME_MOVE:
      const addMoveResult = addGameMove(
        state.gamePreviousMoves,
        action.move,
        state.gameMoves,
        state.columnPiles
      );

      return {
        ...state,
        ...addMoveResult,
      };

    /**
     * Flips one card back from the flipped pile to the deck pile
     *    - removes the top card of the deck pile;
     *    - adds it to the flipped pile;
     *    - calculates the difference of cards between the two piles and updates the translation y;
     */
    case GameBoardActionTypes.UNDO_FLIP_DECK_PILE:
      const unflipResult = unflipDeckCard(state.deckPile, state.flippedPile);
      return {
        ...state,
        ...unflipResult,
      };

    /**
     * Adds the top move from the list of previous moves to the list of next moves
     */
    case GameBoardActionTypes.REMOVE_GAME_MOVE:
      const removeResult = removeGameMove(
        "gamePreviousMoves",
        "gameNextMoves",
        state.gamePreviousMoves,
        state.gameNextMoves,
        state.gameMoves
      );
      return { ...state, ...removeResult };

    /**
     * Removes 1 card from a goal pile
     */
    case GameBoardActionTypes.REMOVE_CARD_FROM_GOAL:
      const removeCardResult = removeCardFromGoal(
        state.foundations,
        action.foundationIndex
      );
      return {
        ...state,
        ...removeCardResult,
      };

    /**
     * Sends a card to a column pile
     *    - adds the card to the correspoding column, flipping or not the cards on top
     */
    case GameBoardActionTypes.ADD_CARD_TO_COLUMN:
      const sendUndoResult = addCardToColumn(
        state.columnPiles,
        action.columnIndex,
        action.card
      );
      return {
        ...state,
        ...sendUndoResult,
      };

    /**
     * Removes N cards from a column pile
     *    - removes N cards from a column and, if the top cards were not flipped, then will flip them
     */
    case GameBoardActionTypes.REMOVE_N_CARDS_FROM_COLUMN:
      const removeNCardsResult = removeNCardsFromColumn(
        state.columnPiles,
        action.columnIndex,
        action.nCards
      );
      return {
        ...state,
        ...removeNCardsResult,
      };
    /**
     * Sends a card to a flipped pile
     *    - adds the card to the flipped pile
     */
    case GameBoardActionTypes.ADD_CARD_TO_FLIPPED:
      const addCardToFlippedResult = addCardToFlipped(
        state.flippedPile,
        action.card
      );
      return {
        ...state,
        ...addCardToFlippedResult,
      };

    /**
     * Undo swap of columns, sends back nCards from the target column to the source column
     *    - apply the necessary changes to the cards fields, according to the type of movement (undo or redo) and if a card was flipped
     *    - save the changes done at the source and target columns
     */
    case GameBoardActionTypes.UNDO_SWAP_COLUMNS:
      const resultUnswap = undoSwapColumns(
        state.columnPiles,
        action.targetIndex,
        action.sourceIndex,
        action.nCards
      );
      return { ...state, ...resultUnswap };

    /**
     * Sends a card to a goal pile
     *    - adds the card to the correspoding goal
     */
    case GameBoardActionTypes.ADD_CARD_TO_GOAL:
      const addCardToGoalResult = addCardToGoal(
        state.foundations,
        action.sourceIndex,
        action.card
      );
      return {
        ...state,
        ...addCardToGoalResult,
      };

    // user related action
    case GameBoardActionTypes.ADD_GAME:
      const currentGameHand = state.currentGameHand + 1;
      return {
        ...state,
        currentGameHand,
        isGameStart: true,
      };

    /**
     * Save the game time
     */
    case GameBoardActionTypes.SAVE_GAME_TIME:
      return { ...state, gameTime: action.time };

    /**
     * Mark Game as over
     */
    case GameBoardActionTypes.MARK_GAME_OVER:
      return {
        ...state,
        isGameOver: true,
        isGameStart: false,
        ...action.payload,
      };

    //restart new game
    case GameBoardActionTypes.RESTART_NEW_GAME:
      let currentGameHands = state.currentGameHand + 1;
      //set also in session
      updateCurrentHand(currentGameHands);
      return {
        ...state,
        ...createRandomGame(),
        initialTimer: 0,
        gameMoves: 0,
        gameTime: 0,
        gamePreviousMoves: [],
        gameNextMoves: [],
        isGameOver: false,
        isGameStart: true,
        gameOverType: null,
        currentGameHand: currentGameHands,
      };

    //restart new game
    case GameBoardActionTypes.REFRESH_RESTART_NEW_GAME:
      return {
        ...state,
        ...createRandomGame(),
        initialTimer: 0,
        // gameMoves: 0,
        gameTime: 0,
        gamePreviousMoves: [],
        // gameNextMoves: [],
        isGameOver: false,
        isGameStart: true,
        gameOverType: null,
        currentGameHand: state.currentGameHand,
      };

    //store history
    case GameBoardActionTypes.STORE_GAME_AS_HISTORY:
      return {
        ...state,
        score_histories: [...state.score_histories, { ...action.score }],
      };

    //set current game hand from  history
    case GameBoardActionTypes.SET_CURRENT_GAME_HAND:
      return { ...state, currentGameHand: action.currentGameHand };

    case "SET_TESTED_DATA":
      return {
        ...state,
        ...sortedCards,
      };

    default:
      return state;
  }
};

export default gameBoardReducer;
