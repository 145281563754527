import React from "react";
import "../auth.css";
import { Container, Row, Col } from "react-bootstrap";
// import { Header } from "../../Landingpage/Header";
// import { useHistory } from "react-router-dom";

const SuccessPage = () => {
    // const { location } = useHistory();
    // console.log("location", location);
    // const email = location.state?.email || "";

    // const getEmailProviderUrl = (email) => {
    //     if (!email) return "mailto:";
    //     const domain = email.split("@")[1];
    //     return `https://${domain}`;
    // };

    // const handleOpenEmail = () => {
    //     const url = getEmailProviderUrl(email);
    //     console.log("handleOpenEmail", url);
    //     window.location.href = url;
    // };
    return (
        <div>
            {/* <Header /> */}
            <div className="login_box">
                <div className="grey_box">
                    <Container>
                        <Row>
                            <Col className="p-0">
                                <div className="op">
                                    <p style={{ color: "#000" }}>
                                        Your account was successfully registered, and an email with
                                        a link for account verification was sent to you. Please
                                        check your email.
                                    </p>
                                    {/* <button
                                        className="d-flex m-auto success_btn w-40"
                                        onClick={handleOpenEmail}
                                    >
                                        Open E-mail
                                    </button> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default SuccessPage;
