import React from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import imageurl from "../../common/images";
export const PasswordConfirmationModal = (props) => {
  const history = useHistory();
  return (
    <div>
      <Modal
        centered
        className="border-0 border-radius-0"
        show={props.show}
        onHide={props.onHide}
      >
        <Modal.Header
          className="border-0"
          closeButton
          onClick={() => history.push("/login")}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0">
          <div className="password_c">
            <img src={imageurl.success} alt="success-img" />
          </div>
          <div className="pass_word_c">
            <span>Success!</span>
            <h5>Your password has been reset.</h5>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
