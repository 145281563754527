export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTERED_SUCCESS = "REGISTERED_PAGE";
export const INITIALIZE_LOADER = "INITIALIZE_LOADER";
export const RESET_LOADING = "RESET_LOADING";
export const INITIALIZE_FORM = "INITIALIZE_FORM";
export const FORM_SUCCESS = "FORM_SUCCESS";
export const FORM_SUBMITTING = "FORM_SUBMITTING";
export const RESET_FORM_SUBMITTING = "RESET_FORM_SUBMITTING";

export const SET_TIMEZONE_LIST = "SET_TIMEZONE_LIST";

export const FETCH_PROFILE = "FETCH_PROFILE";
export const FETCH_PROFILE_ERROR = "FETCH_PROFILE_ERROR";
export const SET_PROFILE_DETAIL = "SET_PROFILE_DETAIL";
export const RESET_PROFILE_DETAIL = "RESET_PROFILE_DETAIL";

export const FETCH_LOBBY_LIST = "FETCH_LOBBY_LIST";
export const SET_LOBBY_LIST = "SET_LOBBY_LIST";
export const FETCH_LOBBY_LIST_ERROR = "FETCH_LOBBY_LIST_ERROR";
export const UPDATE_LOBBY_LIST = "UPDATE_LOBBY_LIST";

export const FETCH_WAITING_LOBBY_LIST = "FETCH_WAITING_LOBBY_LIST";
export const SET_LOBBY_INFO_DETAIL = "SET_LOBBY_INFO_DETAIL";
export const SET_LOBBY_PARTICIPANT = "SET_LOBBY_PARTICIPANT";
export const RSEET_LOBBY_INFO_DETAIL = "RSEET_LOBBY_INFO_DETAIL";

export const EXTEND_FIVE_MINUTE_FOR_WAITING = "EXTEND_FIVE_MINUTE_FOR_WAITING";
export const FETCH_GAME_RESULT_LIST = "FETCH_GAME_RESULT_LIST";
export const SET_GAME_RESULT_LIST = "SET_GAME_RESULT_LIST";
export const FETCH_GAME_RESULT_ERROR = "FETCH_GAME_RESULT_ERROR";
export const FETCH_GAME_HISTORY_LIST = "FETCH_GAME_HISTORY_LIST";
export const SET_GAME_HISTORY_LIST = "SET_GAME_HISTORY_LIST";
export const FETCH_GAME_HISTORY_ERROR = "FETCH_GAME_HISTORY_ERROR";

export const FETCH_TRANSACTION_LIST_LIST = "FETCH_TRANSACTION_LIST_LIST";
export const SET_TRANSACTION_LIST_LIST = "SET_TRANSACTION_LIST_LIST";
export const FETCH_TRANSACTION_LIST_ERROR = "FETCH_TRANSACTION_LIST_ERROR";

export const FETCH_LOTTERY_LIST_LIST = "FETCH_LOTTERY_LIST_LIST";
export const SET_LOTTERY_LIST_LIST = "SET_LOTTERY_LIST_LIST";
export const FETCH_LOTTERY_LIST_ERROR = "FETCH_LOTTERY_LIST_ERROR";

export const SET_PROFILE_UPDATE = "SET_PROFILE_UPDATE";
export const SET_PROFILE_UPDATE_Profile = "SET_PROFILE_UPDATE_Profile";

export const LIST_GUIDLINE = "LIST_GUIDLINE";

export const PAYMENT_METHOD_ADD_WALLET = "PAYMENT_METHOD_ADD_WALLET";
export const PAYMENT_METHOD_ADD_WALLET_TO_BANK =
  "PAYMENT_METHOD_ADD_WALLET_TO_BANK";

export const PAYMENT_LOADING = "PAYMENT_LOADING";

export const FETCH_RANK_INFO = "FETCH_RANK_INFO";
export const SET_RANK_INFO = "SET_RANK_INFO";
export const FETCH_RANK_INFO_ERROR = "FETCH_RANK_INFO_ERROR";

export const FETCH_GAME_ROUND_TIME_DIFFRENCE = "FETCH_GAME_ROUND_TIME_DIFFRENCE";
export const SET_GAME_ROUND_TIME_DIFFRENCE = "SET_GAME_ROUND_TIME_DIFFRENCE";
export const FETCH_GAME_ROUND_TIME_DIFFRENCE_ERROR = "FETCH_GAME_ROUND_TIME_DIFFRENCE_ERROR";
