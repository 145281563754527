import { LIST_GUIDLINE } from "../common/types";

const initialState = {
  guidlineData: {},
};

export const guidlineReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_GUIDLINE:
      return {
        ...state,
        guidlineData: action.payload,
      };

    default:
      return state;
  }
};
