import * as Yup from "yup";
export const registerValidation = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email address")
    .required("Email field is required")
    .label("Email"),
  password: Yup.string()
    .required("Password field is required")
    .label("Password")
    .min(8, "Password must be at least 8 characters.")
    .max(30, "Password must be at most 30 characters."),
  name: Yup.string().required("Username field is required").label("Username"),
  terms_accepted: Yup.boolean()
    .oneOf([true], "You must accept the terms and conditions")
    .label("TOC"),
  is_eligible: Yup.boolean()
    .oneOf([true], "Are you sure your age is 18 or older")
    .label("Age"),
});

export const loginValidation = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email address")
    .required("Email field is required")
    .label("Email"),
  password: Yup.string()
    .required("Password field is required")
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    // )
    .label("Password")
    .min(8, "Password must be at least 8 characters.")
    .max(30, "Password must be at most 30 characters."),
});

export const forgotValidation = Yup.object().shape({
  email: Yup.string()
    .email("please enter valid email address")
    .required("Email field is required")
    .label("Email"),
});

export const changePasswordValidation = Yup.object().shape({
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters.")
    .max(30, "Password must be at most 30 characters."),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password must match")
    .required("Confirm password is required"),
});

const profileValidation = Yup.object().shape({
  first_name: Yup.string()
    .required("Please enter your first name")
    .label("First name")
    .min(4, "First name must be at least 4 characters.")
    .max(30, "First name must be at most 30 characters."),
  last_name: Yup.string()
    .required("Please enter your last name")
    .label("Last name")
    .min(4, "Last name must be at least 4 characters.")
    .max(30, "Last name must be at most 30 characters."),
  image: Yup.string().required("Profile pic is required"),
});

export const stepFormValidation = [
  profileValidation,
  Yup.object().shape({
    time_zone: Yup.string()
      .required("Please select a timezone")
      .label("Timezone"),
  }),
];

export const updateProfileValidation = Yup.object().shape({
  first_name: Yup.string()
    .required("Please enter your first name")
    .label("First name"),
    // .min(4, "First name must be at least 4 characters.")
    // .max(30, "First name must be at most 30 characters."),
  last_name: Yup.string()
    .required("Please enter your last name")
    .label("Last name"),
    // .min(4, "Last name must be at least 4 characters.")
    // .max(30, "Last name must be at most 30 characters."),
  image: Yup.string(),
});

export const contactValidation = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email address")
    .required("Email field is required")
    .label("Email"),
  message: Yup.string().required("Message field is required"),
  name: Yup.string().required("Name field is required").label("Name"),
});
