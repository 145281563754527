import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllLobby } from "../../../store/actions/firebaseAction";
import {
  extendLobbyTime,
  getLobbyLists,
  joinedLobby,
  updateLobbyList,
} from "../../../store/actions/lobbyAction";
import LobbyTimer from "../../common/LobbyTimer";
import {
  GAME_STARTING_REQUIREMENT,
  LOBBY_BUTTON_STATUS,
  LOBBY_STATUS,
  LOBBY_STATUS_CLASS,
  NAVIGATION,
} from "../../common/constant";
import SiteLoader from "../../shared/SiteLoader/SiteLoader";
import { JoinLobbyModal } from "./Modal/JoinLobbyModal";
import { PaymentConfirmModal } from "./Modal/PaymentConfirmModal";

const Dashboard = ({
  timezones,
  lists,
  loading,
  getLobbyLists,
  user_condition,
  profile_info,
  lobbyIds,
}) => {
  const [show, setShow] = useState(false);
  const [paymentConfirm, setPaymentConfirm] = useState(false);
  const [lobbyInfo, setLobbyInfo] = useState({});
  const [selectTimezone, setSelectTimezone] = useState(
    profile_info.time_zone || 1
  );
  const [joining, setJoining] = useState(false);
  const history = useHistory();
  useEffect(() => {
    getLobbyLists({ time_zone: selectTimezone });
  }, [getLobbyLists, selectTimezone]);

  useEffect(() => {
    if (profile_info && profile_info.time_zone) {
      setSelectTimezone(profile_info.time_zone);
    }
  }, [profile_info]);

  const getStatusClass = (status) => {
    if (status === LOBBY_STATUS.FINISHED) return "close_lobbby";
    else if (status === LOBBY_STATUS.WAITING) return "";
    else if (status === LOBBY_STATUS.RUNNING) return "";
    else return "";
  };

  const handleChangeTimezone = (e) => {
    setSelectTimezone(Number(e.target.value));
  };

  const handleJoin = () => {
    // check user eligible for join or not
    if (!user_condition.can_join) {
      setShow(false);
      setPaymentConfirm(true);
      return;
    }
    setJoining(true);
    joinedLobby({
      tbl_id: lobbyInfo.id,
      date: lobbyInfo.date,
    })
      .then(() => {
        history.push(
          `${NAVIGATION.YOUR_LOBBY}/${lobbyInfo.id}/${lobbyInfo.date}`
        );
      })
      .finally(() => setJoining(false));
  };

  const dispatch = useDispatch();
  const actionHandler = (_currentTime, setTime, index) => {
    if (
      lists[index].joined_user <
        GAME_STARTING_REQUIREMENT.MINIMUM_PARTICOPANT &&
      lists[index].joined_user <= GAME_STARTING_REQUIREMENT.MAXIMUM_PARITCIPANT
    ) {
      if (
        lists[index].attempt <
        GAME_STARTING_REQUIREMENT.WAITING_TIME_EXTENDED_LIMIT
      ) {
        // setTime(0);
        // dispatch(updateLobbyList(index));
        //call extend game api
        dispatch(extendLobbyTime(index, setTime));

        console.log("GAME SHOULD EXTEND");
      } else {
        //closed lobby
        dispatch(updateLobbyList(index, true));
        //call api if needed
      }
    } else {
      // game can be start
      console.log("GAME can be start");
      //api call with redirect game screen
      history.push(NAVIGATION.GAME_BOARD);
    }
  };

  let unsbscribe;

  async function getFirebaseData(ids) {
    unsbscribe = getAllLobby(ids, dispatch);
  }

  useEffect(() => {
    if (lobbyIds.length > 0) {
      getFirebaseData(lobbyIds);
    }
    return () => {
      if (unsbscribe) unsbscribe.forEach((unsub) => unsub());
    };
  }, [lobbyIds]);

  return (
    <div className="settiings open_prf">
      <h5>
        <span>Lobbies</span>

        <div className="w-100">
          <div className="">
            <Form.Select
              aria-label="Default select example"
              className="time_zone_lobby  mb-0"
              value={selectTimezone}
              onChange={handleChangeTimezone}
              style={{ fontWeight: "bold" }}
            >
              <option value={""}>Pick Your Timezone</option>
              {timezones.length > 0 &&
                timezones.map((list) => (
                  <option key={list.id} value={list.id}>
                    {list.time_zone}
                  </option>
                ))}
            </Form.Select>
            {/* <i className="arrow down" style={{fontWeight:'bold'}}></i> */}
            {/* <i className="fa-solid fa-caret-down"></i> */}
          </div>
        </div>
      </h5>
      {loading ? <SiteLoader /> : null}
      <ul className="nine_ready">
        {lists.length > 0 &&
          lists.map((list, index) => (
            <li key={list.id}>
              <div className="game_detail_card">
                {/* <Link to="/lobby/yourlobby"> */}
                <div className="nine_game">
                  <div className="loc_box">
                    <div className="min_ramp">
                      <span>
                        {list.joined_user}/{list.total_user}
                      </span>
                      <span className={LOBBY_STATUS_CLASS[list.lobbymesg]}>
                        {list.lobbymesg}
                      </span>

                      <LobbyTimer
                        time_diffrence={Number(list.time_diffrence)}
                        actionHandler={(currentTime, setTime) =>
                          actionHandler(currentTime, setTime, index)
                        }
                        showZero={false}
                      />
                    </div>
                    <h4>{list.time}</h4>
                  </div>
                </div>
                {/* </Link> */}
                {list.lobbiesstatus === LOBBY_STATUS.JOINED ? (
                  <button
                    className={`signup_btn neo_btn ${getStatusClass(
                      list.lobbiesstatus
                    )}`}
                    onClick={() => {
                      history.push(
                        `${NAVIGATION.YOUR_LOBBY}/${list.id}/${list.date}`
                      );
                    }}
                  >
                    {LOBBY_BUTTON_STATUS[list.lobbiesstatus]}
                  </button>
                ) : (
                  <button
                    className={`signup_btn neo_btn ${getStatusClass(
                      list.lobbiesstatus
                    )}`}
                    disabled={
                      list.lobbiesstatus !== LOBBY_STATUS.WAITING ||
                      (list.lobbiesstatus === LOBBY_STATUS.WAITING &&
                        list.joined_user ===
                          GAME_STARTING_REQUIREMENT.MAXIMUM_PARITCIPANT)
                    }
                    onClick={() => {
                      setLobbyInfo(list);
                      setShow(true);
                    }}
                  >
                    {/* {getStatusText(list.lobbiesstatus)} */}
                    {list.lobbiesstatus === LOBBY_STATUS.WAITING &&
                    list.joined_user ===
                      GAME_STARTING_REQUIREMENT.MAXIMUM_PARITCIPANT
                      ? "PACKED"
                      : LOBBY_BUTTON_STATUS[list.lobbiesstatus]}
                  </button>
                )}
              </div>
            </li>
          ))}
        {lists.length === 0 && <li className="text-center">No lobby found</li>}
      </ul>
      {show && (
        <JoinLobbyModal
          show={show}
          onHide={() => {
            setLobbyInfo({});
            setShow(false);
          }}
          lobbyInfo={lobbyInfo}
          handleJoin={handleJoin}
          joining={joining}
        />
      )}
      {paymentConfirm && (
        <PaymentConfirmModal
          show={paymentConfirm}
          onHide={() => {
            setPaymentConfirm(false);
          }}
          user_condition={user_condition}
        />
      )}
    </div>
  );
};
const mapStateToProps = ({
  profile: { details },
  timezone: { timezones },
  lobby: { lists, loading, condition, lobbyIds },
}) => {
  return {
    timezones,
    loading,
    lists,
    user_condition: condition,
    profile_info: details,
    lobbyIds,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLobbyLists: (payload, enable) =>
      dispatch(getLobbyLists(payload, enable)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
