import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import imageurl from "../../common/images";
import { Link } from "react-router-dom";
import { LogoutModal } from "./Modal/LogoutModal";
import { connect } from "react-redux";
import { getGameHistories } from "../../../store/actions/gameBoardAction";
import SiteLoader from "../../shared/SiteLoader/SiteLoader";
import { NAVIGATION } from "../../common/constant";
import { storeTempLobbyId } from "../../../store/common/utils";
import { Profilesuccess } from "./Modal/Profilesuccess";
import { modelUpdate } from "../../../store/actions/authAction";
//import { Profilesuccess } from './Modal/Profilesuccess'
const UserProfile = ({
  profile_info,
  getGameHistories,
  loading,
  lists,
  history,
}) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const dispatch = useDispatch();
  const { showModel } = useSelector((state) => state.auth);

  useEffect(() => {
    getGameHistories();
  }, [getGameHistories]);

  return (
    <div>
      <div className="settiings open_prf">
        <h5>
          <span>My Profile</span>
          <div className="mani_btn">
            <Link to="/lobby/edit-profile">
              <button>Edit Profile</button>
            </Link>
            <button onClick={() => setShowLogoutModal(true)}>Logout</button>
          </div>
        </h5>

        {loading ? <SiteLoader /> : null}
        {showLogoutModal && (
          <LogoutModal
            show={showLogoutModal}
            onHide={() => setShowLogoutModal(false)}
          />
        )}

        <div className="prf_info">
          <div className="user_prf">
            <img
              src={
                profile_info.profile_img
                  ? profile_info.profile_img
                  : imageurl.profilrdefault
              }
              alt=""
              className="update__profile"
            />
            <div className="first_box">
              <h4>{profile_info.name}</h4>
              <span>{`${profile_info.first_name ?? ""} ${profile_info.last_name ?? ""
                }`}</span>
            </div>
            <div className="first_box">
              <h4>Points</h4>
              <span>{profile_info?.game_point}</span>
            </div>
          </div>
        </div>
        <div className="game_history_profile">
          <div className="d-flex justify-content-between game_history_inner">
            <span
              className=""
              style={{
                fontFamily: "Georgia",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "28px",
                lineHeight: "32px",
              }}
            >
              Game History:
            </span>
            {/* <button className="game_history_inner_button">View All</button> */}
          </div>
        </div>

        <ul className="gaming_list">
          {lists &&
            lists.length > 0 &&
            lists.map((list, i) => (
              <li key={`history_${i}`}>
                <div className="game_detail_card">
                  <div className="nine_game">
                    <div className="loc_box">
                      <span>{list.user_count} Players</span>
                      <h4>{list.place}</h4>
                    </div>
                    <div className="loc_box">
                      <span>{list.date}</span>
                      <h4>{list.time}</h4>
                    </div>
                    <div className="loc_box">
                      <span>Points</span>
                      <h4 className="points">{list.points}</h4>
                    </div>
                  </div>
                  <span
                    onClick={() => {
                      storeTempLobbyId(list.lobby_id);
                      history.push(
                        `${NAVIGATION.GAME_RESULT}/${list.lobby_id}`
                      );
                    }}
                    className="view_gam"
                  >
                    View Game
                  </span>
                </div>
              </li>
            ))}
          {lists.length === 0 && (
            <li className="text-center">No history found</li>
          )}
        </ul>
      </div>
      {showModel && (
        <Profilesuccess
          show={showModel}
          onHide={() => dispatch(modelUpdate(false))}
        // history={history}
        />
      )}
    </div>
  );
};
const mapStateToProps = ({
  profile: { details },
  game_history: { lists, loading },
}) => {
  return {
    profile_info: details,
    lists,
    loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getGameHistories: (payload) => dispatch(getGameHistories(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserProfile));
