import React from "react";
import { useDispatch, useSelector } from "react-redux";
import gameBoardActions from "../../../../../store/gameBoard/gameBoard.actions";

const DeckPile = () => {
  const dispatch = useDispatch();
  const { deckPile } = useSelector((state) => state.gameBoard);

  const handleFlipDeckPile = () => {
    // setTimeout(() => dispatch(gameBoardActions.flipDeckPile()), 600);
    dispatch(gameBoardActions.flipDeckPile());
    // add one movement of the game
    dispatch(
      gameBoardActions.addGameMove({
        source: "deckPile",
        target: "flippedPile",
        cards: [],
      })
    );
  };
  const handleResetDeck = () => {
    // resets the deck
    dispatch(gameBoardActions.resetDeck());
  };
  return (
    <div className="mine_card no_bg">
      {deckPile.length > 0 ? (
        <picture>
          <img
            alt="deck"
            src={
              deckPile.length > 1
                ? require(`../../../../../assets/images/deck.png`)
                : require(`../../../../../assets/cards/1B.png`)
            }
            onClick={() => handleFlipDeckPile()}
            className="drop-target"
            onDragStart={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </picture>
      ) : (
        <div className="drop-target" onClick={handleResetDeck}>
          <div className="card-border">
            <img
              alt="deck"
              src={require(`../../../../../assets/images/reload.png`)}
              style={{ alignSelf: "center" }}
              width="24px"
              height="24px"
              onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DeckPile;
