import React from "react";
import { useSelector } from "react-redux";
import GoalPile from "../Piles/GoalPile";
import { suits } from "../../../../../store/gameBoard/gameBoard.utils";

const GoalPileWrapper = () => {
  const { foundations } = useSelector((state) => state.gameBoard);
  return (
    <div className="crd_main">
      {foundations.length &&
        foundations?.map((foundation, i) => (
          <GoalPile
            key={i.toString()}
            cards={foundation}
            index={i}
            foundationSuit={suits[i]}
            nextCard={foundation.length + 1}
          />
        ))}
    </div>
  );
};

export default GoalPileWrapper;
