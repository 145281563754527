import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getGuidlines } from "../../../store/actions/termAction";
import createMarkup from "../../common/createMarkup";

const TermCondition = () => {
  const dispatch = useDispatch();
  const { guidlineData } = useSelector((state) => state.guidline);

  useEffect(() => {
    dispatch(getGuidlines());
  }, [dispatch]);

  return (
    <div className="settiings-term">
      <div
        style={{
          display: "flex",
        }}
      >
        <span>
          <Link className="pe-1" to="/lobby/settings">
            <Icon
              icon="teenyicons:arrow-left-small-outline"
              className="big-icon"
              style={{ fontSize: "36px" }}
            />
          </Link>{" "}
        </span>
        <h2
          style={{
            margin: "auto",
          }}
        >
          Terms & Conditions
        </h2>
      </div>

      <div
        style={{
          height: "300px",
          overflowY: "auto",
        }}
      >
        <div
          className="text-break"
          sx={{ fontSize: 12, fontWeight: 500 }}
          dangerouslySetInnerHTML={createMarkup(
            guidlineData.term_condition?.description
          )}
        />
      </div>
    </div>
  );
};
export default withRouter(TermCondition);
