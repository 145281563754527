import { Icon } from "@iconify/react";
import React from "react";
import { useSelector } from "react-redux";
import { TOTAL_ALLOCATE_GAME_HAND } from "../../../../../store/gameBoard/game.constants";

const CurrentHand = () => {
  const { currentGameHand } = useSelector(({ gameBoard }) => ({
    currentGameHand: gameBoard.currentGameHand,
  }));
  return (
    <div className="first_box">
      <Icon icon="arcticons:solitairecg" />
      <span>
        Current Hand: {currentGameHand}/{TOTAL_ALLOCATE_GAME_HAND}
      </span>
    </div>
  );
};

export default CurrentHand;
