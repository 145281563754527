import React from "react";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { getGuidlines } from "../../../store/actions/termAction";
import { useDispatch, useSelector } from "react-redux";
import createMarkup from "../../common/createMarkup";
const TermsAndConditionDialog = (props) => {
  const { show, onClose } = props;
  const dispatch = useDispatch();
  const { guidlineData } = useSelector((state) => state.guidline);

  useEffect(() => {
    dispatch(getGuidlines());
  }, [dispatch]);

  return (
    <>
      <Modal centered show={show} onHide={onClose} size="lg" scrollable>
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">
            Terms & Condition
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="text-break"
            sx={{ fontSize: 12, fontWeight: 500 }}
            dangerouslySetInnerHTML={createMarkup(
              guidlineData.term_condition?.description
            )}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TermsAndConditionDialog;
