import React, { useEffect } from "react";
import imageurl from "../common/images";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { getSession, logout } from "../../store/actions/authAction";
import { NAVIGATION } from "../common/constant";
import { useDispatch } from "react-redux";

export const Header = () => {
  const history = useHistory();
  let { Authorization, sessionType } = getSession();
  const dispatch = useDispatch();

  useEffect(() => {
    if (Authorization && sessionType === "LOGIN") {
      history.push(NAVIGATION.LOBBY);
    }
  }, [history, Authorization]);

  return (
    <div>
      <Navbar className="header" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img className="img-fluid" src={imageurl.logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              {Authorization === null ? (
                <Nav.Link as={Link} to="/login">
                  Log In
                </Nav.Link>
              ) : (
                <Nav.Link
                  as={Link}
                  to="/login"
                  onClick={() => dispatch(logout(history))}
                >
                  Logout
                </Nav.Link>
              )}
              {Authorization === null && (
                <Nav.Link
                  as={Link}
                  to="/register"
                  className=" now_me me-0 signup_btn"
                  href="#action2"
                >
                  Sign Up
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
