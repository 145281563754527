import { toast } from "react-toastify";
import { APIs } from "../common/constants";
import {
  EXTEND_FIVE_MINUTE_FOR_WAITING,
  FETCH_LOBBY_LIST,
  FETCH_LOBBY_LIST_ERROR,
  FETCH_WAITING_LOBBY_LIST,
  RSEET_LOBBY_INFO_DETAIL,
  SET_LOBBY_INFO_DETAIL,
  SET_LOBBY_LIST,
  UPDATE_LOBBY_LIST,
} from "../common/types";
import AXIOS, { setToken } from "../common/api_helper";
import { default_lobby_details } from "../reducers/lobbyReducer";
import { createAndUpdateLobby } from "./firebaseAction";
import {
  GAME_STARTING_REQUIREMENT,
  LOBBY_STATUS,
} from "../../components/common/constant";
import { storeTempLobbyId } from "../common/utils";

export const getLobbyLists =
  (payload, enable = null) =>
  async (dispatch) => {
    try {
      setToken();
      dispatch({ type: FETCH_LOBBY_LIST });
      const { data } = await AXIOS.post(`${APIs.GET_LOBBIES}`, { ...payload });

      if (data.success) {
        // let arr = data.data.map((d, i) => {
        //   return {
        //     ...d,
        //     time_diffrence: (i + 1) * 30,
        //     attempt: 0,
        //   };
        // });
        dispatch({
          type: SET_LOBBY_LIST,
          payload: data.data || [],
          // payload: arr || [],
          condition: data.condition || {},
          lobbyIds: data.data.map((d) => d.lobby_id),
        });
        if (enable) enable(true);
      } else {
        dispatch({
          type: SET_LOBBY_LIST,
          payload: [],
          condition: {},
          lobbyIds: [],
        });
      }
    } catch (err) {
      toast.error(err.message);
      dispatch({
        type: FETCH_LOBBY_LIST_ERROR,
        payload: err,
      });
    }
  };

export const joinedLobby = async (payload) => {
  try {
    setToken();
    const { data } = await AXIOS.post(APIs.JOIN_LOBBY, {
      ...payload,
    });
    if (data.success) {
      //store data on firebase as well as
      if (data.data) {
        let update = await createAndUpdateLobby(data.data.lobby_id, {
          ...(data.data.user || {}),
        });
        if (update) {
          toast.success(data.message);
          return true;
        }
      }
    } else {
      toast.error(data.message);
      return false;
    }
  } catch (err) {
    toast.error(err.message);
  }
};

export const getLobbyInfo = (payload) => async (dispatch) => {
  try {
    setToken();
    dispatch({ type: FETCH_WAITING_LOBBY_LIST });
    const { data } = await AXIOS.post(`${APIs.GET_WAITING_ROOM_INFO}`, {
      ...payload,
    });

    if (data.success) {
      let transformData = {
        lobby_info: {
          ...data.data,
          // time_diffrence: 10,//for test
        },
        joined_user_lists: data.data.joined_user_lists || [],
      };

      dispatch({
        type: SET_LOBBY_INFO_DETAIL,
        payload: transformData || default_lobby_details,
      });
      //store lobby id in session for tempary
      storeTempLobbyId(data.data.lobby_id);
      // if (data.data.lobby_id) await getLobby(data.data.lobby_id, dispatch);
    } else {
      dispatch({
        type: SET_LOBBY_INFO_DETAIL,
        payload: default_lobby_details,
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_LOBBY_LIST_ERROR,
      payload: err,
    });
  }
};

export const getStartLobbyInfo = (payload) => async () => {
  try {
    setToken();
    const { data } = await AXIOS.post(`${APIs.GET_WAITING_ROOM_INFO}`, {
      ...payload,
    });

    if (data.success) {
      return data;
    } else {
      return false;
    }
  } catch (err) {
    toast.error(err.message);
  }
};

export const resetLobbyInfo = () => (dispatch) => {
  dispatch({ type: RSEET_LOBBY_INFO_DETAIL });
};

export const extendWaitingTimer = () => (dispatch) => {
  dispatch({ type: EXTEND_FIVE_MINUTE_FOR_WAITING });
};

export const updateLobbyList =
  (index, closed = false) =>
  (dispatch, getState) => {
    let {
      lobby: { lists },
    } = getState();
    // var foundIndex = lists.findIndex((x) => x.id === id);
    if (closed) {
      lists[index].lobbiesstatus = LOBBY_STATUS.CLOSED;
      lists[index].lobbymesg = "Not Started";
    } else {
      lists[index].time_diffrence =
        GAME_STARTING_REQUIREMENT.WAITING_EXTENDED_MINUTES;
      lists[index].attempt = lists[index].attempt + 1;
    }

    dispatch({
      type: UPDATE_LOBBY_LIST,
      payload: lists || [],
    });
  };

export const updateLobbyListFromFirestore =
  (lobbyId, participants) => (dispatch, getState) => {
    let {
      lobby: { lists },
    } = getState();
    var foundIndex = lists.findIndex((l) => l.lobby_id === lobbyId);
    if (foundIndex !== -1) {
      lists[foundIndex].joined_user = participants.length || 0;
      dispatch({
        type: UPDATE_LOBBY_LIST,
        payload: lists || [],
      });
    }
  };

//function to update or extend lobby time from backend
export const extendLobbyTime =
  (index, setTime) => async (dispatch, getState) => {
    try {
      let {
        lobby: { lists },
      } = getState();
      setToken();
      let request_attempt = lists[index].attempt + 1;
      const { data } = await AXIOS.post(`${APIs.UPDATE_LOBBY_TIME}`, {
        lobby_id: lists[index].lobby_id,
        attempt: request_attempt,
        // added new
        tbl_id: lists[index].id,
        date: lists[index].date,
      });
      if (data.success) {
        //update lobby
        lists[index].time_diffrence =
          GAME_STARTING_REQUIREMENT.WAITING_EXTENDED_MINUTES;
        lists[index].attempt = request_attempt;
        lists[index].time = data.data;
        //set initial zero
        setTime(0);
        dispatch({
          type: UPDATE_LOBBY_LIST,
          payload: lists || [],
        });
      } else {
        toast.error(data.message);
        return false;
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

export const exitUserFromLobby = async (payload) => {
  try {
    setToken();
    const { data } = await AXIOS.post(APIs.EXIT_USER_FROM_LOBBY, {
      ...payload,
    });
    if (data.success) {
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  } catch (err) {
    toast.error(err.message);
  }
};

export const updateGameAttempt = async (payload) => {
  try {
    setToken();
    const { data } = await AXIOS.post(APIs.UPDATE_GAME_ATTEMPT, {
      ...payload,
    });
    if (data.success) {
      return data;
    } else {
      toast.error(data.message);
      return false;
    }
  } catch (err) {
    toast.error(err.message);
  }
};
