import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import imageurl from "../common/images";
import { Link } from "react-router-dom";
export const Footer = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="footer">
      <div className="first_box">
        <Container>
          <Row>
            <Col>
              <div
                className="new_borm"
                data-aos="fade-right"
                data-aos-duration="3000"
              >
                <div>
                  <h5>Are you ready?</h5>
                  <Link to="/register">
                    <button className="login_btn d-block">
                      <img src={imageurl.rightleaf} alt="" />
                      Sign up to Start Playing
                      <img src={imageurl.leftleaf} alt="" />
                    </button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="first_box gre_y">
        <Container>
          <Row>
            <Col>
              <div
                className="new_borm"
                data-aos="fade-left"
                data-aos-duration="3000"
              >
                <div>
                  <img className="mb-3" src={imageurl.footlogo} alt="" />
                  <p>© Casino Solitaire 2023.</p>
                  <p>All Rights Reserved.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
