import { Icon } from "@iconify/react";
import React from "react";
import { useSelector } from "react-redux";

const MoveCount = () => {
  // get timer flag from the gameBoard redux state
  const { gameMoves } = useSelector(({ gameBoard }) => ({
    gameMoves: gameBoard.gameMoves,
  }));

  // print the game moves with the correct number of 0s before
  const printGameMoves = () => {
    if (gameMoves < 10 && gameMoves !== 0) {
      return `0${gameMoves}`;
    }
    return gameMoves;
  };

  return (
    <div className="first_box">
      <Icon icon="iconoir:open-select-hand-gesture" />
      <span>Moves: {printGameMoves()}</span>
    </div>
  );
};

export default MoveCount;
