import { GAME_STARTING_REQUIREMENT } from "../../components/common/constant";
import {
  EXTEND_FIVE_MINUTE_FOR_WAITING,
  FETCH_LOBBY_LIST,
  FETCH_LOBBY_LIST_ERROR,
  FETCH_WAITING_LOBBY_LIST,
  RSEET_LOBBY_INFO_DETAIL,
  SET_LOBBY_INFO_DETAIL,
  SET_LOBBY_LIST,
  SET_LOBBY_PARTICIPANT,
  UPDATE_LOBBY_LIST,
} from "../common/types";

export const default_lobby_details = {
  lobby_info: {
    time_diffrence: 0,
    time: "",
    total_user: 0,
    joined_user: 0,
    lobby_id: "",
  },
  joined_user_lists: [],
};
const initialState = {
  error: "",
  loading: false,
  lists: [],
  //   currentPage: 1,
  //   per_page: 0,
  //   total_record: 0,
  condition: {
    can_join: false,
    wallet_balance: 0,
    mini_balance: 0,
  },
  details: {
    // ...default_lobby_details,
  },
  lobbyIds: [],
};

const lobbyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOBBY_LIST:
      return {
        ...state,
        loading: true,
      };
    case SET_LOBBY_LIST:
      return {
        ...state,
        lists: [...action.payload],
        // ...action.pagination,
        loading: false,
        condition: { ...action.condition },
        lobbyIds: [...action.lobbyIds],
      };
    case UPDATE_LOBBY_LIST:
      return {
        ...state,
        lists: [...action.payload],
      };

    case FETCH_LOBBY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        // currentPage: 1,
        // per_page: 0,
        // total_record: 0,
        condition: {
          can_join: false,
          wallet_balance: 0,
          mini_balance: 0,
        },
      };

    default:
      return state;
  }
};

export const waitingLobbyReducer = (
  state = {
    loading: false,
    ...default_lobby_details,
  },
  action
) => {
  switch (action.type) {
    case FETCH_WAITING_LOBBY_LIST:
      return {
        ...state,
        loading: true,
      };

    case SET_LOBBY_INFO_DETAIL:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case SET_LOBBY_PARTICIPANT:
      return {
        ...state,
        loading: false,
        lobby_info: {
          ...state.lobby_info,
          joined_user: [...action.payload].length || 0,
        },
        joined_user_lists: [...action.payload],
      };
    case EXTEND_FIVE_MINUTE_FOR_WAITING:
      return {
        ...state,
        loading: false,
        lobby_info: {
          ...state.lobby_info,
          time_diffrence: GAME_STARTING_REQUIREMENT.WAITING_EXTENDED_MINUTES,
        },
      };
    case RSEET_LOBBY_INFO_DETAIL:
      return {
        ...state,
        ...default_lobby_details,
        loading: false,
      };

    default:
      return state;
  }
};

export default lobbyReducer;
