export const CardColor = {
  Red: 0,
  Black: 1,
};

export const CardState = {
  TableuPile: 0,
  Deck: 1,
  Foundation: 2,
  // ColumnPile: 0,
};

export const TOTAL_ALLOCATE_GAME_REDO_COUNT = 7;
export const TOTAL_ALLOCATE_GAME_HAND = 7;
export const TOTAL_ALLOCATE_GAME_TIME = 1 * 60; //5 * 60;
export const DEALY_AFTER_GAME_OVER = 10;
export const TOTAL_NUMBER_OF_CARDS = 52;
export const TOTAL_NUMBER_OF_DECK_CARDS = 24; //for new logic
export const TOTAL_DELAYED_TIME = 7 * 10 + 20; //20 second for adjust ment

export const typeOfCard = "CARD";

export const GAME_OVER_TYPE = {
  COMPLETE: "COMPLETE",
  OVER: "OVER",
};

// temp time logic comment on following places
// TEMP_CODE - GameOptions.js
// TEMP_CODE - PlayingTimer.js
