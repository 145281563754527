import React from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import LobbyTimer from "../../../common/LobbyTimer";
const GameStatredModal = (props) => {
  const history = useHistory();
  return (
    <div>
      <Modal
        centered
        className="border-0 p-0"
        show={props.show}
        onHide={props.onHide}
      >
        <Modal.Header className="border-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0 p-0">
          <div className="pass_word_c">
            <h2>Your game is about to </h2>
            <h5>start!</h5>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LobbyTimer
              time_diffrence={Number(props.time_diffrence)}
              actionHandler={props.actionHandler}
            />
          </div>

          <div className="pass_word_c">
            <span>
              Get ready, the game will automatically start for all players when
              the countdown ends.
            </span>
          </div>
          <div className="d-flex align-items-center main_btn close_kl button bank_btn mt-3">
            <button className="" onClick={() => props.onHide()}>
              Close
            </button>
            <button onClick={() => history.push("/lobby")}>
              <span>Leave Lobby</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GameStatredModal;
