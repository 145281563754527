import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import imageurl from "../../common/images";
import { useParams } from "react-router-dom";
import { getGameLobbyInfo } from "../../../store/common/utils";
import { NAVIGATION } from "../../common/constant";
import {
  getGameHistories,
  getGameResults,
} from "../../../store/actions/gameBoardAction";
import { connect } from "react-redux";
import SiteLoader from "../../shared/SiteLoader/SiteLoader";

const GameResults = ({
  history,
  loading,
  lists,
  getGameResults,
  lobby_info,
  getGameHistories,
}) => {
  const { lobbyId } = useParams();
  let { current_session_lobby } = getGameLobbyInfo();
  useEffect(() => {
    if (current_session_lobby === null || !lobbyId) {
      history.push(NAVIGATION.LOBBY);
      return;
    }
    if (lobbyId !== current_session_lobby) {
      history.push(NAVIGATION.LOBBY);
      return;
    }

    getGameResults(lobbyId);
  }, [lobbyId, getGameResults]);

  useEffect(() => {
    getGameHistories();
  }, [getGameHistories]);

  return (
    <div>
      <div className="settiings open_prf">
        <div className="mani_pur">
          <h4 className="title_mani">
            <img src={imageurl.res} alt="" className="mx-3" />
            <span>Results</span>
          </h4>
          <div className="poni">
            <span>{lobby_info.date || ""}</span>
            <span>{lobby_info.time || ""}</span>
          </div>
        </div>
        {loading ? <SiteLoader /> : null}

        <table className="w-100">
          <thead style={{ background: "#AFBDCB" }}>
            <th style={{ padding: "10px" }}>YOUR PLACEMENT</th>
            <th style={{ textAlign: "center" }}>CARDS LEFT</th>
            <th style={{ textAlign: "end", paddingInlineEnd: "30px" }}>
              PAYOUT
            </th>
          </thead>
          <tbody>
            {lists && lists.length > 0 && (
              <tr style={{ background: "#E6EBF0" }} className={`lotery_list `}>
                <td style={{ color: "#06B506", padding: "10px" }}>
                  <div>
                    <span className="mx-3">1</span>
                    <span>{lists[0].user_name}</span>
                  </div>
                </td>
                <td style={{ textAlign: "center", color: "#06B506" }}>
                  {lists[0].total_remaining_cards}
                </td>
                <td
                  style={{
                    textAlign: "end",
                    paddingInlineEnd: "30px",
                    color: "#06B506",
                  }}
                >
                  {lists[0].total_payout}
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <table className="my-2 score-table-wraper">
          <tbody>
            {lists &&
              lists.length > 0 &&
              lists.map((item, i) => {
                return (
                  <tr
                    style={{ marginBlock: "10px" }}
                    className={`game_detail_card lotery_list ${
                      item.status == 1 && "points"
                    }`}
                    key={i}
                  >
                    <td
                      style={{
                        color: item.status === 1 ? "#06B506" : "#000000",
                        padding: "10px",
                        width: "22%",
                      }}
                    >
                      <div className="d-flex">
                        <span className="mx-3">{i + 1}</span>
                        <span>{item.user_name}</span>
                      </div>
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        color: item.status === 1 ? "#06B506" : "#000000",
                      }}
                    >
                      {item.total_remaining_cards}
                    </td>
                    <td
                      style={{
                        textAlign: "end",
                        paddingInlineEnd: "30px",
                        color: item.status === 1 ? "#06B506" : "#000000",
                      }}
                    >
                      {item.total_payout}
                    </td>
                  </tr>
                );
              })}
            {/* <tr style={{ background: "#E6EBF0" }}>
              <td style={{ color: "#06B506", padding: "10px" }}>
                <div>
                  <span className="mx-3">1</span>
                  <span>Jeff Smith</span>
                </div>
              </td>
              <td style={{ textAlign: "center", color: "#06B506" }}>35</td>
              <td
                style={{
                  textAlign: "end",
                  paddingInlineEnd: "30px",
                  color: "#06B506",
                }}
              >
                Assistant Manager
              </td>
            </tr> */}
          </tbody>
        </table>

        {/* <ul className="gaming_list">
          <li>
            <div className="main_btn close_kl bank_btn mt-3">
              <button
                onClick={() =>
                  history.push(`/lobby/results-breakdown/${lobbyId}`)
                }
              >
                <span>See Breakdown</span>
              </button>
            </div>
          </li>
        </ul> */}

        <ul className="gaming_list">
          <li>
            <div className="row">
              <div className="col-6 ">
                <button
                  className=""
                  onClick={() => history.push("/lobby/history")}
                  style={{
                    width: "100%",
                    padding: "10px",
                    background: "#E6EBF0",
                    border: "1px solid ",
                  }}
                >
                  Back
                </button>
              </div>
              <div className="col-6">
                <button
                  onClick={() =>
                    history.push(`/lobby/results-breakdown/${lobbyId}`)
                  }
                  style={{
                    width: "100%",
                    padding: "10px",
                    background: "#000000",
                    color: "#fff",
                  }}
                >
                  <span>See Breakdown</span>
                </button>
              </div>
            </div>

            {/* <div className="main_btn close_kl bank_btn mt-3">
              <button
                className=""
                onClick={() => history.push(NAVIGATION.LOBBY)}
              >
                Next
              </button>
              <button onClick={() => history.push(`/lobby/results/${lobbyId}`)}>
                <span>See OverAll</span>
              </button>
            </div> */}
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = ({ game: { lists, loading, lobby_info } }) => {
  return {
    loading,
    lists,
    lobby_info,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getGameResults: (payload) => dispatch(getGameResults(payload)),
    getGameHistories: () => dispatch(getGameHistories()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GameResults));
