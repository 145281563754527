import React from "react";
import { Modal } from "react-bootstrap";
import BtnSpinner from "../../../common/BtnSpinner";

export const JoinLobbyModal = (props) => {
  return (
    <div>
      <Modal
        centered
        className="border-0 border-radius-0 lobby_modal"
        show={props.show}
        onHide={props.onHide}
      >
        <Modal.Header className="border-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0 p-0">
          <div className="pass_word_c">
            <h5 className="mb-1">Join this lobby?</h5>
            <span className="mb-2 d-block">{props.lobbyInfo.time}</span>
            <p className="mb-5">
              Upon joining, an entrance fee of $10 will be deducted from your
              balance. Once in the lobby, you will not be refunded if you leave.
            </p>
          </div>

          <div className="main_btn close_kl bank_btn mt-3">
            <button onClick={props.onHide} className="">
              Close
            </button>
            <button
              className=""
              disabled={props.joining}
              onClick={() => props.handleJoin()}
            >
              {props.joining ? <BtnSpinner /> : <span>Join Lobby</span>}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
