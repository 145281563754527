import { Icon } from "@iconify/react";
import React, { memo } from "react";

const BarDisplay = ({ children }) => {
  return (
    <div className="controllers_list">
      <div className="new_box">
        {children.map((Child, index) => (
          <div key={`barDisplay_${index}`}>{Child}</div>
        ))}
      </div>
    </div>
  );
};

export default memo(BarDisplay);
