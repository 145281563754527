import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "aos/dist/aos.css";
import "./index.css";

import store from "./store/store";
import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
// import { firebase } from "./config/firebase";
// import { ReactReduxFirebaseProvider } from "react-redux-firebase";
// import { createFirestoreInstance } from "redux-firestore";
const root = ReactDOM.createRoot(document.getElementById("root"));
// const rrfConfig = {
//   userProfile: "users",
//   useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
// };
// const rrfProps = {
//   firebase,
//   config: rrfConfig,
//   dispatch: store.dispatch,
//   createFirestoreInstance,
// };
root.render(
  <Provider store={store}>
    {/* <PersistGate persistor={persistor}> */}
    {/* <ReactReduxFirebaseProvider {...rrfProps}> */}
    <App />
    {/* </ReactReduxFirebaseProvider> */}

    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    {/* </PersistGate> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
