import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import gameBoardActions from "../../../../../store/gameBoard/gameBoard.actions";
import { displayTimer } from "../../../../common/_helper";
import { useMemo } from "react";
import { GAME_OVER_TYPE } from "../../../../../store/gameBoard/game.constants";
import { suits } from "../../../../../store/gameBoard/gameBoard.utils";

// export const convertTime = (value) => {
//   const hours = Math.floor(value / 3600);
//   const minutes = Math.floor((value % 3600) / 60);
//   const seconds = Math.floor((value % 3600) % 60);
//   return `${hours > 0 ? `${hours}:` : "0:"}${
//     minutes < 10 ? `0${minutes}` : minutes
//   }:${seconds < 10 ? `0${seconds}` : seconds}`;
// };
const initialTime = 0;
const PlayingTimer = forwardRef(({ handleGameOver }, ref) => {
  const [time, setTime] = useState(initialTime);
  const dispatch = useDispatch();
  // get timer flag and the game pause from the GameBoard redux state
  const { isGameOver, gameTime, isGameStart, nextFoundationCards } =
    useSelector(({ gameBoard }) => ({
      isGameOver: gameBoard.isGameOver,
      gameTime: gameBoard.gameTime,
      isGameStart: gameBoard.isGameStart,
      nextFoundationCards: gameBoard.foundations.map(
        (foundation, index) => `${foundation.length + 1}${suits[index]}`
      ),
    }));

  const isCheckGameOver = (foundationCards) =>
    foundationCards.every((nextCard, i) => nextCard === `14${suits[i]}`);

  useEffect(() => {
    let interval;
    if (isGameStart && !isGameOver) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!isGameStart && isGameOver) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isGameOver, isGameStart]);

  // const tick = () => {
  // setTime((prevTime) => prevTime + 1);
  // setTime(time + 1);
  // dispatch(gameBoardActions.saveGameTime(time + 1));
  // };

  useEffect(() => {
    // if (isGameStart && TOTAL_ALLOCATE_GAME_TIME >= time) {
    // uncomment this line after testing  - TEMP_CODE
    if (isGameStart) {
      // remove this line after testing  - TEMP_CODE
      if (!isGameOver && isCheckGameOver(nextFoundationCards)) {
        console.log("GAME IS COMPLETE NOW");
        // dispatch(gameBoardActions.markGameTimeOver());
        handleGameOver(GAME_OVER_TYPE.COMPLETE);
      }
      dispatch(gameBoardActions.saveGameTime(time));
    }
  }, [isGameStart, time]);

  // creates a setInterval with respective clearInterval
  // returns the number of hours, minutes and seconds it has passed
  // const useTimer = () => {
  //   let initialTime = 0;

  //   if (gameTime > 0) {
  //     initialTime = gameTime;
  //   }

  // let initialHours = 0;
  // let initialMinutes = 0;
  // let initialSeconds = 0;

  // if (gameTime > 0) {
  //   initialHours = Math.floor(gameTime / 3600);
  //   initialMinutes = Math.floor((gameTime % 3600) / 60);
  //   initialSeconds = Math.floor((gameTime % 3600) % 60);
  // }

  // set time states
  // const [seconds, setSeconds] = useState(initialSeconds);
  // const [minutes, setMinutes] = useState(initialMinutes);
  // const [hours, setHours] = useState(initialHours);

  // update the timer at every 1 second
  // useEffect(() => {
  //   const timerID = setInterval(() => tick(), 1000);
  //   return function cleanup() {
  //     clearInterval(timerID);
  //   };
  // });

  // // when the timer flag is toggled, reset the timer
  // useEffect(() => {
  //   if (gameTime === 0) {
  //     setSeconds(0);
  //     setMinutes(0);
  //     setHours(0);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [timerFlag]);

  // add one second, minute or hour accordingly
  // function tick() {
  // if (!isGameOver && isGameStart) {
  //   // if a minute has passed
  //   if (seconds === 59) {
  //     // if 59 minutes have passed
  //     if (minutes === 59) {
  //       // the reset the minutes and seconds and add one hour
  //       setSeconds(0);
  //       setMinutes(0);
  //       setHours(hours + 1);
  //     } else {
  //       // the reset the seconds and add one minute
  //       setSeconds(0);
  //       setMinutes(minutes + 1);
  //     }
  //   } else {
  //     // if none of the above, then simply add one second
  //     setSeconds(seconds + 1);
  //   }
  // }
  //dispatch(gameBoardActions.saveGameTime(hours * 3600 + minutes * 60 + seconds));
  // }

  // return object with every time unit
  // return { seconds, minutes, hours };
  // return { time };
  // };

  // use the timer to get the current time units
  // const { seconds, minutes, hours } = useTimer();
  // const { time } = useTimer();

  const showTimer = useMemo(() => displayTimer(gameTime), [gameTime]);

  useImperativeHandle(ref, () => ({
    resetTimer() {
      setTime(0);
    },
  }));

  return (
    <div className="first_box">
      <Icon icon="vaadin:stopwatch" />
      <span>
        {" "}
        {/* {hours > 0 ? `${hours}:` : null}
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds} */}
        {showTimer}
      </span>
    </div>
  );
});

export default PlayingTimer;
