// Game Board Actions
const GameBoardActionTypes = {
  CREATE_GAME: "GAME/CREATE_GAME",
  REMOVE_GAME_MOVE: "GAME/REMOVE_GAME_MOVE",
  SET_CURRENT_GAME_HAND: "GAME/SET_CURRENT_GAME_HAND",
  //deck
  FLIP_DECK_PILE: "DECK/FLIP_DECK_PILE",
  RESET_DECK: "DECK/RESET_DECK",
  DRAG_FLIPPED_CARD: "DECK/DRAG_FLIPPED_CARD",
  UNDO_FLIP_DECK_PILE: "DECK/UNDO_FLIP_DECK_PILE",
  ADD_CARD_TO_FLIPPED: "DECK/ADD_CARD_TO_FLIPPED",
  //columns
  ADD_DRAGGING_CARDS_TO_COLUMN: "COLUMNS/ADD_DRAGGING_CARDS_TO_COLUMN",
  ADD_CARD_TO_COLUMN: "COLUMNS/ADD_CARD_TO_COLUMN",
  REMOVE_N_CARDS_FROM_COLUMN: "COLUMNS/REMOVE_N_CARDS_FROM_COLUMN",
  UNDO_SWAP_COLUMNS: "COLUMNS/UNDO_SWAP_COLUMNS",

  //goals
  ADD_DRAGGING_CARDS_TO_GOAL: "GOALS/ADD_DRAGGING_CARDS_TO_GOAL",
  REMOVE_CARD_FROM_GOAL: "GOALS/REMOVE_CARD_FROM_GOAL",
  ADD_CARD_TO_GOAL: "GOALS/ADD_CARD_TO_GOAL",

  //card
  TURN_CARD: "TURN_CARD",
  CHECK_DOUBLE_CLICK_VALID: "COLUMNS/CHECK_DOUBLE_CLICK_VALID",

  //history
  ADD_GAME_MOVE: "GAME/ADD_GAME_MOVE",
  SAVE_GAME_TIME: "GAME/SAVE_GAME_TIME",
  MARK_GAME_OVER: "GAME/MARK_GAME_OVER",

  //user
  ADD_GAME: "USER/ADD_GAME",
  //store current game as history
  STORE_GAME_AS_HISTORY: "STORE_GAME_AS_HISTORY",
  RESTART_NEW_GAME: "RESTART_NEW_GAME",
  REFRESH_RESTART_NEW_GAME: "REFRESH_RESTART_NEW_GAME",
};

export default GameBoardActionTypes;
