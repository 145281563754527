import React, { useState } from "react";
import "../auth.css";
import { Container, Row, Form, Col } from "react-bootstrap";
import { Header } from "../../Landingpage/Header";
import { Icon } from "@iconify/react";
import imageurl from "../../common/images";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../../store/actions/authAction";
import BtnSpinner from "../../common/BtnSpinner";
import { ErrorMessage, Field, Formik } from "formik";
import TextError from "../../common/TextError";
import { forgotValidation } from "../../common/validation";
const ForgotPassword = () => {
  const submitting = useSelector((state) => state.form.submitting);
  const dispatch = useDispatch();
  const [resend, setResend] = useState(false);

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    setSubmitting(false);
    dispatch(forgotPassword(values, resetForm, setResend));
  };
  const resendSubmit = () => {
    let email = localStorage.getItem("reset_email");
    if (email) dispatch(forgotPassword({ email }, null, setResend));
  };
  return (
    <div>
      <Header />
      <div className="login_box">
        <div className="grey_box">
          <Container>
            <Row>
              <Col className="p-0">
                <h5>Reset Your Password</h5>
               
                {!resend ? (
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={forgotValidation}
                    onSubmit={handleSubmit}
                  >
                    {({ handleSubmit }) => (
                      <>
                       <p>
                  Please enter the email you signed up with and we'll send a
                  reset link.
                </p>
                      <Form onSubmit={handleSubmit} className="main_make mb-3">
                        <Form.Group
                          className="mb-3 group"
                          controlId="formBasicEmail"
                        >
                         <div className="position-relative">
                         <Icon icon="circum:mail" />
                          <Field
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            autoComplete="off"
                            name="email"
                          />{" "}
                         </div>
                          <ErrorMessage name="email" component={TextError} />
                        </Form.Group>

                        <button
                          className="login_btn w-100 mb-4"
                          disabled={submitting}
                        >
                          <img src={imageurl.rightleaf} alt="" />
                          {submitting ? <BtnSpinner /> : <span>Send Link</span>}
                          <img src={imageurl.leftleaf} alt="" />
                        </button>
                      </Form>
                      </>
                    )}
                  </Formik>
                ) : (
                  <>
                    <p>Didn't Receive An Email?</p>
                    <div className="main_btn">
                      <button
                        disabled={submitting}
                        className="resend"
                        onClick={resendSubmit}
                      >
                        {submitting ? <BtnSpinner /> : "Resend"}
                      </button>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
