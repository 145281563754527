import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import imageurl from "../common/images";

const ConnectionLostModal = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleOnlineStatus = () => setIsOnline(window.navigator.onLine);
    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      setShow(true);
    }
  }, [isOnline]);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Modal
        className="border-0 border-radius-0 common_payment_modal"
        show={show}
        centered
        size="md"
        onHide={handleClose}
      >
        <Modal.Body className="border-0 p-0">
          <div className="pass_word_c">
            <h5 className="mb-2">Connection lost!</h5>
            <p className="p-0">Please reconnect to internet and refresh the page.</p>
            <img src={imageurl.connection_lost} alt="lost-connection"/>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConnectionLostModal;
