import React, { useState } from 'react'
import { sliderimages } from '../../../store/common/utils';

const Rules = () => {
    const [selectedData, setSelectedData] = useState(sliderimages[0].id)
    const filterData = sliderimages.filter(slider => slider.id === selectedData)
    const content = filterData[0] ?? []
    return (
        <div className="setting open_prf">
            <h5>
                <span>Rules</span>
            </h5>
            <div class="row" style={{ width: '100%' }}>
                <div class="col-sm-4">
                    {sliderimages.map(rule => (
                        <button className={`rules-list ${rule.id === selectedData ? 'active' : ''}`} onClick={() => setSelectedData(rule.id)}>
                            <div>{rule.id} {rule.content}</div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg>
                            </div>
                        </button>
                    ))}
                </div>
                <div class="col-sm-8">
                    <div className="row main-content" id="style-5" >
                        <div className="col-sm-8">
                            <div>
                                {content.head1 && (
                                    <div style={{ color: "red", textAlign: "left", }}>
                                        {content.head1}
                                    </div>
                                )}
                                <ul className="mt-2">
                                    {content.li99 && <li style={{ textAlign: 'left' }}>{content.li99} <strong>{content.strong1}</strong></li>}
                                    {content.li88 && <li style={{ textAlign: 'left' }}>{content.li88}<strong>{content.strong2}</strong></li>}
                                    {content.li77 && <li style={{ textAlign: 'left' }}>{content.li77}<strong>{content.strong2}</strong></li>}
                                    {content.li66 && <li style={{ textAlign: 'left' }}>{content.li66}<strong>{content.strong2}</strong></li>}
                                    {content.li55 && <li style={{ textAlign: 'left' }}>{content.li55}<strong>{content.strong2}</strong></li>}
                                    {content.li44 && <li style={{ textAlign: 'left' }}>{content.li44}<strong>{content.strong2}</strong></li>}
                                    {content.li33 && <li style={{ textAlign: 'left' }}>{content.li33}<strong>{content.strong2}</strong></li>}
                                    {content.li22 && <li style={{ textAlign: 'left' }}>{content.li22}<strong>{content.strong1}</strong></li>}
                                    {content.li11 && <li style={{ textAlign: 'left', }}>{content.li11}<strong>{content.strong2}</strong></li>}

                                </ul>
                                {content.head2 && (
                                    <div style={{ color: "red", textAlign: "left" }}>
                                        {content.head2}
                                    </div>
                                )}
                                <ul className="mt-2">
                                    {content.li3 && (
                                        <li style={{ textAlign: 'left' }}>
                                            {content.li3} <strong>{content.strong3}</strong>
                                        </li>
                                    )}
                                    {content.li4 && (
                                        <li style={{ textAlign: 'left' }}>
                                            {content.li4} <strong>{content.strong4}</strong>{content.li1000}
                                        </li>
                                    )}
                                </ul>  {
                                    content.head3 && (
                                        <div style={{ color: "red", textAlign: "left" }}>
                                            {content.head3}
                                        </div>
                                    )}
                                <div style={{ textAlign: 'left', marginTop: 4 }}>{content.text}</div>
                                <ul className="mt-2">
                                    {content.li0 && (
                                        <li style={{ textAlign: 'left' }}>
                                            {content.li0} <strong>{content.strong4}</strong>
                                        </li>
                                    )}
                                    {content.li01 && (
                                        <li style={{ textAlign: 'left' }}>
                                            {content.li01} <strong>{content.strong4}</strong>
                                        </li>
                                    )}  {content.li02 && (
                                        <li style={{ textAlign: 'left' }}>
                                            {content.li02} <strong>{content.strong4}</strong>
                                        </li>
                                    )}  {content.li03 && (
                                        <li style={{ textAlign: 'left' }}>
                                            {content.li03} <strong>{content.strong4}</strong>
                                        </li>
                                    )}  {content.li04 && (
                                        <li style={{ textAlign: 'left' }}>
                                            {content.li04} <strong>{content.strong4}</strong>
                                        </li>
                                    )}  {content.li05 && (
                                        <li style={{ textAlign: 'left' }}>
                                            {content.li05} <strong>{content.strong4}</strong>
                                        </li>
                                    )}

                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <img className="d-block w-100" src={content.image} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rules