import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { displayTimer } from "../../../../common/_helper";
import {
  GAME_OVER_TYPE,
  TOTAL_ALLOCATE_GAME_TIME,
} from "../../../../../store/gameBoard/game.constants";
// import gameBoardActions from "../../../../../store/gameBoard/gameBoard.actions";

const ReverseTimer = forwardRef(
  ({ handleGameOver, game_round_time_diffrence }, ref) => {
    // Nakul - Game Timer logic
    const [time, setTime] = useState(game_round_time_diffrence);
    const { isGameStart, isGameOver } = useSelector(({ gameBoard }) => ({
      isGameStart: gameBoard.isGameStart,
      isGameOver: gameBoard.isGameOver,
    }));

    useEffect(() => {
      let interval;
      if (isGameStart && !isGameOver) {
        interval = setInterval(() => {
          setTime((prevTime) => prevTime + 1);
        }, 1000);
      } else if (!isGameStart && isGameOver) {
        clearInterval(interval);
      }
      return () => {
        clearInterval(interval);
      };
    }, [isGameStart, isGameOver]);

    useEffect(() => {
      if (isGameStart && TOTAL_ALLOCATE_GAME_TIME < time && !isGameOver) {
        //game over logic here
        console.log("GAME HAS BEEN OVER NOW ");
        handleGameOver(GAME_OVER_TYPE.OVER);
        // dispatch(gameBoardActions.markGameTimeOver());
      }
    }, [isGameStart, time]);

    const showTimer = useMemo(
      () => displayTimer(TOTAL_ALLOCATE_GAME_TIME - time),
      [time]
    );

    useImperativeHandle(ref, () => ({
      resetTimer() {
        setTime(0);
      },
    }));
    return <span className="time_red_watch">{showTimer}</span>;
  }
);

export default ReverseTimer;
