import React, { useEffect, useState } from "react";
import BarDisplay from "../DataDisplay/BarDisplay";
import PlayingTimer from "../DataDisplay/PlayingTimer";
import UndoButton from "../Buttons/UndoButton";
import MoveCount from "../DataDisplay/MoveCount";
import CurrentHand from "../DataDisplay/CurrentHand";
import ReverseTimer from "../DataDisplay/ReverseTimer";
import { useDispatch, useSelector } from "react-redux";
import gameBoardActions from "../../../../../store/gameBoard/gameBoard.actions";
import { useRef } from "react";
import GameOverModal from "../modal/GameOverModal";
import {
  removeMatchingCards,
  // numberOfRemaingDeckCards,
} from "../../common/_helpers";
import {
  getGameRoundTimeDiffrence,
  storeGameScore,
} from "../../../../../store/actions/gameBoardAction";
import GameCompleteModal from "../modal/GameCompleteModal";
import { getGameLobbyInfo } from "../../../../../store/common/utils";
import { TOTAL_ALLOCATE_GAME_HAND } from "../../../../../store/gameBoard/game.constants";

const GameOptions = ({ showGameCompleteModal, setGameCompleteModal }) => {
  const dispatch = useDispatch();
  const [showGameOverModal, setGameOverModal] = useState(false);
  let { lobby_unique_identifier } = getGameLobbyInfo();
  const { game_round_time_diffrence, time_diffrence_loading } = useSelector(
    (state) => state.game
  );

  useEffect(() => {
    console.log("this is call useeffect of get time diffrence");
    dispatch(getGameRoundTimeDiffrence(lobby_unique_identifier));
  }, [dispatch, lobby_unique_identifier]);

  const gameInfo = useSelector(({ gameBoard }) => ({
    gameMoves: gameBoard.gameMoves,
    gameTime: gameBoard.gameTime,
    currentGameHand: gameBoard.currentGameHand,
    gameOverType: gameBoard.gameOverType,
    isGameOver: gameBoard.isGameOver,

    //get remaining card count from pile as per new logic - 04-05-2023
    numberOfCardLeft: removeMatchingCards(
      gameBoard.stockPiles,
      gameBoard.foundations
    ).length,
  }));

  const reverseTimerRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      dispatch(gameBoardActions.setGameCurrentHand(0));
    };
  }, [dispatch]);

  const startNewGame = () => {
    //reset main timer
    if (timerRef.current) timerRef.current.resetTimer();
    //reset game over timer
    if (reverseTimerRef.current) reverseTimerRef.current.resetTimer();
    //hide game over popup
    setGameOverModal(false);
    console.log("this is game start call");
    dispatch(gameBoardActions.reCreateNewGame());
  };

  // The redo should act as a refresh button, for an entirely new hand to be displayed not a Undo/back button
  const refreshNewGame = () => {
    dispatch(gameBoardActions.refreshCreateNewGame());
  };

  const handleGameOver = (gameOverType) => {
    //mark as complete game
    dispatch(gameBoardActions.markGameTimeOver({ gameOverType }));
    //show game over popup when gameMoves count is less than 7
    if (gameInfo.currentGameHand < TOTAL_ALLOCATE_GAME_HAND) {
      setGameOverModal(true);
    }

    if (gameInfo.currentGameHand > TOTAL_ALLOCATE_GAME_HAND) {
      dispatch(gameBoardActions.setGameCurrentHand(0));
    }
    //call save score api
    dispatch(
      storeGameScore({
        lobby_id: lobby_unique_identifier,
        remaining_cards: gameInfo.numberOfCardLeft,
        round_time: gameInfo.gameTime,
        round_no: gameInfo.currentGameHand,
        round_moves: gameInfo.gameMoves,
      })
    );
    console.log("this is game over call");
    dispatch(getGameRoundTimeDiffrence(lobby_unique_identifier));
  };

  return (
    <>
      {/* TEMP_CODE */}

      <ReverseTimer
        ref={reverseTimerRef}
        handleGameOver={handleGameOver}
        game_round_time_diffrence={game_round_time_diffrence}
      />

      <div className="controllers">
        <BarDisplay>
          <PlayingTimer ref={timerRef} handleGameOver={handleGameOver} />
          <MoveCount />
          <UndoButton refreshNewGame={refreshNewGame} />
          <CurrentHand />
        </BarDisplay>
        {/* <button onClick={() => checkRemaingCrad()}>test</button> */}
        {/* <button onClick={() => dispatch({ type: "SET_TESTED_DATA" })}>
          Test
        </button> */}
        {/* <button onClick={() => handleGameOver("OVER")}>over</button>
        <button onClick={() => handleGameOver("OVER1")}>complete</button> */}
      </div>
      {showGameOverModal && gameInfo.isGameOver && (
        <GameOverModal
          show={showGameOverModal}
          info={gameInfo}
          onHide={() => {
            setGameOverModal(false);
            startNewGame();
          }}
        />
      )}
      {showGameCompleteModal && (
        <GameCompleteModal
          show={showGameCompleteModal}
          info={gameInfo}
          onHide={() => {
            setGameCompleteModal(false);
          }}
        />
      )}
    </>
  );
};

export default GameOptions;
