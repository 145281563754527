import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import ForgotPassword from "../components/auth/LoginModule/Forgotpassword";
import Login from "../components/auth/LoginModule/Login";
import ResetPassword from "../components/auth/LoginModule/ResetPassword";
import ProfileInformation from "../components/auth/Registermodule/ProfileInformation";
import GameDashboardlayout from "../components/Gamedashboard/GameDashboardlayout";
import { Landingpagelayout } from "../components/Landingpage/Landingpagelayout";
import { getTimezones } from "../store/actions/authAction";
import ProtectedRoute from "./ProtectedRoute";
import PaymentMood from "../components/add-wallet";
import BankTransfer from "../components/BankTransfer";
import { BASE_NAME } from "../store/common/constants";


import Registration from "../components/auth/Registermodule/Registration";
import SuccessPage from "../components/auth/Registermodule/SuccessPage";

export const Router = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTimezones());
  }, [dispatch]);

  
  return (
    <div>
      <BrowserRouter
        basename={BASE_NAME}
        // getUserConfirmation={() => {
        //   /* Empty callback to block the default browser prompt */
        // }}
      >
        <Switch>
          <Route exact path="/" component={Landingpagelayout} />
          {/* Login Module */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route
            exact
            path="/reset-password/:token/:email"
            component={ResetPassword}
          />
          {/* Registration module */}
          <Route exact path="/register" component={Registration} />
          <Route exact path="/registration-success" component={SuccessPage} />

          <Route exact path="/profile" component={ProfileInformation} />
          <Route exact path="/lobby/payment" component={PaymentMood} />
          <Route exact path="/lobby/bank-transfer" component={BankTransfer} />

          {/* Game Dashboard */}
          <ProtectedRoute path="/lobby" component={GameDashboardlayout} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};
