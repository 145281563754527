import React from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const FundAddedModal = ({ show, handleClose }) => {
  const history = useHistory();
  return (
    <>
      <Modal
        className="border-0 border-radius-0 custom_modal"
        show={show}
        centered
        size="md"
        onHide={handleClose}
      >
        <Modal.Body className="border-0 p-0">
          <div className="p-5">
            <div className="text-center">
              <h6>Success!</h6>
              <h5>Your funds were</h5>
              <h5 className="pb-4 dashed_border_bottom">
                added to your wallet.
              </h5>
              <p className="mt-3">
                If the balance was not updated, try refreshing the page.
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center main_btn close_kl button bank_btn">
            <button onClick={handleClose}>Close</button>
            <button
              onClick={() => {
                history.push("/lobby/my-wallet");
              }}
            >
              View Transactions
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FundAddedModal;
