import React from "react";
import DeckPile from "../Piles/DeckPile";
import FlippedPile from "../Piles/FlippedPile";

const Deck = () => {
  return (
    <div className="mine_craft">
      <DeckPile />
      <FlippedPile />
    </div>
  );
};

export default Deck;
