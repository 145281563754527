import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTransactionLists } from "../../../store/actions/userAction";
import SiteLoader from "../../shared/SiteLoader/SiteLoader";

import { useHistory } from "react-router-dom";

//import { Profilesuccess } from './Modal/Profilesuccess'
const Wallet = ({ loading, lists, getTransactionLists }) => {
  const history = useHistory();

  useEffect(() => {
    getTransactionLists();
  }, [getTransactionLists]);

  const { walletList } = useSelector((state) => state.transaction);

  return (
    <div className="my-5">
      <div className="settiings open_prf">
        <h5>
          <span>My Wallet</span>
          {/* <div className="mani_btn">
            <img src={imageurl.connectstripe} alt="" />
          </div> */}
        </h5>
        <div className="balnce">
          <span>Balance:</span>
          <h4>${walletList?.wallet_balance} </h4>
          <div className="main_motive">
            <button onClick={() => history.push("/lobby/bank-transfer")}>
              Transfer
            </button>
            {/* <Link to='/lobby/bank-transfer'>Transfer</Link> */}
            <button onClick={() => history.push("/lobby/payment")}>Load</button>
          </div>
        </div>
        <div className="transaction_his">
          <h4>Transaction History:</h4>
          <Link to="/lobby/my-wallet/my-transaction">
            {" "}
            <button>View All</button>
          </Link>
        </div>
        {loading ? <SiteLoader /> : null}

        <ul className="wallet_list">
          {lists &&
            lists.length > 0 &&
            lists.slice(0, 5).map((list, i) => (
              <li
                key={`transaction_${i}`}
              >
                <div className="game_detail_card">
                  <h3 className="mb-0">{list.msg}</h3>
                  <div className="nine_game">
                    <div className="loc_box" style={{ textAlign: "right" }}>
                      <span>{list.date}</span>
                      {list.type === "2" ? (
                        <h4 className="time_red ">{list.amount}$</h4>
                      ) : list.type === "3" ? (
                        list.game_status === "loser" ? (
                          <h4 className="time_red ">{list.amount}$</h4>
                        ) : (
                          <h4 className="points">{list.amount}$</h4>
                        )
                      ) : (
                        <h4 className="points">{list.amount}$</h4>
                      )
                      // <h4 className="points">+${list.amount}</h4>
                      // ''
                      }
                    </div>
                  </div>
                </div>
              </li>
            ))}
          {lists.length === 0 && (
            <li className="text-center">No transaction found</li>
          )}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = ({ transaction: { lists, loading } }) => {
  return {
    loading,
    lists,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTransactionLists: (payload) => dispatch(getTransactionLists(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
