import { CardColor, CardState, typeOfCard } from "./game.constants";

// array for the suits available
export const suits = ["H", "D", "C", "S"];
/**
 * Creates an array of objects for each card
 */
export const getAllCards = () => {
  // array that will contain all the cards
  const cards = [];
  // for each suit available
  for (let i = 1; i < 14; i++) {
    suits.forEach((suit) => {
      cards.push({
        rank: i,
        suit,
        color: suit === "D" || suit === "H" ? CardColor.Red : CardColor.Black,
        hidden: false,
        id: i.toString() + suit,
        type: typeOfCard,
        index: 0,
        state: CardState.TableuPile,
        isDragging: false,
      });
    });
  }
  return cards;
};

/**
 * Randomly shuffles the cards created
 */
export const shuffle = (array) => {
  for (
    let j, temp, i = array.length;
    i;
    j = Math.floor(Math.random() * i),
      temp = array[--i],
      array[i] = array[j],
      array[j] = temp
  );
  return array;
};

/**
 * Creates all the cards and randomly distributes them throughout the game fields
 */
export const createRandomGame = () => {
  // get all the cards images and shuffle them
  const cardsArray = shuffle(getAllCards());
  // const cardsArray = getAllCards();
  const columnPiles = [];
  for (let i = 1; i < 8; i++) {
    const tempPiles = cardsArray.splice(0, i);
    tempPiles.map((card, index) => {
      card.index = i - 1;
      if (index < tempPiles.length - 1) {
        card.hidden = true;
        return card;
      }
      card.hidden = false;
      return card;
    });
    columnPiles.push(tempPiles);
  }
  const deckPile = cardsArray.map((card) => ({
    ...card,
    state: CardState.Deck,
  }));

  const deckPileIds = deckPile.map((card) => card.id);

  return {
    foundations: [[], [], [], []],
    columnPiles,
    deckPile,
    stockPiles: deckPile,
    flippedPile: [],
    deckPileIds,
  };
};

// ********************************************************
// FLIPPING FUNCTIONS

/**
 * Flips one deck card to the flipped pile
 * @param deckPile
 * @param flippedPile
 */
export const flipDeckCard = (deckPile, flippedPile) => {
  // create copy of the deck pile
  const tempDeckPile = [...deckPile];
  // get the top card of the deck pile
  // const cardFlipped = tempDeckPile.pop(); //O
  //three flip in new logic - 08-07-2023
  const cardFlippedArray = tempDeckPile.splice(-3, 3); //N
  // get copy of the flipped pile
  const tempFlippedPile = [...flippedPile];
  // add it to the flipped pile //O
  // tempFlippedPile.push({
  //   ...cardFlipped, // flipped: true
  // });
  const finalTempFlippedPile = [...tempFlippedPile, ...cardFlippedArray]; //N

  return {
    deckPile: tempDeckPile,
    // flippedPile: tempFlippedPile, //O
    flippedPile: finalTempFlippedPile, //N
  };
};

/**
 * Resets a deck from the source to the target
 * @param sourceId id of the pile source (to be turned)
 * @param targetId id of the pile target (to receive cards)
 * @param source cards of the pile to turn
 */
export const resetDeck = (source, reversed) => {
  const final = source.map((card) => ({
    ...card,
  }));
  return {
    deckPile: reversed ? final : final.reverse(),
    flippedPile: [],
  };
};

// DRAGGING FUNCTIONS

/**
 * Sets the cards that are currently being dragged (the top card of the flipped pile)
 * @param flippedPile
 */
export const setCardDragging = (
  { flippedPile, foundations, columnPiles },
  payload
) => {
  switch (payload.movedCard.state) {
    case CardState.Deck:
      flippedPile = flippedPile.map((card) =>
        card.id === payload.movedCard.id
          ? { ...card, isDragging: payload.isDragging }
          : { ...card }
      );
      return { flippedPile };
    case CardState.Foundation:
      foundations[payload.movedCard.index] = foundations[
        payload.movedCard.index
      ].map((card) =>
        card.id === payload.movedCard.id
          ? { ...card, isDragging: payload.isDragging }
          : { ...card }
      );
      return { foundations };
    case CardState.TableuPile:
      const indexOfCard = columnPiles[payload.movedCard.index].findIndex(
        (card) => card.id === payload.movedCard.id
      );
      columnPiles[payload.movedCard.index] = columnPiles[
        payload.movedCard.index
      ].map((card, index) =>
        index >= indexOfCard
          ? { ...card, isDragging: payload.isDragging }
          : { ...card }
      );

      //ADD MULTIPLE CARD TO IN MOVE HISTORY
      return { columnPiles };
    default:
  }
};

/**
 * Adds the cards being dragged to the destination column
 */
export const addDragginCardsToColumn = (
  { columnPiles, foundations, flippedPile },
  payload
) => {
  if (payload.movedCard.state === CardState.TableuPile) {
    const indexOfCard = columnPiles[payload.movedCard.index].findIndex(
      (card) => card.id === payload.movedCard.id
    );

    //get target card
    const cardsToAdd = columnPiles[payload.movedCard.index]
      .slice(indexOfCard)
      .map((card) => ({
        ...card,
        index: payload.index,
        isDragging: false,
      }));

    const cardsLeft = columnPiles[payload.movedCard.index].slice(
      0,
      indexOfCard
    );

    columnPiles[payload.index] = [...columnPiles[payload.index], ...cardsToAdd];
    columnPiles[payload.movedCard.index] = cardsLeft;
    // if the cardsLeft is bigger than 0, there are more cards in the initial column
    if (cardsLeft.length > 0) {
      // get the last card of the initial column
      const lastCard = cardsLeft.length - 1;

      // if the last card has flipped = false, then make it true

      if (columnPiles[payload.movedCard.index][lastCard].hidden) {
        columnPiles[payload.movedCard.index][lastCard] = {
          ...columnPiles[payload.movedCard.index][lastCard],
          hidden: false,
        };
      }
    }

    return { columnPiles };
  } else {
    //if card dragged from deck pile to column pile
    columnPiles[payload.index].push({
      ...payload.movedCard,
      state: CardState.TableuPile,
      index: payload.index,
      isDragging: false,
    });

    if (payload.movedCard.state === CardState.Deck) {
      //filtered flipped pile after dragged pile
      flippedPile = flippedPile.filter(
        (card) => card.id !== payload.movedCard.id
      );
    } else {
      foundations[payload.movedCard.index] = foundations[
        payload.movedCard.index
      ].filter((card) => card.id !== payload.movedCard.id);
    }

    return {
      columnPiles,
      flippedPile,
      foundations,
    };
  }
};

/**
 * Adds the cards being dragged to the destination goal0
 */
export const addDragginCardsToGoal = (
  { columnPiles, flippedPile, foundations },
  payload
) => {
  foundations[payload.index].push({
    ...payload.movedCard,
    state: CardState.Foundation,
    index: payload.index,
    isDragging: false,
  });

  if (payload.movedCard.state === CardState.Deck) {
    flippedPile = flippedPile.filter(
      (card) => card.id !== payload.movedCard.id
    );
    return { foundations, flippedPile };
  } else {
    // columnPiles[payload.movedCard.index] = columnPiles[
    //   payload.movedCard.index
    // ].filter((card) => card.id !== payload.movedCard.id);
    const cardsLeft = columnPiles[payload.movedCard.index].filter(
      (card) => card.id !== payload.movedCard.id
    );
    columnPiles[payload.movedCard.index] = cardsLeft;

    // if the cardsLeft is bigger than 0, there are more cards in the initial column
    if (cardsLeft.length > 0) {
      // get the last card of the initial column
      const lastCard = cardsLeft.length - 1;

      // if the last card has flipped = false, then make it true

      if (columnPiles[payload.movedCard.index][lastCard].hidden) {
        columnPiles[payload.movedCard.index][lastCard] = {
          ...columnPiles[payload.movedCard.index][lastCard],
          hidden: false,
        };
      }
    }
    return { foundations, columnPiles };
  }
};

/**
 * Checks if there is a valid move to a column
 */
export const checkDoubleClickValid = (
  { foundations, columnPiles, flippedPile, gameMoves, gamePreviousMoves },
  payload
) => {
  const suitIndex = suits.indexOf(payload.doubleClickedCard.suit);

  if (foundations[suitIndex].length + 1 === payload.doubleClickedCard.rank) {
    foundations[suitIndex].push({
      ...payload.doubleClickedCard,
      index: suitIndex,
      state: CardState.Foundation,
    });

    if (payload.doubleClickedCard.state === CardState.TableuPile) {
      // columnPiles[payload.doubleClickedCard.index] = columnPiles[
      //   payload.doubleClickedCard.index
      // ].slice(0, -1);
      const cardsLeft = columnPiles[payload.doubleClickedCard.index].slice(
        0,
        -1
      );
      columnPiles[payload.doubleClickedCard.index] = cardsLeft;

      // if the cardsLeft is bigger than 0, there are more cards in the initial column
      if (cardsLeft.length > 0) {
        // get the last card of the initial column
        const lastCard = cardsLeft.length - 1;
        // if the last card has flipped = false, then make it true
        if (columnPiles[payload.doubleClickedCard.index][lastCard].hidden) {
          columnPiles[payload.doubleClickedCard.index][lastCard] = {
            ...columnPiles[payload.doubleClickedCard.index][lastCard],
            hidden: false,
          };
        }
      }

      //add card to previous state (column to goal)
      let final = addGameMove(
        gamePreviousMoves,
        {
          source: `column${payload.doubleClickedCard.index}Pile`, //move from
          target: `goal${suitIndex}Pile`, //move to
          cards: [payload.doubleClickedCard],
          sourceIndex: payload.doubleClickedCard.index,
          targetIndex: suitIndex,
        },
        gameMoves
      );
      gamePreviousMoves = final.gamePreviousMoves;
      gameMoves = final.gameMoves;
    } else if (payload.doubleClickedCard.state === CardState.Deck) {
      flippedPile = flippedPile.slice(0, -1);

      //add card to previous state (deck or flipped file to goal)
      let final = addGameMove(
        gamePreviousMoves,
        {
          source: `deckPile`, //move from
          target: `goal${suitIndex}Pile`, //move to
          cards: [payload.doubleClickedCard],
          sourceIndex: payload.doubleClickedCard.index,
          targetIndex: suitIndex,
        },
        gameMoves
      );
      gamePreviousMoves = final.gamePreviousMoves;
      gameMoves = final.gameMoves;
    }
  }

  return {
    foundations,
    columnPiles,
    flippedPile,
    gamePreviousMoves,
    gameMoves,
  };
};

export const setTurnCard = ({ columnPiles }, payload) => {
  columnPiles[payload.index].map((card, i) => {
    if (card.id === payload.id && i === columnPiles[payload.index].length - 1) {
      card.hidden = false;
      return card;
    }
    return card;
  });

  return { columnPiles };
};

// ********************************************************
// GAME MOVES' HISTORY FUNCTIONS

/**
 * Adds a move to the list of previous moves
 * @param gamePreviousMoves list of previous moves stored
 * @param move game move to add
 * @param gameMoves current count of game moves
 */
export const addGameMove = (
  gamePreviousMoves,
  move,
  gameMoves,
  columnPiles
) => {
  let tempPreviousMoves = [];

  if (move.nCards !== undefined && move.nCards > 1) {
    //if moving cards is greather then one
    // create copy of the target column
    const copy = [...columnPiles[move.targetIndex]].map((card) => ({
      ...card,
      index: move.sourceIndex,
      isDragging: false,
    }));
    // get the index of the last card
    const targetLastIndex = copy.length;
    // get the cards that are moving
    const cardsMoving = copy.splice(targetLastIndex - move.nCards, move.nCards);
    //reset move cards array
    move.cards = cardsMoving;
  }
  // add the move to the list of previous moves
  tempPreviousMoves = [...gamePreviousMoves];
  tempPreviousMoves.push(move);

  return {
    gamePreviousMoves: tempPreviousMoves,
    gameMoves: gameMoves + 1, // add a new game move
    // gameNextMoves: [], // reset the game next moves
  };
};

/**
 * Flips one deck card to the flipped pile
 * @param deckPile
 * @param flippedPile
 */
export const unflipDeckCard = (deckPile, flippedPile) => {
  // create copy of the flipped pile
  const tempFlippedPile = [...flippedPile];
  // get the top card of the flipped pile
  // const cardFlipped = tempFlippedPile.pop(); //O
  const cardFlippedArray = tempFlippedPile.splice(-3, 3); //N
  // get copy of the deck pile
  const tempDeckPile = [...deckPile];
  const finalTempDeckPile = [...tempDeckPile, ...cardFlippedArray]; //N

  // if there was indeed a card to be flipped, then add it to the deck pile
  // if (cardFlipped) {
  //   //O
  //   tempDeckPile.push({
  //     ...cardFlipped,
  //     //  hidden: true
  //   });
  // }

  return {
    // deckPile: tempDeckPile, //O
    deckPile: finalTempDeckPile, //N
    flippedPile: tempFlippedPile,
  };
};

export const removeGameMove = (
  sourceId,
  targetId,
  gameMoveSource,
  gameMoveTarget,
  gameMoves
) => {
  // create copy of the list of moves
  const tempGameMoveSource = [...gameMoveSource];
  const tempGameMoveTarget = [...gameMoveTarget];
  // remove the top move of the source list
  const moveUndone = tempGameMoveSource.pop();
  // add that move to the target list
  tempGameMoveTarget.push(moveUndone);

  return {
    [sourceId]: tempGameMoveSource,
    [targetId]: tempGameMoveTarget,
    gameMoves: gameMoves + 1, // add a new game move
  };
};

/**
 * Removes back 1 card from a goal (the cards are from a undo-redo movement)
 * @param goals
 * @param goalId id of the goal the cards will be removed from
 */
export const removeCardFromGoal = (foundations, foundationIndex) => {
  // get copy of the goal
  const tempFoundatipons = [...foundations[foundationIndex]];

  // remove the top card
  tempFoundatipons.pop();

  foundations[foundationIndex] = tempFoundatipons;
  return {
    foundations,
  };
};

/**
 * Adds back to a column, a card from a undo/redo movement
 * @param columns
 * @param columnId id of the column to add the card to
 * @param card card to be added
 * @param movementWithFlip true if the move caused a card flip
 */
export const addCardToColumn = (columnPiles, columnIndex, card) => {
  // create a copy of the column
  const column = [...columnPiles[columnIndex]];
  // get the number of cards in the column
  const nCards = column.length;

  // check if the column has cards and if the movement caused a card flip
  if (nCards > 0) {
    // flip back the column last card
    column[nCards - 1] = { ...column[nCards - 1], hidden: true };
  }

  // add the cards to the final column
  column.push({ ...card, hidden: false });
  columnPiles[columnIndex] = column;
  // return the changes in the column
  return {
    columnPiles,
  };
};

/**
 * Removes back N cards from a column (the cards are from a undo-redo movement)
 * @param columns
 * @param columnId id of the column the cards will be removed from
 * @param nCards number of cards to remove
 * @param movementWithFlip true if the move caused a card flip
 */
export const removeNCardsFromColumn = (columnPiles, columnIndex, nCards) => {
  // create copy of the column
  const tempCol = [...columnPiles[columnIndex]];

  // remove the last card
  tempCol.splice(-nCards, 1);

  // get index of last card
  // const lastCard = tempCol.length - 1;

  // let finalMovementWithFlip = movementWithFlip;
  // if the last card has flipped = false, then make it true
  // if (lastCard >= 0 && movementWithFlip) {
  //   tempCol[lastCard] = {
  //     ...tempCol[lastCard],
  //     hidden: true,
  //   };
  // }
  columnPiles[columnIndex] = tempCol;

  return {
    columnPiles,
  };
};

/**
 * Adds back to the flipped pile, a card from a undo/redo movement
 * @param flippedPile
 * @param card card to be added
 */
export const addCardToFlipped = (flippedPile, card) => {
  //new logic - 08-07-2023
  if (card?.positionIndex !== undefined) {
    flippedPile.splice(card?.positionIndex, 0, card);
  } else {
    flippedPile = [...flippedPile, { ...card }];
  }

  return {
    // flippedPile: [...flippedPile, { ...card }],
    flippedPile: flippedPile,
  };
};

/**
 * Undo the swap movement between 2 columns
 * @param columns
 * @param targetIndex id of the column the cards will be removed from
 * @param sourceIndex id of the column the cards will be added to
 * @param nCards number of cards to swap*/
export const undoSwapColumns = (
  columnPiles,
  targetIndex,
  sourceIndex,
  nCards
) => {
  // create a copy of the initial column
  const targetCol = [...columnPiles[targetIndex]];
  // create a copy of the final column
  const sourceCol = [...columnPiles[sourceIndex]];

  // get the cards to swap
  const cardsToSwap = targetCol.splice(-nCards, nCards);

  // flip the cards from the final column
  // get the index of the last card from the final column
  const lastSourceCol = sourceCol.length - 1;
  // flip the last card back
  sourceCol[lastSourceCol] = { ...sourceCol[lastSourceCol], hidden: true };

  // add the swapped cards to the final column (make sure that it is flipped)
  cardsToSwap.map((card) => sourceCol.push({ ...card }));

  // return the changes made in the initial and final column
  columnPiles[targetIndex] = targetCol;
  columnPiles[sourceIndex] = sourceCol;
  return {
    columnPiles,
  };
};

/**
 * Adds back to a goal, a card from a undo/redo movement
 * @param goals
 * @param goalId id of the goal to add the card to
 * @param card card to be added
 */
export const addCardToGoal = (foundations, sourceIndex, card) => {
  // create a copy of the goal
  const goal = [...foundations[sourceIndex]];

  // add the cards to the final goal
  goal.push({ ...card });

  // return the changes in the goal
  foundations[sourceIndex] = goal;
  return {
    foundations,
  };
};
