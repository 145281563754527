import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getStartLobbyInfo } from "../../../../store/actions/lobbyAction";
import { useParams } from "react-router-dom";
import LobbyTimer from "../../../common/LobbyTimer";
import EmptyTimer from "../../../common/EmptyTimer";

export const GameStartRemainingTimeModal = ({ onHide, show }) => {
  const { joining_date, lobby_id } = useParams();
  const dispatch = useDispatch();
  const [timeDiffrence, setTimeDiffrence] = useState(0);

  useEffect(() => {
    dispatch(getStartLobbyInfo({ tbl_id: lobby_id, date: joining_date }))
      .then((result) => {
        if (result) {
          setTimeDiffrence(result.data.time_diffrence);
        } else {
          onHide();
        }
      })
      .catch((err) => {
        onHide();
      });
  }, [dispatch]);

  return (
    <>
      <Modal
        centered
        className="border-0 border-radius-0 lobby_modal"
        show={show}
        backdrop
      >
        <Modal.Body className="border-0 p-0">
          <div className="pass_word_c">
            <h5 className="mb-1">Your game is about to start!</h5>

            {timeDiffrence > 0 ? (
              <LobbyTimer time_diffrence={Number(timeDiffrence)} />
            ) : (
              <EmptyTimer />
            )}
            <p className="mb-5">
              Get ready, the game will automatically start for all players when
              the countdown ends.
            </p>
          </div>

          <div className="main_btn  mt-3 bg__black">
            <button className="w-100 black__btn" onClick={onHide}>
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
