import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./reducers/rootReducer.js";
import thunkMiddleware from "redux-thunk";
// import { persistStore } from "redux-persist";

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunkMiddleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;
// export const persistor = persistStore(store);
