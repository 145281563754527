import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SiteLoader from "../../shared/SiteLoader/SiteLoader";
import { getGameHistories } from "../../../store/actions/gameBoardAction";
import { NAVIGATION } from "../../common/constant";
import { storeTempLobbyId } from "../../../store/common/utils";
// import { color } from "framer-motion";
const History = ({ getGameHistories, loading, lists, history }) => {
  
  useEffect(() => {
    getGameHistories();
  }, [getGameHistories]);

  return (
    <div>
      <div className="settiings open_prf">
        <h5>
          <span>History</span>
        </h5>
        {loading ? <SiteLoader /> : null}

        <ul className="gaming_list">
          {lists &&
            lists.length > 0 &&
            lists.map((list, i) => {
              return (
                <li key={`history_${i}`}>
                  <div className="game_detail_card">
                    <div className="nine_game">
                      <div className="loc_box">
                        <span>{list.user_count} Players</span>
                        <h4>{list.place === "0th Place" ? "Did not play" : list.place}</h4>
                      </div>
                      <div className="loc_box">
                        <span>{list.date}</span>
                        <h4>{list.time}</h4>
                      </div>
                      <div className="loc_box">
                        <span>Points</span>
                        <h4 className="points">{list.points}</h4>
                      </div>
                    </div>
                    <span
                      onClick={() => {
                        storeTempLobbyId(list.lobby_id);
                        history.push(
                          `${NAVIGATION.GAME_RESULT}/${list.lobby_id}`
                        );
                      }}
                      className="view_gam"
                    >
                      View Game
                    </span>
                  </div>
                </li>
              );
            })}
          {lists.length === 0 && (
            <li className="text-center">No history found</li>
          )}
        </ul>
      </div>
    </div>
  );
};
const mapStateToProps = ({ game_history: { lists, loading } }) => {
  return {
    loading,
    lists,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getGameHistories: (payload) => dispatch(getGameHistories(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(History));
