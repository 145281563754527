import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../../../store/actions/authAction";
export const LogoutModal = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div>
      <Modal
        className="border-0 p-0"
        centered
        show={props.show}
        onHide={props.onHide}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0 p-0">
          <div className="pass_word_c">
            <span>Ready to</span>
            <h5>Log out?</h5>
          </div>
          <div className="d-flex align-items-center main_btn close_kl button bank_btn mt-3">
            <button onClick={() => props.onHide()}>Cancel</button>
            <button onClick={() => dispatch(logout(history))}>
              <span>Logout</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
