import React, { useEffect, useState } from "react";
import { Prompt, useLocation } from "react-router-dom";
import { ConfirmBox } from "./ConfirmBox";

export const RouteLeavingGuard = ({
  navigate,
  when,
  shouldBlockNavigation,
  yes,
  no,
  message,
  title,
}) => {
  const [modalVisible, updateModalVisible] = useState(false);
  const [lastLocation, updateLastLocation] = useState();
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

  const location = useLocation();

  const showModal = (location) => {
    updateModalVisible(true);
    updateLastLocation(location);
  };

  const closeModal = (cb) => {
    updateModalVisible(false);
    if (cb) {
      cb();
    }
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        if (lastLocation.pathname === location.pathname) {
          updateConfirmedNavigation(false);
          navigate("/lobby");
        } else {
          updateConfirmedNavigation(true);
        }
        // updateConfirmedNavigation(true);
      }
    });
  };

  useEffect(() => {
    if (confirmedNavigation) {
      navigate(lastLocation.pathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {modalVisible && (
        <ConfirmBox
          yes={yes}
          no={no}
          visible={modalVisible}
          onCancel={closeModal}
          onConfirm={handleConfirmNavigationClick}
          message={message}
          title={title ?? "Alert"}
        />
      )}
    </>
  );
};

export default RouteLeavingGuard;
