import {
  FETCH_LOTTERY_LIST_ERROR,
  FETCH_LOTTERY_LIST_LIST,
  FETCH_TRANSACTION_LIST_ERROR,
  FETCH_TRANSACTION_LIST_LIST,
  SET_LOTTERY_LIST_LIST,
  SET_TRANSACTION_LIST_LIST,
} from "../common/types";

const initialState = {
  error: "",
  loading: false,
  lists: [],
  walletList:{}
};
export const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION_LIST_LIST:
      return {
        ...state,
        loading: true,
      };
    case SET_TRANSACTION_LIST_LIST:
      return {
        ...state,
        lists: [...action.payload],
        walletList:action.wallate_list,
        loading: false,
      };

    case FETCH_TRANSACTION_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

const initialState2 = {
  error: "",
  loading: false,
  lists: [],
  lottery_total: 0,
};
export const lotteryReducer = (state = initialState2, action) => {
  switch (action.type) {
    case FETCH_LOTTERY_LIST_LIST:
      return {
        ...state,
        loading: true,
      };
    case SET_LOTTERY_LIST_LIST:
      return {
        ...state,
        lists: [...action.payload],
        lottery_total: action.lottery_total,
        loading: false,
      };

    case FETCH_LOTTERY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        lottery_total: 0,
      };

    default:
      return state;
  }
};
