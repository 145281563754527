import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyAf2W7q_i2EEeixial_Rn4UofetGc8o0tE",
//   authDomain: "provido-react-app.firebaseapp.com",
//   projectId: "provido-react-app",
//   storageBucket: "provido-react-app.appspot.com",
//   messagingSenderId: "849240014915",
//   appId: "1:849240014915:web:8368a47d2306d7e9abd4f6",
//   measurementId: "G-HQV12X3EJD",
// };
const firebaseConfig = {
  apiKey: "AIzaSyDfz2Cu4q59pHsYlwi-Lx5jEGWCPOyH-3g",
  authDomain: "casino-solitaire.firebaseapp.com",
  projectId: "casino-solitaire",
  storageBucket: "casino-solitaire.appspot.com",
  messagingSenderId: "960259403686",
  appId: "1:960259403686:web:64dc479d8927a9613c2967",
  measurementId: "G-NX2K34ZQ0X",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
console.log("Initializing Firebase", firestore);
export { firebase, firestore };
