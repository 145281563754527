import React from "react";
import { Icon } from "@iconify/react";
export const Controllers = () => {
  return (
    <div className="controllers_list">
      <div className="new_box">
        <div className="first_box">
          <Icon icon="vaadin:stopwatch" />
          <span>11:20</span>
        </div>
        <div className="first_box">
          <Icon icon="iconoir:open-select-hand-gesture" />
          <span>Moves :7</span>
        </div>
        <div className="first_box" style={{ padding: "4px 10px" }}>
          <div className="main_hand">
            <Icon icon="material-symbols:refresh" />
            <span>Redo Hand</span>
          </div>
          <div>
            <span>Remaining :7</span>
          </div>
        </div>
      </div>
      <div className="new_box">
        <div className="first_box">
          <Icon icon="arcticons:solitairecg" />
          <span>Current Hand :7</span>
        </div>
      </div>
    </div>
  );
};
