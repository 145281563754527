import { firestore, firebase } from "../../config/firebase";
import { SET_LOBBY_PARTICIPANT } from "../common/types";
import { updateLobbyListFromFirestore } from "./lobbyAction";

const LOBBY_COLLECTION = "lobby";

//lobby collection
const lobbyCollection = firestore.collection(LOBBY_COLLECTION);

// store lobby participant data on firestore
export const createAndUpdateLobby = async (docId, obj) => {
  //check first user join or not
  const querySnapshot = await lobbyCollection.doc(docId).get();
  let isAvailable = querySnapshot.data();

  if (!isAvailable && isAvailable === undefined) {
    return await lobbyCollection.doc(docId).set(
      {
        lobbyId: docId,
        complete_players: [],
        participants: [{ ...obj }],
      },
      { merge: true }
    );
  } else {
    return await lobbyCollection.doc(docId).update({
      lobbyId: docId,
      complete_players: [],
      participants: firebase.firestore.FieldValue.arrayUnion({
        ...obj,
      }),
    });
  }
};

//get lobby data
export const getLobby = async (docId, dispatch) => {
  let unsubRef = lobbyCollection.doc(docId).onSnapshot((snapshot) => {
    // console.log(snapshot.data());
    let participants = snapshot.data() ? snapshot.data().participants : [];
    if (participants.length > 0)
      dispatch({
        type: SET_LOBBY_PARTICIPANT,
        payload: participants || [],
      });
    // console.log(participants);
  });
  return unsubRef;
};

//get lobby all data
// "a1e14e31-64f0-46ad-8552-ee7bef956ba5",
// "d8264ce3-3e1e-4f6d-ba30-7d807e3a6334",

// export const getAllLobby = async (ids, dispatch) => {
//   // console.log(ids);
//   let unsubRef = lobbyCollection
//     .where("lobbyId", "in", [...ids])
//     .onSnapshot((snapshots) => {
//       snapshots.forEach((doc) => {
//         let participants = doc.data() ? doc.data().participants : [];

//         if (participants.length > 0)
//           dispatch(updateLobbyListFromFirestore(doc.id, participants));
//       });
//     });
//   return unsubRef;
// };

export const getAllLobby = (ids, dispatch) => {
  // Split the array of IDs into chunks of 30 or fewer IDs
  const chunkSize = 30;
  const idChunks = [];
  for (let i = 0; i < ids.length; i += chunkSize) {
    idChunks.push(ids.slice(i, i + chunkSize));
  }

  // Array to store unsubscribe functions
  const unsubRefArray = [];

  // Perform queries for each chunk of IDs
  for (const chunk of idChunks) {
    const unsubRef = lobbyCollection
      .where("lobbyId", "in", chunk)
      .onSnapshot((snapshots) => {
        snapshots.forEach((doc) => {
          const participants = doc.data() ? doc.data().participants : [];

          if (participants.length > 0)
            dispatch(updateLobbyListFromFirestore(doc.id, participants));
        });
      });

    unsubRefArray.push(unsubRef);
  }

  // Return an array of unsubscribe functions
  return unsubRefArray;
};

//update to update lobby when player complete game
export const updateLobbyOnFirstore = async (docId, obj) => {
  const querySnapshot = await lobbyCollection.doc(docId).get();
  let isAvailable = querySnapshot.data();
  if (isAvailable) {
    return await lobbyCollection.doc(docId).update({
      lobbyId: docId,
      complete_players: firebase.firestore.FieldValue.arrayUnion({
        ...obj,
      }),
    });
  }
};

//update to update lobby when player complete game
export const deleteUserFromLobbyOnFirstore = async (
  docId,
  userId,
) => {
  const documentRef = lobbyCollection.doc(docId);

  try {
    const doc = await documentRef.get();
    if (doc.exists) {
      const documentData = doc.data();
      // Modify the participants array to remove the specified user object
      const updatedParticipants = documentData.participants.filter(
        (participant) => participant.user_id !== userId
      );
      console.log("USER HAS BEEN REMOVED FROM FIRESTORE");
      await documentRef.update({
        participants: updatedParticipants,
      });
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.error("Error deleting user from participants array: ", error);
  }
};

//get lobby data
export const observeLobbyForCheckGameStatus = async (
  docId,
  setCanResultAnounced
) => {
  let unsubRef = lobbyCollection.doc(docId).onSnapshot((snapshot) => {
    // console.log(snapshot.data());
    let complete_players = snapshot.data()
      ? snapshot.data().complete_players
      : [];
    let participants = snapshot.data() ? snapshot.data().participants : [];
    if (complete_players.length > 0 && participants.length > 0) {
      console.log(
        `TOTAL PARTICIAPNT: ${participants.length} | COMPLETE PLAYER: ${complete_players.length}`
      );
      if (participants.length === complete_players.length) {
        setCanResultAnounced(true);
      }
    }
  });
  return unsubRef;
};
