import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
//import { Header } from '../../Landingpage/Header'

import { Route, withRouter } from "react-router-dom";
import "./GameBoard/Gameboard.css";
import { GameHeader } from "./GameHeader";
import "./game.css";
//import Profile from './Profile/Profile';
import Dashboard from "./Dashboard/Dashboard";
import AccountSettings from "./Settings/AccountSettings";
import Contactus from "./Settings/Contactus";
import Settings from "./Settings/Settings";

import { connect } from "react-redux";
import { getProfileInfo } from "../../store/actions/authAction";
import YourLobby from "./Dashboard/YourLobby";
import GameResults from "./GameBoard/GameResults";
import GameResultsBreakDown from "./GameBoard/GameResultsBreakDown";
import GameStarter from "./GameBoard/GameStarter";
import History from "./History/History";
import Lottery from "./Lottery/Lottery";
import privacy from "./Settings/privacy";
import TermCondition from "./Settings/term-condition";
import EditProfile from "./Userprofile/EditProfile";
import UserProfile from "./Userprofile/UserProfile";
import Wallet from "./Wallet/Wallet";
import YourTransaction from "./Wallet/YourTransaction";
import Maingame from "./gamearea/Maingame";
import Rules from "./Rules/rules";

const GameDashboardlayout = ({ getProfileInfo }) => {
  useEffect(() => {
    getProfileInfo();
  }, [getProfileInfo]);

  return (
    <div>
      <GameHeader />
      <div className="login_box new_game">
        <div className="container-fluid container-md">
          <Row>
            <Col className="p-0">
              <Route exact path={`/lobby/game`} component={Maingame} />
              <Route exact path={`/lobby/game-board`} component={GameStarter} />
              <Route
                exact
                path={`/lobby/results/:lobbyId`}
                component={GameResults}
              />
              <Route
                exact
                path={`/lobby/results-breakdown/:lobbyId`}
                component={GameResultsBreakDown}
              />

              {/*Lobby*/}
              <Route exact path="/lobby" component={Dashboard} />
              <Route
                exact
                path="/lobby/your-lobby/:lobby_id/:joining_date"
                component={YourLobby}
              />
              {/*Settings*/}
              <Route exact path="/lobby/settings" component={Settings} />
              <Route
                exact
                path="/lobby/settings/contactus"
                component={Contactus}
              />
              <Route
                exact
                path="/lobby/settings/accountsetting"
                component={AccountSettings}
              />
              <Route
                exact
                path="/lobby/settings/trem-condition"
                component={TermCondition}
              />
              <Route exact path="/lobby/settings/privacy" component={privacy} />
              {/* Profile */}
              <Route exact path="/lobby/profile" component={UserProfile} />
              <Route exact path="/lobby/edit-profile" component={EditProfile} />
              {/*Wallet*/}
              <Route exact path="/lobby/my-wallet" component={Wallet} />
              <Route
                exact
                path="/lobby/my-wallet/my-transaction"
                component={YourTransaction}
              />
              {/*History*/}
              <Route exact path="/lobby/history" component={History} />
              <Route exact path="/lobby/rules" component={Rules} />
              {/*Lottery*/}
              <Route exact path="/lobby/lottery" component={Lottery} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileInfo: () => dispatch(getProfileInfo()),
  };
};
export default connect(
  null,
  mapDispatchToProps
)(withRouter(GameDashboardlayout));
