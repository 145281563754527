import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const CountDownTimer = ({ initialCount, onHide }) => {
  return (
    <div className="timer-wrapper">
      <CountdownCircleTimer
        isPlaying
        duration={initialCount}
        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
        onComplete={onHide}
        size={80}
        strokeWidth={5}
        colorsTime={[
          initialCount / 1,
          initialCount / 50,
          initialCount / 20,
          initialCount / 10,
        ]}
      >
        {({ remainingTime }) => <div className="timer">{remainingTime}</div>}
      </CountdownCircleTimer>
    </div>
  );
};

export default CountDownTimer;
