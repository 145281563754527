import React, { memo, useEffect, useMemo } from "react";
import { useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { CardState } from "../../../../../store/gameBoard/game.constants";
import { getEmptyImage } from "react-dnd-html5-backend";
import gameBoardActions from "../../../../../store/gameBoard/gameBoard.actions";
import { suits } from "../../../../../store/gameBoard/gameBoard.utils";
import CardSound from "../../../../../assets/card-slide-sound.wav";

const Card = ({ card, offset, isLastCard, nCards, leftOffset }) => {
  const { hidden, id, index, type } = card;
  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => state.profile.details);

  //sound object
  const cardSound = useMemo(() => new Audio(CardSound), []);

  useEffect(() => {
    const updateVolume = () => {
      const newVolume = parseFloat(profileInfo.sound);
      if (
        !isNaN(newVolume) &&
        isFinite(newVolume) &&
        newVolume >= 0 &&
        newVolume <= 1
      ) {
        cardSound.volume = newVolume; // Set the volume property
      } else {
        console.error("Invalid volume value:", newVolume);
      }
    };
    profileInfo && updateVolume();
  }, [cardSound, profileInfo]);

  const checkDoubleClick = (card, nextFoundationCards, dispatch) => {
    if (nextFoundationCards.find((nextCard) => nextCard === card.id)) {
      // dispatch(gameBoardActions.handleDoubleClick({ doubleClickedCard: card }));
      //let sound = getToggleSoundVal() ?? "ON";
      // if (sound === "ON") cardSound.play();
    }
  };

  const nextFoundationCards = useSelector((state) => {
    let { foundations } = state.gameBoard;
    return foundations.map(
      (foundation, index) => `${foundation.length + 1}${suits[index]}`
    );
  });

  // useDrag will be responsible for making an element draggable. It also expose, isDragging method to add any styles while dragging
  const [{ isDragging, item }, drag, preview] = useDrag({
    item: { type, card },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        let sound = profileInfo?.sound ?? 0.5;
        if (sound > 0) cardSound.play();
        const nextState = dropResult.nextState;
        if (nextState === CardState.TableuPile) {
          //get previous state of moving card
          // let previousState =
          //   item.card.state === CardState.Deck ? "deckPile" : "columnPile";
          let previousState = item.card.state;

          dispatch(
            gameBoardActions.addDraggingCardsToColumn({
              movedCard: item.card,
              index: dropResult.index,
              nextState,
            })
          );
          // add game move
          if (previousState === CardState.Deck) {
            dispatch(
              gameBoardActions.addGameMove({
                source: "deckPile",
                target: `column${dropResult.index}Pile`, //move to
                sourceIndex: undefined, //nothing
                targetIndex: dropResult.index,
                cards: [item.card],
              })
            );
          } else if (previousState === CardState.Foundation) {
            dispatch(
              gameBoardActions.addGameMove({
                source: `goal${item.card.index}Pile`, //move from
                target: `column${dropResult.index}Pile`, //move to
                sourceIndex: item.card.index,
                targetIndex: dropResult.index,
                cards: [item.card],
              })
            );
          } else {
            dispatch(
              gameBoardActions.addGameMove({
                source: `column${item.card.index}Pile`, //move from
                target: `column${dropResult.index}Pile`, //move to
                sourceIndex: item.card.index,
                targetIndex: dropResult.index,
                cards: [item.card],
                // movementWithFlip,
                nCards,
              })
            );
          }
        } else if (nextState === CardState.Foundation) {
          //get previous state of moving card
          let previousState =
            item.card.state === CardState.Deck ? "deckPile" : "columnPile";

          dispatch(
            gameBoardActions.addDraggingCardsToGoal({
              movedCard: item.card,
              index: dropResult.index,
              nextState,
            })
          );

          // add game move
          if (previousState === "deckPile") {
            // add game move
            dispatch(
              gameBoardActions.addGameMove({
                source: `deckPile`, //move from
                target: `goal${dropResult.index}Pile`, //move to
                cards: [item.card],
                sourceIndex: item.card.index,
                targetIndex: dropResult.index,
                // movementWithFlip,
              })
            );
          } else {
            dispatch(
              gameBoardActions.addGameMove({
                source: `column${item.card.index}Pile`, //move from
                target: `goal${dropResult.index}Pile`, //move to
                cards: [item.card],
                sourceIndex: item.card.index,
                targetIndex: dropResult.index,
                // movementWithFlip,
              })
            );
          }
        }
      }
      if (item && !dropResult) {
        dispatch(
          gameBoardActions.dragFlippedCard({
            movedCard: item.card,
            isDragging: false,
          })
        );
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
    }),
    canDrag: () => (card.hidden ? false : true),
  });

  useEffect(() => {
    if (item && item.card && isDragging) {
      dispatch(
        gameBoardActions.dragFlippedCard({ movedCard: item.card, isDragging })
      );
    }
  }, [isDragging, item, dispatch]);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: false });
  }, [preview]);

  return (
    <img
      alt={card.id}
      ref={card.hidden ? null : drag}
      className="card__image"
      src={
        hidden
          ? require(`../../../../../assets/cards/1B.png`)
          : require(`../../../../../assets/cards/${id}.png`)
      }
      style={{
        top: offset,
        display: card.isDragging ? "none" : undefined,
        left: leftOffset,
      }}
      onClick={
        hidden
          ? () => dispatch(gameBoardActions.turnCard({ index, id }))
          : undefined
      }
      onDoubleClick={
        isLastCard
          ? () => checkDoubleClick(card, nextFoundationCards, dispatch)
          : undefined
      }
      onDragStart={(e) => {
        if (card.hidden) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    />
  );
};

export default memo(Card);
