import React,{useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import imageurl from '../common/images'
import { motion, useTime,useTransform} from "framer-motion"
import AOS from 'aos';
import { HashLink as NavLink } from 'react-router-hash-link';
export const HeroSection = () => {
   
        useEffect(() => {
            AOS.init();
          }, [])
    const time = useTime()
    const rotate = useTransform(
        time,
        [0, 10000], // For every 4 seconds...
        [0, 360], // ...rotate 360deg
        { clamp: false }
    )
    return (
        <div className='hero_section'>
            <Container>
                <Row>
                    <Col lg={6}>
                        <div className="grow_box "  data-aos="fade-up" data-aos-duration="3000">
                            <div className="main">
                                <span className='d-block mb-2'>Casino Solitaire</span>
                                <h1><b>Play Solitaire</b><br />
                                    <b>& Earn Cash</b></h1>
                                <p>Complete head to head against other players and try to place highest for cash rewards!</p>
                               <NavLink smooth to="#play"> <button className='login_btn w-50'><img src={imageurl.rightleaf} alt="" />play<img src={imageurl.leftleaf} alt="" /></button></NavLink>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="grow_box" data-aos="fade-up" data-aos-duration="3000" style={{justifyContent:"end"}}>
                            <motion.div
                                style={{ rotate }}

                            >
                                <div className="intro_img">
                                    <img src={imageurl.hero_banner} alt="" />
                                </div>

                            </motion.div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
