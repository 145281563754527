import React from "react";
import "../auth.css";
import { Container, Row, Form, Col } from "react-bootstrap";
import { Header } from "../../Landingpage/Header";
import TextError from "../../common/TextError";
import { Icon } from "@iconify/react";
import BtnSpinner from "../../common/BtnSpinner";
import imageurl from "../../common/images";
import { ErrorMessage, Field, Formik } from "formik";
import { registerValidation } from "../../common/validation";
import { register } from "../../../store/actions/authAction";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useState } from "react";
import TermsAndConditionDialog from "./TermsAndConditionDialog";
const Registration = ({ submitting, register }) => {
  const history = useHistory();
  const [show, setShow] = useState(false);


  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    register(values, resetForm, history);
    setSubmitting(false);
  };
  return (
    <>
      <>
        <Header />
        <div className="login_box">
          <div className="grey_box">
            <Container>
              <Row>
                <Col className="p-0">
                  <h5>Create a new account</h5>
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      password: "",
                      terms_accepted: false,
                      is_eligible: false,
                    }}
                    validationSchema={registerValidation}
                    onSubmit={handleSubmit}
                  >
                    {({ handleSubmit, errors }) => (
                      <Form onSubmit={handleSubmit} className="main_make mb-3">
                        <Form.Group
                          className="mb-3 group"
                          controlId="formBasicEmail"
                        >
                          <div className="position-relative">
                            <Icon icon="circum:mail" />
                            <Field
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              autoComplete="off"
                              name="email"
                            />
                          </div>
                          <ErrorMessage name="email" component={TextError} />
                        </Form.Group>
                        <Form.Group
                          className="mb-3 group"
                          controlId="formBasicEmail"
                        >
                          <div className="position-relative">
                            <Icon icon="mingcute:user-1-line" />
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Username"
                              autoComplete="off"
                              name="name"
                            />
                          </div>
                          <ErrorMessage name="name" component={TextError} />
                        </Form.Group>
                        <Form.Group
                          className="mb-4 group"
                          controlId="formBasicPassword"
                        >
                          <div className="position-relative">
                            <Icon icon="ph:lock-simple-light" />
                            <Field
                              type="password"
                              className="form-control"
                              placeholder="******"
                              autoComplete="off"
                              name="password"
                            />
                          </div>
                          <ErrorMessage name="password" component={TextError} />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        >
                          <Field name="is_eligible">
                            {({ field }) => (
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  {...field}
                                  checked={field.value}
                                  className="form-check-input"
                                  id="age__checkbox"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="age__checkbox"
                                >
                                  I am 18 or older
                                </label>
                              </div>
                            )}
                          </Field>
                          <ErrorMessage
                            name="is_eligible"
                            component={TextError}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox1"
                        >
                          {/* <Form.Check
                          type="checkbox"
                          label={`I agree to the Terms & Conditions`}
                        /> */}
                          <Field name="terms_accepted">
                            {({ field }) => (
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  {...field}
                                  checked={field.value}
                                  className="form-check-input"
                                  id="toc__checkbox"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="toc__checkbox"
                                >
                                  I agree to the <a href="#">Terms & Conditions</a>
                                </label>
                              </div>
                            )}
                          </Field>
                          <ErrorMessage
                            name="terms_accepted"
                            component={TextError}
                          />
                        </Form.Group>
                        <button
                          type="submit"
                          className="login_btn w-100"
                          disabled={submitting}
                        >
                          <img src={imageurl.rightleaf} alt="" />
                          {submitting ? <BtnSpinner /> : <span>SIGN UP</span>}

                          <img src={imageurl.leftleaf} alt="" />
                        </button>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
      <TermsAndConditionDialog show={show} onClose={() => setShow(false)} />
    </>
  );
};
const mapStateToProps = ({ form }) => {
  return {
    submitting: form.submitting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: (payload, resetForm, history) =>
      dispatch(register(payload, resetForm, history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
