import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const Addbalance = (props) => {
  let history = useHistory();
  return (
    <div>
      <Modal
        centered
        className="border-0 border-radius-0 lobby_modal"
        show={props.show}
        onHide={props.onHide}
      >
        <Modal.Body className="border-0 p-0">
          <div className="pass_word_c">
            <span className="mb-2 d-block">
              Balance:<b>$200</b>
            </span>
            <p className="mb-5">
              Players must have at least $300 in their wallet to join a game
            </p>
          </div>

          <div className="main_btn close_kl bank_btn mt-3">
            <button onClick={props.onHide} className="">
              Cancel
            </button>
            <button className="">
              <span onClick={() => history.push("/lobby/payment")}>Top Up</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
