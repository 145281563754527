import React from "react";
import { withRouter } from "react-router-dom";
import { Controllers } from "./Controllers";
import { Gameboard } from "./Gameboard";

const Maingame = () => {
  return (
    <div>
      <Gameboard />
      <div className="controllers">
        <Controllers />
      </div>
    </div>
  );
};
export default withRouter(Maingame);
