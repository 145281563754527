import React from "react";
import { Modal } from "react-bootstrap";

export const NotEnoughPlayerModal = (props) => {
  return (
    <div>
      <Modal
        centered
        className="border-0 border-radius-0 lobby_modal"
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
      >
        <Modal.Body className="border-0 p-0">
          <div className="pass_word_c">
            <p className="mb-5">
              Not enough players, please join the next timeslot
            </p>
          </div>

          <div className="main_btn  mt-3 bg__black">
            <button className="w-100 black__btn" onClick={props.onHide}>
              Return
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
