import { Icon } from "@iconify/react";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { changePassword } from "../../../store/actions/authAction";
import { Header } from "../../Landingpage/Header";
import BtnSpinner from "../../common/BtnSpinner";
import TextError from "../../common/TextError";
import imageurl from "../../common/images";
import { changePasswordValidation } from "../../common/validation";
import { PasswordConfirmationModal } from "../Modal/PasswordConfirmationModal";
import "../auth.css";
const ResetPassword = ({ changePassword, submitting }) => {
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfim, setShowConfirmPassword] = useState(false);

  // const history = useHistory();
  const { token, email } = useParams();
  const handleSubmit = (values, { setSubmitting }) => {
    // let email = localStorage.getItem("reset_email");
    changePassword({ email, token, ...values }, setShow);
    setSubmitting(false);
  };
  return (
    <div>
      <Header />
      <div className="login_box">
        <div className="grey_box">
          <Container>
            <Row>
              <Col className="p-0">
                <h5>Reset Your Password</h5>{" "}
                <Formik
                  initialValues={{
                    password: "",
                    password_confirmation: "",
                  }}
                  validationSchema={changePasswordValidation}
                  onSubmit={handleSubmit}
                >
                  {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="main_make mb-3">
                      <Form.Group
                        className="mb-2 group"
                        controlId="formBasicPassword"
                      >
                        <div className="position-relative">
                          <Icon icon="ph:lock-simple-light" />
                          <Field
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="New Password"
                            autoComplete="off"
                            name="password"
                          />
                          <span
                            className="Icon-eye"
                            onClick={() =>
                              setShowPassword(showPassword ? false : true)
                            }
                          >
                            {!showPassword ? (
                              <AiFillEye />
                            ) : (
                              <AiFillEyeInvisible />
                            )}
                            {/* <AiFillEyeInvisible onClick={()=>setShowPassword(showPassword?false:true)}/> */}
                          </span>
                        </div>
                        <ErrorMessage name="password" component={TextError} />
                      </Form.Group>

                      <Form.Group
                        className="mb-4 group"
                        controlId="formBasicPassword"
                      >
                        <div className="position-relative">
                          <Icon icon="ph:lock-simple-light" />
                          <Field
                            type={showPasswordConfim ? "text" : "password"}
                            className="form-control"
                            placeholder="Repeat New Password"
                            autoComplete="off"
                            name="password_confirmation"
                          />
                          <span
                            className="Icon-eye"
                            onClick={() =>
                              setShowConfirmPassword(
                                showPasswordConfim ? false : true
                              )
                            }
                          >
                            {!showPasswordConfim ? (
                              <AiFillEye />
                            ) : (
                              <AiFillEyeInvisible />
                            )}
                            {/* <AiFillEyeInvisible onClick={()=>setShowPassword(showPassword?false:true)}/> */}
                          </span>
                        </div>
                        <ErrorMessage
                          name="password_confirmation"
                          component={TextError}
                        />
                      </Form.Group>

                      <button className="login_btn w-100" disabled={submitting}>
                        <img src={imageurl.rightleaf} alt="" />

                        {submitting ? (
                          <BtnSpinner />
                        ) : (
                          <span>UPDATE PASSWORD</span>
                        )}
                        <img src={imageurl.leftleaf} alt="" />
                      </button>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
            {show && (
              <PasswordConfirmationModal
                show={show}
                onHide={() => setShow(false)}
              />
            )}
          </Container>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ form }) => {
  return {
    submitting: form.submitting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (payload, setShow) =>
      dispatch(changePassword(payload, setShow)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
