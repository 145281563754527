import {
  FETCH_PROFILE,
  FETCH_PROFILE_ERROR,
  FORM_SUBMITTING,
  FORM_SUCCESS,
  INITIALIZE_FORM,
  INITIALIZE_LOADER,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  RESET_FORM_SUBMITTING,
  RESET_LOADING,
  RESET_PROFILE_DETAIL,
  SET_PROFILE_DETAIL,
  SET_PROFILE_UPDATE,
  SET_TIMEZONE_LIST,
  SIGNOUT_SUCCESS
} from "../common/types";
const initialState = {
  error: "",
  login_info: {},
  showModel: false,
  updateP: {},
};

const loaderState = {
  loading: false,
};

const formState = {
  success: false,
  submitting: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        login_info: { ...action.payload },
      };
    case SIGNOUT_SUCCESS:
      return state;
    case SET_PROFILE_UPDATE:
      return { ...state, showModel: action.payload };

    case REGISTER_SUCCESS:
      return {
        ...state,
        registrationSuccess: true,
      };

    default:
      return state;
  }
};

export const loaderReducer = (state = loaderState, action) => {
  switch (action.type) {
    case INITIALIZE_LOADER:
      return { ...state, loading: true };
    case RESET_LOADING:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export const formReducer = (state = formState, action) => {
  switch (action.type) {
    case INITIALIZE_FORM:
      return {
        ...state,
        success: false,
      };
    case FORM_SUCCESS:
      return {
        ...state,
        success: true,
      };
    case FORM_SUBMITTING:
      return {
        ...state,
        submitting: true,
      };
    case RESET_FORM_SUBMITTING:
      return {
        ...state,
        submitting: false,
      };
    default:
      return state;
  }
};
export const profileReducer = (
  state = { loading: false, details: {} },
  action
) => {
  switch (action.type) {
    case FETCH_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case SET_PROFILE_DETAIL:
      return {
        ...state,
        details: { ...action.payload },
        loading: false,
      };

    case FETCH_PROFILE_ERROR:
      return {
        ...state,
        details: {},
        loading: false,
      };
    case RESET_PROFILE_DETAIL:
      return {
        ...state,
        details: {},
        loading: false,
      };

    default:
      return state;
  }
};
export const timezoneReducer = (state = { timezones: [] }, action) => {
  switch (action.type) {
    case SET_TIMEZONE_LIST:
      return {
        ...state,
        timezones: [...action.payload],
      };

    default:
      return state;
  }
};

export default authReducer;
