import React from "react";
import { Modal } from "react-bootstrap";
import LobbyTimer from "../../../common/LobbyTimer";
import EmptyTimer from "../../../common/EmptyTimer";

export const WaitingLobbyModal = ({ lobby_info, onHide, show }) => {
  return (
    <div>
      <Modal
        centered
        className="border-0 border-radius-0 lobby_modal"
        show={show}
        backdrop
      >
        <Modal.Body className="border-0 p-0">
          <div className="pass_word_c">
            {lobby_info.time_diffrence > 0 ? (
              <LobbyTimer time_diffrence={Number(lobby_info.time_diffrence)} />
            ) : (
              <EmptyTimer />
            )}
            <p className="mb-5">
              Waiting for more players, 5 minutes has been added to the waiting
              time
            </p>
          </div>

          <div className="main_btn  mt-3 bg__black">
            <button className="w-100 black__btn" onClick={onHide}>
              Return
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
