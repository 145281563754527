import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getGuidlines } from "../../../store/actions/termAction";
const Privacy = () => {
  const dispatch = useDispatch();
  const { guidlineData } = useSelector((state) => state.guidline);

  useEffect(() => {
    dispatch(getGuidlines());
  }, [dispatch]);

  return (
    <div className="settiings-term">
      <div
        style={{
          display: "flex",
        }}
      >
        <span>
          <Link className="pe-1" to="/lobby/settings">
            <Icon
              icon="teenyicons:arrow-left-small-outline"
              className="big-icon"
              style={{ fontSize: "36px" }}
            />
          </Link>{" "}
        </span>
        <h2
          style={{
            margin: "auto",
          }}
        >
          Privacy Policy
        </h2>
      </div>
      <div
        style={{
          height: "300px",
          overflowY: "auto",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: guidlineData?.privacy?.description,
          }}
        />
      </div>
    </div>
  );
};
export default withRouter(Privacy);
