import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import { Icon } from "@iconify/react";

import { WaitingLobbyModal } from "./Modal/WaitingLobbyModal";

import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  deleteUserFromLobbyOnFirstore,
  getLobby,
} from "../../../store/actions/firebaseAction";
import {
  exitUserFromLobby,
  extendWaitingTimer,
  getLobbyInfo,
  resetLobbyInfo,
  updateGameAttempt,
} from "../../../store/actions/lobbyAction";
import EmptyTimer from "../../common/EmptyTimer";
import LobbyTimer from "../../common/LobbyTimer";
import {
  GAME_STARTING_REQUIREMENT,
  MESSAGES,
  NAVIGATION,
} from "../../common/constant";
import SiteLoader from "../../shared/SiteLoader/SiteLoader";
// import RouteLeavingGuard from "../../../routes/RouteLeavingGuard";
import { joinGame } from "../../../store/actions/gameBoardAction";
import { setGameLobbyInfo } from "../../../store/common/utils";
import {
  TOTAL_ALLOCATE_GAME_TIME,
  TOTAL_DELAYED_TIME,
} from "../../../store/gameBoard/game.constants";

import RouteLeavingGuard from "../../../routes/RouteLeavingGuard";
import { displayTimer } from "../../common/_helper";
import GameStatredModal from "../Userprofile/Modal/GameStatredModal";
import { GameStartRemainingTimeModal } from "./Modal/GameStartRemainingTimeModal";
import { NotEnoughPlayerModal } from "./Modal/NotEnoughPlayerModal";

export const YourLobby = ({
  getLobbyInfo,
  resetLobbyInfo,
  loading,
  lobby_info,
  joined_user_lists,
}) => {
  const [showWaitingModal, setShowWaitingModal] = useState(false);
  const [showAfterTimeModal, setShowAfterTimeModal] = useState(false);
  const [joinRequest, setJoinRequest] = useState(false);

  const [showRemainingTimeModal, setShowRemainingTimeModal] = useState(false);
  const [oneTimeModalOpen, setOneTimeModalOpen] = useState(false);
  const { joining_date, lobby_id } = useParams();

  const [notEnoughPlayer, setNotEnoughPlayer] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const profile_info = useSelector((state) => state.profile.details);
  const [time, setTime] = useState(0);

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const showTimer = useMemo(
    () => displayTimer(lobby_info.time_diffrence - time),
    [time, lobby_info.time_diffrence]
  );

  useEffect(() => {
    if (
      showTimer !== "0:0" &&
      moment(showTimer, "HH:mm").isSameOrBefore(moment("00:45", "HH:mm")) &&
      oneTimeModalOpen === false
    ) {
      setShowRemainingTimeModal(true);
    }
  }, [showRemainingTimeModal, showTimer, oneTimeModalOpen]);

  useEffect(() => {
    if (
      lobby_info.attempt >
        GAME_STARTING_REQUIREMENT.WAITING_TIME_EXTENDED_LIMIT &&
      lobby_info.joined_user < GAME_STARTING_REQUIREMENT.MINIMUM_PARTICOPANT
    )
      history.push("/lobby");
  }, [history, lobby_info.attempt, lobby_info.joined_user]);

  useEffect(() => {
    getLobbyInfo({ tbl_id: lobby_id, date: joining_date });
    return () => resetLobbyInfo();
  }, [getLobbyInfo, joining_date, lobby_id]);

  let unsbscribe;

  async function getFirebaseData(lobbyId) {
    unsbscribe = await getLobby(lobbyId, dispatch);
  }

  useEffect(() => {
    if (lobby_info && lobby_info.lobby_id) {
      getFirebaseData(lobby_info.lobby_id);
    }
    return () => {
      if (unsbscribe) unsbscribe();
    };
  }, [lobby_info.lobby_id]);

  //current timer
  const actionHandler = (_currentTime, setTime) => {
    if (
      lobby_info.joined_user < GAME_STARTING_REQUIREMENT.MINIMUM_PARTICOPANT
    ) {
      if (
        lobby_info.attempt <
        GAME_STARTING_REQUIREMENT.WAITING_TIME_EXTENDED_LIMIT
      ) {
        setTime(0);
        console.log("GAME SHOULD EXTEND");
        dispatch(extendWaitingTimer());
        updateGameAttempt({
          lobby_id: lobby_info.lobby_id,
          attempt_status: lobby_info.attempt + 1,
        })
          .then((result) => {
            getLobbyInfo({ tbl_id: lobby_id, date: joining_date });
          })
          .catch((err) => {});
        setShowWaitingModal(true);
      } else {
        //game can't be started
        console.log("GAME CAN't be start");
        setNotEnoughPlayer(true);
      }
    } else {
      // game can be start
      console.log("GAME can be start");
      //api call with redirect game screen
      // sessionStorage.removeItem("attempWaitingLimit");
      if (!joinRequest) {
        // Check if join request has already been made
        handleGameStartedApi();
      }
    }
  };

  //function to handle game started api
  const handleGameStartedApi = () => {
    setJoinRequest(true);
    dispatch(joinGame(lobby_info.joined_user))
      .then((lobby_id) => {
        // if (res) history.push(NAVIGATION.GAME_BOARD);
        if (lobby_id) {
          //set lobby information in session before redirect on game screen
          setGameLobbyInfo(lobby_id);
          history.push({
            pathname: NAVIGATION.GAME_BOARD,
            search:
              `?` +
              new URLSearchParams({
                gameId: lobby_id,
                startGameTime: Math.round(Date.now() / 1000),
                endGameTime:
                  Math.round(Date.now() / 1000) +
                  7 * TOTAL_ALLOCATE_GAME_TIME +
                  TOTAL_DELAYED_TIME,
                // currentHand: 1,
              }).toString(),
          });
        }
      })
      .finally(() => setJoinRequest(false));
  };

  return (
    <>
      <div className="settiings open_prf">
        {loading || joinRequest ? <SiteLoader /> : null}
        {/* <button onClick={() => handleGameStartedApi()}>start</button> */}
        <RouteLeavingGuard
          when={true}
          navigate={async (path) => {
            exitUserFromLobby({
              lobby_id: lobby_info.lobby_id,
              user_id: profile_info.id,
            });
            history.push(path);
            await deleteUserFromLobbyOnFirstore(
              lobby_info.lobby_id,
              profile_info.id
            );
          }}
          shouldBlockNavigation={(location) => {
            if (location.pathname === "/lobby/game-board") return false;
            return true;
          }}
          yes="Yes"
          no="No"
          message={MESSAGES.LOBBY_LEAVE_MSG}
        />
        <h5>
          <span>
            <Link className="pe-1" to="/lobby">
              <Icon icon="teenyicons:arrow-left-small-outline" />
            </Link>
            Your Lobby
          </span>
          {lobby_info.joined_user}/{lobby_info.total_user} Players
          <div className="w-80">
            <div className=""></div>
          </div>
        </h5>
        <div className="transaction_his">
          <h4>Start Time: {lobby_info.time}</h4>
          {lobby_info.time_diffrence > 0 ? (
            <LobbyTimer
              time_diffrence={Number(lobby_info.time_diffrence)}
              actionHandler={actionHandler}
            />
          ) : (
            <EmptyTimer />
          )}
        </div>
        <div style={{ paddingInline: "20px" }}>
          <ul className="wallet_list">
            {joined_user_lists.map((user, i) => (
              <li key={i}>
                <div className="game_detail_card">
                  <h3 className="mb-0">{user.user_name}</h3>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {showWaitingModal && (
          <WaitingLobbyModal
            show={showWaitingModal}
            onHide={() => {
              setShowWaitingModal(false);
            }}
            lobby_info={lobby_info}
          />
        )}
        {showRemainingTimeModal ? (
          <GameStartRemainingTimeModal
            show={showRemainingTimeModal}
            onHide={() => {
              setShowRemainingTimeModal(false);
              setOneTimeModalOpen(true);
            }}
          />
        ) : null}
        {notEnoughPlayer && (
          <NotEnoughPlayerModal
            show={notEnoughPlayer}
            onHide={() => {
              setNotEnoughPlayer(false);
              history.push(NAVIGATION.LOBBY);
            }}
          />
        )}
      </div>
      <GameStatredModal
        show={showAfterTimeModal}
        onHide={() => setShowAfterTimeModal(false)}
        actionHandler={actionHandler}
        time_diffrence={lobby_info.time_diffrence}
      />
    </>
  );
};

const mapStateToProps = ({
  waitingLobby: { loading, lobby_info, joined_user_lists },
}) => {
  return {
    loading,
    lobby_info,
    joined_user_lists,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLobbyInfo: (payload) => dispatch(getLobbyInfo(payload)),
    resetLobbyInfo: () => dispatch(resetLobbyInfo()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(YourLobby);
