import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";
import imageurl from "../../../common/images";
export const UpdateProfilePic = (props) => {
  const [newImage, setNewImage] = useState(null);
  const onDrop = useCallback((acceptedFiles) => {
    const image = acceptedFiles[0];
    props.setImageFile(image);
    let reader = new FileReader();
    reader.onload = (e) => {
      setNewImage(e.target.result);
    };
    reader.readAsDataURL(image);
  }, []);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    props.handleFile(fileUploaded);
  };
  return (
    <div>
      <Modal
        className="border-0 border-radius-0"
        show={props.show}
        onHide={props.onHide}
      >
        <Modal.Header className="border-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0 p-0">
          <div className="pass_word_c">
            <span>
              {newImage ? (
                <img
                  src={newImage ? newImage : imageurl.profilrdefault}
                  alt=""
                  className="update__profile"
                />
              ) : (
                <img
                  src={props.image ? props.image : imageurl.profilrdefault}
                  alt=""
                  className="update__profile"
                />
              )}
            </span>
            <h5>Update your photo?</h5>
          </div>
          <Dropzone
            onDrop={(file) => {
              onDrop(file);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="profile_image_upload" {...getRootProps()}>
                <input {...getInputProps()} />
                <span>Drag & drop an image or</span>
                <button>Choose file</button>
              </div>
            )}
          </Dropzone>

          <div className="main_btn close_kl bank_btn mt-3">
            <button onClick={props.onHide} className="">
              Close
            </button>
            <button className="" onClick={() => props.saveImage(newImage)}>
              <span>Update Photo</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
