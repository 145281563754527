export const displayTimer = (time_seconds) => {
  let hours = Math.floor(time_seconds / 3600);
  let minutes = Math.floor((time_seconds % 3600) / 60);
  let seconds = time_seconds % 60;
  let difference = time_seconds;
  let timeLeft = { hours: 0, minutes: 0, seconds: 0 };
  if (difference > 0) {
    timeLeft = {
      hours: hours.toString().length === 1 ? "0" + hours : hours,
      minutes: minutes.toString().length === 1 ? "0" + minutes : minutes,
      seconds: seconds.toString().length === 1 ? "0" + seconds : seconds,
    };
  }

  if (hours > 0)
    return `${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`;
  else return `${timeLeft.minutes}:${timeLeft.seconds}`;
};
