import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import imageurl from "../../common/images";
// import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  getGameResults,
  getRankInGame,
} from "../../../store/actions/gameBoardAction";
import { getGameLobbyInfo } from "../../../store/common/utils";
import { NAVIGATION } from "../../common/constant";
import SiteLoader from "../../shared/SiteLoader/SiteLoader";
import RankModal from "../../models/RankModal";

const GameResultsBreakDown = ({
  history,
  loading,
  lists,
  getGameResults,
  lobby_info,
  getRankInGame,
}) => {
  const { lobbyId } = useParams();
  let { current_session_lobby } = getGameLobbyInfo();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (current_session_lobby === null || !lobbyId) {
      history.push(NAVIGATION.LOBBY);
      return;
    }
    if (lobbyId !== current_session_lobby) {
      history.push(NAVIGATION.LOBBY);
      return;
    }

    getGameResults(lobbyId);
  }, [lobbyId, getGameResults]);

  useEffect(() => {
    getRankInGame(lobbyId);
  }, [getRankInGame, lobbyId]);

  return (
    <div>
      <div className="settiings open_prf">
        <div className="mani_pur">
          <h4 className="title_mani">
            <img src={imageurl.res} alt="" className="mx-3" />
            <span>Results</span>
          </h4>
          <div className="poni">
            <span>{lobby_info.date || ""}</span>
            <span>{lobby_info.time || ""}</span>
          </div>
        </div>
        {loading ? <SiteLoader /> : null}

        <ul className="list-style-none">
          <li>
            <div className="game_detail_card ">
              <div className="nine_game_result">YOUR PLACEMENT</div>
              <span>HAND 1</span>
              <span>HAND 2</span>
              <span>HAND 3</span>
              <span>HAND 4</span>
              <span>HAND 5</span>
              <span>HAND 6</span>
              <span>HAND 7</span>
            </div>
          </li>
          {lists && lists.length > 0 && (
            <li>
              <div
                style={{ background: "#E6EBF0", color: "#06B506" }}
                className="game_detail_card lotery_list points"
              >
                <div className="nine_game_result">
                  <span>1</span>
                  <h3 className="mb-0">{lists[0].user_name}</h3>
                </div>
                <span>{lists[0].round_1}</span>
                <span>{lists[0].round_2}</span>
                <span>{lists[0].round_3}</span>
                <span>{lists[0].round_4}</span>
                <span>{lists[0].round_5}</span>
                <span>{lists[0].round_6}</span>
                <span>{lists[0].round_7}</span>
              </div>
            </li>
          )}
        </ul>

        <ul className="gaming_list  p-3">
          {lists &&
            lists.length > 0 &&
            lists.map((list, i) => (
              <li key={`result__${i}`}>
                <div
                  className={`game_detail_card lotery_list ${
                    list.status === 1 && "points"
                  }`}
                  style={{ color: list.status === 1 ? "#06B506" : "" }}
                >
                  <div className="nine_game_result">
                    <span>{i + 1}</span>
                    <h3 className="mb-0">{list.user_name}</h3>
                  </div>
                  <span>{list.round_1}</span>
                  <span>{list.round_2}</span>
                  <span>{list.round_3}</span>
                  <span>{list.round_4}</span>
                  <span>{list.round_5}</span>
                  <span>{list.round_6}</span>
                  <span>{list.round_7}</span>
                </div>
              </li>
            ))}
        </ul>

        <ul className="gaming_list">
          <li>
            <div className="row">
              <div className="col-6 ">
                <button
                  className=""
                  onClick={() => history.push("/lobby/history")}
                  style={{
                    width: "100%",
                    padding: "10px",
                    background: "#E6EBF0",
                    border: "1px solid ",
                  }}
                >
                  Back
                </button>
              </div>
              <div className="col-6">
                <button
                  onClick={() => setShow(true)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    background: "#000000",
                    color: "#fff",
                  }}
                >
                  <span>See OverAll</span>
                </button>
              </div>
            </div>

            {/* <div className="main_btn close_kl bank_btn mt-3">
              <button
                className=""
                onClick={() => history.push(NAVIGATION.LOBBY)}
              >
                Next
              </button>
              <button onClick={() => history.push(`/lobby/results/${lobbyId}`)}>
                <span>See OverAll</span>
              </button>
            </div> */}
          </li>
        </ul>
      </div>
      {show && <RankModal show={show} handleClose={() => setShow(false)} />}
    </div>
  );
};

const mapStateToProps = ({ game: { lists, loading, lobby_info } }) => {
  return {
    loading,
    lists,
    lobby_info,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getGameResults: (payload) => dispatch(getGameResults(payload)),
    getRankInGame: (payload) => dispatch(getRankInGame(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GameResultsBreakDown));
