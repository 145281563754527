import {
  TOTAL_NUMBER_OF_CARDS,
  TOTAL_NUMBER_OF_DECK_CARDS,
} from "../../../../store/gameBoard/game.constants";

export const getOffSet = (index, offset) => {
  return index > 0 ? index * offset : 0;
};

export const numberOfCard = (data) => {
  let sum = 0;
  for (let d of data) sum += d.length;
  let remaining = TOTAL_NUMBER_OF_CARDS - sum;
  return remaining;
};

export const numberOfRemaingDeckCards = (data, idsArray) => {
  let sum = 0;
  for (let d of data) {
    if (d.length) {
      sum += d.filter((card) => idsArray.includes(card.id)).length;
    }
  }
  let remaining = TOTAL_NUMBER_OF_DECK_CARDS - sum;
  return remaining;
};

// 1. The deck on top has a total of 24 cards. Each card that is pulled, and is placed in the rows below or in the card sorter to the left, should be subtracted from the 24.
export const numberOfReamingDeckCardsCount = (deckPile) => {
  let currentDechPileCardCount = deckPile.length;
  let flipFromDeckPileCardCount =
    TOTAL_NUMBER_OF_DECK_CARDS - currentDechPileCardCount;
  //return remaining deck file card count
  return TOTAL_NUMBER_OF_DECK_CARDS - flipFromDeckPileCardCount;
};

// find remaining card logic 26-04-2024
export const removeMatchingCards = (allCard, foundationCard) => {
  const foundationIds = foundationCard.flatMap((stack) =>
    stack.map((card) => card.id)
  );
  return allCard.filter((card) => !foundationIds.includes(card.id));
};
