import React from "react";
import { Modal } from "react-bootstrap";

export const ConfirmBox = ({
  yes,
  no,
  visible,
  onCancel,
  onConfirm,
  message,
  title,
}) => {
  const confirm = () => {
    onCancel();
    onConfirm();
  };
  return (
    <div>
      <Modal
        centered
        className="border-0 border-radius-0 lobby_modal"
        show={visible}
        backdrop
      >
        <Modal.Body className="border-0 p-0">
          <div className="pass_word_c">
            <h5 className="mb-1">{title}</h5>
            <p className="mb-5">{message}</p>
          </div>

          <div className="main_btn close_kl bank_btn mt-3">
            <button onClick={() => onCancel()}>{no}</button>
            <button onClick={confirm}> {yes}</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
