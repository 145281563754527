import { Icon } from "@iconify/react";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSession, updateProfile } from "../../../store/actions/authAction";
import { fetchSession, storeSession } from "../../../store/common/utils";
import { Header } from "../../Landingpage/Header";
import BtnSpinner from "../../common/BtnSpinner";
import TextError from "../../common/TextError";
import imageurl from "../../common/images";
import { stepFormValidation } from "../../common/validation";
import "../auth.css";
const title = ["Connect a Bank", "Profile Information", "TimeZone"];

const ProfileInformation = ({
  history,
  updateProfile,
  submitting,
  timezones,
}) => {
  let { is_profile } = getSession();
  const is_stripe = fetchSession("is_stripe");
  const [steps, setSteps] = useState(
    Number(is_stripe) === 0 ? 0 : Number(is_profile) ?? 0
  );
  const [image, setImage] = useState(null);
  const imageRef = useRef(null);
 
  const onFileChange = (event, setFieldValue) => {
    const image = event.target.files[0];
    setFieldValue("image", image);
    let reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(image);
  };

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    if (steps === 1) {
      setSteps(steps + 1);
    } else if (steps === 2) {
      updateProfile(values, resetForm, history);
      storeSession("is_profile", 1);
      history.push("/lobby");
    }
    setSubmitting(false);
  };

  return (
    <div>
      <Header />
      <div className="login_box">
        <div className="grey_box">
          <Container>
            <Row>
              {
                <Col className="p-0">
                  <h5>{title[steps]}</h5>
                  {steps === 0 && (
                    <>
                      <div className="op">
                        <p>
                          Casino Solitaire lets players play head-to-head
                          solitaire games and win big based on performance.
                        </p>
                        <p>
                          Before you start playing, you must first connect a
                          bank account with a minimum of $$$ available.
                        </p>
                      </div>
                      <div className="text-center mb-4">
                        <img src={imageurl.bankimg} alt="" />
                      </div>
                      <div className="d-flex justify-content-center main_btn bank_btn mt-3">
                        <button
                          onClick={() => {
                            // storeSession("is_profile", 1);
                            // if (is_profile === 1) {
                            //   storeSession("sessionType", "LOGIN");
                            //   history.push(NAVIGATION.LOBBY);
                            // } else {
                            //   setSteps(1);
                            // }
                            setSteps(1);
                          }}
                        >
                          Skip
                        </button>
                        <button
                          onClick={() => {
                            history.push({
                              pathname: "/lobby/payment",
                              state: { redirectPath: "/profile" },
                            });
                          }}
                        >
                          <span>
                            Add <b>Money</b>
                          </span>
                        </button>
                      </div>
                    </>
                  )}

                  <Formik
                    initialValues={{
                      first_name: "",
                      last_name: "",
                      image: "",
                      time_zone: "",
                    }}
                    validationSchema={stepFormValidation[steps - 1]}
                    onSubmit={handleSubmit}
                  >
                    {({ handleSubmit, setFieldValue, errors }) => (
                      <Form onSubmit={handleSubmit} className="main_make mb-3">
                        {steps === 1 && (
                          <>
                            <div className="img_pro">
                              <input
                                type="file"
                                hidden
                                ref={imageRef}
                                onChange={(e) => onFileChange(e, setFieldValue)}
                              />
                              <img
                                src={image ? image : imageurl.profilrdefault}
                                alt=""
                              />
                              <span>
                                {" "}
                                <Icon
                                  icon="ri:pencil-line"
                                  onClick={() => imageRef.current.click()}
                                />
                              </span>
                            </div>
                            <div className="text-center">
                              <ErrorMessage
                                name="image"
                                component={TextError}
                              />
                            </div>
                            <Form.Group
                              className="mb-3 group"
                              controlId="formBasicEmail"
                            >
                              <div className="position-relative">
                                <Icon icon="mingcute:user-1-line" />
                                <Field
                                  type="text"
                                  className="form-control"
                                  placeholder="First name"
                                  autoComplete="off"
                                  name="first_name"
                                />{" "}
                              </div>
                              <ErrorMessage
                                name="first_name"
                                component={TextError}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-5 group"
                              controlId="formBasicEmail"
                            >
                              <div className="position-relative">
                                <Icon icon="mingcute:user-1-line" />
                                <Field
                                  type="text"
                                  className="form-control"
                                  placeholder="Last name"
                                  autoComplete="off"
                                  name="last_name"
                                />
                              </div>
                              <ErrorMessage
                                name="last_name"
                                component={TextError}
                              />
                            </Form.Group>
                          </>
                        )}

                        {steps === 2 && (
                          <>
                            <div className="timezone_slect mb-5">
                              {/* <Form.Select aria-label="Default select example"></Form.Select> */}
                              <Field
                                as="select"
                                name="time_zone"
                                className="form-control"
                              >
                                <option value={""}>Pick Your Timezone</option>
                                {timezones.length > 0 &&
                                  timezones.map((list) => (
                                    <option key={list.id} value={list.id}>
                                      {list.time_zone}
                                    </option>
                                  ))}
                              </Field>
                              <i className="arrow down"></i>
                            </div>
                            <ErrorMessage
                              name="time_zone"
                              component={TextError}
                            />
                          </>
                        )}

                        {steps > 0 && (
                          <div className="main_btn">
                            <button
                              className="login_btn"
                              type="submit"
                              disabled={submitting}
                            >
                              {submitting ? (
                                <BtnSpinner />
                              ) : (
                                <span>{steps === 2 ? "Finish" : "Next"}</span>
                              )}
                            </button>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </Col>
              }
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ form, timezone: { timezones } }) => {
  return {
    submitting: form.submitting,
    timezones,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (payload, resetForm, history) =>
      dispatch(updateProfile(payload, resetForm, history)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileInformation));
