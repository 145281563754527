import { combineReducers } from "redux";
import authReducer, {
  loaderReducer,
  formReducer,
  timezoneReducer,
  profileReducer,
} from "./authReducer";
import lobbyReducer, { waitingLobbyReducer } from "./lobbyReducer";
import gameBoardReducer from "../gameBoard/gameBoard.reducer";
import gameReducer, { gameHistoryReducer } from "./gameReducer";
import { lotteryReducer, transactionReducer } from "./userReducer";
import { guidlineReducer } from "./guidlineReducer";
import { paymentReducer } from "./paymentReducer";
// import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import { firebaseReducer } from "react-redux-firebase";
// import { firestoreReducer } from "redux-firestore";
const rootReducer = combineReducers({
  auth: authReducer,
  loader: loaderReducer,
  form: formReducer,
  timezone: timezoneReducer,
  lobby: lobbyReducer,
  profile: profileReducer,
  // firebase: firebaseReducer,
  // firestore: firestoreReducer,
  waitingLobby: waitingLobbyReducer,
  gameBoard: gameBoardReducer,
  game: gameReducer,
  game_history: gameHistoryReducer,
  transaction: transactionReducer,
  lottery: lotteryReducer,
  guidline: guidlineReducer,
  payment:paymentReducer
});

//persist setting
// const persistConfig = {
//   key: "root",
//   storage,
//   whitelist: [
//     // "auth",
//     // "loader",
//     // "form",
//     // "timzone",
//     // "lobby",
//     // "profile",
//     // "waitingLobby",
//     "gameBoard",
//     "game",
//   ],
// };
// export default persistReducer(persistConfig, rootReducer);

export default rootReducer;
