import React from "react";
import {
  CardState,
  typeOfCard,
} from "../../../../../store/gameBoard/game.constants";
import Card from "../Card/Card";
import { useDrop } from "react-dnd";
import { useSelector } from "react-redux";

const getDroppable = (item, nextCard, foundationSuit, columnPile) => {
  const id = item.card?.id;
  if (item && item.card && item.card.state === CardState.TableuPile) {
    if (item.card.id !== columnPile[columnPile.length - 1].id) {
      return false;
    }
  }
  if (id === `${nextCard}${foundationSuit}`) {
    return true;
  }
  return false;
};

const GoalPile = ({ cards, index, foundationSuit, nextCard }) => {
  const nextState = CardState.Foundation;
  const { columnPiles } = useSelector((state) => state.gameBoard);
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: typeOfCard,
    canDrop: (item) =>
      getDroppable(
        item,
        nextCard,
        foundationSuit,
        columnPiles[item.card.index]
      ),
    drop: () => ({
      index,
      nextState,
    }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div ref={drop} className="drop-target unselectable talon-foundation">
      <div className="card-border">
        <img
          alt="foundation"
          src={require(`../../../../../assets/images/${foundationSuit}.png`)}
          className="foundation-suit unselectable"
          onDragStart={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{ alignSelf: "center" }}
          width="24px"
          height="24px"
        />
        {cards?.map((card, i) => (
          <Card
            offset={0}
            key={card.id}
            card={card}
            isLastCard={i === cards.length - 1 ? true : false}
            nCards={1}
          />
        ))}
      </div>
    </div>
  );
};

export default GoalPile;
