import { toast } from "react-toastify";
import { NAVIGATION } from "../../components/common/constant";
import AXIOS, { setToken } from "../common/api_helper";
import { APIs } from "../common/constants";
import {
  FETCH_PROFILE,
  FETCH_PROFILE_ERROR,
  FORM_SUBMITTING,
  FORM_SUCCESS,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  RESET_FORM_SUBMITTING,
  SET_PROFILE_DETAIL,
  SET_PROFILE_UPDATE,
  SET_TIMEZONE_LIST,
  SIGNOUT_SUCCESS,
} from "../common/types";
import { storeSession } from "../common/utils";

export const register =
  (payload, resetForm, history, setCookie, removeCookie) =>
    async (dispatch) => {
      try {
        dispatch({
          type: FORM_SUBMITTING,
        });

        const { data } = await AXIOS.post(APIs.USER_REGISTER, {
          ...payload,

        });
        console.log("User registration", data);
        dispatch({
          type: FORM_SUCCESS,
        });
        dispatch({
          type: RESET_FORM_SUBMITTING,
        });
        if (data.success) {
          dispatch({
            type: REGISTER_SUCCESS,
            payload: {
              ...data.data,
            },
          });

          saveSession(data.data, "REGISTER");
          history.push(NAVIGATION.SUCCESS);
          resetForm();
          clearSession();
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        dispatch({
          type: RESET_FORM_SUBMITTING,
        });
        dispatch({
          type: LOGIN_ERROR,
          payload: {
            error:
              err.response && err.response.data.message
                ? err.response.data.message
                : err.message,
          },
        });
        if (err.response && err.response.status === 401) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
    };
    
export const login =
  (payload, resetForm, history, setCookie, removeCookie) =>
  async (dispatch) => {
    try {
      dispatch({
        type: FORM_SUBMITTING,
      });

      const { data } = await AXIOS.post(APIs.USER_LOGIN, {
        ...payload,
        user_type: "2",
      });
      dispatch({
        type: FORM_SUCCESS,
      });
      dispatch({
        type: RESET_FORM_SUBMITTING,
      });
      if (data.success) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            ...data.data,
          },
        });
        toast.success(data.message);
        resetForm();
        const userInfo = data?.data ?? {};
        if (
          (userInfo.is_stripe === 0 && userInfo.is_profile === 0) ||
          (userInfo.is_stripe === 1 && userInfo.is_profile === 0)
        ) {
          storeSession("is_stripe", userInfo.is_stripe);
          saveSession(
            {
              ...userInfo,
              is_profile:
                userInfo.first_name === null || userInfo.first_name === ""
                  ? 1
                  : 2,
            },
            "REGISTER"
          );
          history.push(NAVIGATION.PROFILE);
        } else if (
          (userInfo.is_stripe === 0 && userInfo.is_profile === 1) ||
          (userInfo.is_stripe === 1 && userInfo.is_profile === 1)
        ) {
          saveSession(
            {
              ...userInfo,
              is_profile: 1,
            },
            "LOGIN"
          );
          history.push(NAVIGATION.LOBBY);
        }
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      dispatch({
        type: RESET_FORM_SUBMITTING,
      });
      dispatch({
        type: LOGIN_ERROR,
        payload: {
          error:
            err.response && err.response.data.message
              ? err.response.data.message
              : err.message,
        },
      });
      if (err.response && err.response.status === 401) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

export const saveSession = (data, type) => {
  sessionStorage.setItem("Authorization", data.token);
  sessionStorage.setItem("id", data.id);
  sessionStorage.setItem("sessionType", type);
  sessionStorage.setItem("is_profile", data.is_profile);
};

export const getSession = () => {
  return {
    Authorization: sessionStorage.getItem("Authorization"),
    user_id: sessionStorage.getItem("id"),
    sessionType: sessionStorage.getItem("sessionType"),
    is_profile: Number(sessionStorage.getItem("is_profile")),
  };
};

export const clearSession = () => {
  sessionStorage.removeItem("Authorization");
  sessionStorage.removeItem("id");
  sessionStorage.removeItem("sessionType");
  sessionStorage.removeItem("is_profile");
  sessionStorage.clear();
  localStorage.clear();
};

export const forgotPassword =
  (payload, resetForm, setResend) => async (dispatch) => {
    try {
      dispatch({
        type: FORM_SUBMITTING,
      });
      const { data } = await AXIOS.post(APIs.FORGOT_PASSWORD, {
        ...payload,
      });
      dispatch({
        type: FORM_SUCCESS,
      });
      dispatch({
        type: RESET_FORM_SUBMITTING,
      });
      if (data.success) {
        localStorage.setItem("reset_email", payload.email);
        if (resetForm) resetForm();
        setResend(true);
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      dispatch({
        type: RESET_FORM_SUBMITTING,
      });
      if (
        err.response &&
        (err.response.status === 412 || err.response.status === 401)
      ) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

export const changePassword = (payload, setShow) => async (dispatch) => {
  try {
    dispatch({
      type: FORM_SUBMITTING,
    });
    const { data } = await AXIOS.post(APIs.RESET_PASSWORD, { ...payload });
    dispatch({
      type: FORM_SUCCESS,
    });
    dispatch({
      type: RESET_FORM_SUBMITTING,
    });
    if (data.success) {
      sessionStorage.removeItem("reset_email");
      setShow(true);
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  } catch (err) {
    dispatch({
      type: RESET_FORM_SUBMITTING,
    });

    if (
      err.response &&
      (err.response.status === 412 || err.response.status === 401)
    ) {
      toast.error(err.response.data.message);
    } else {
      toast.error(err.message);
    }
  }
};

export const logout = (history) => async (dispatch) => {
  dispatch({ type: SIGNOUT_SUCCESS });
  clearSession();
  toast.success("logout successful.");
  history.push("/login");
};

export const getProfileInfo = () => async (dispatch) => {
  try {
    setToken();
    dispatch({ type: FETCH_PROFILE });
    const { data } = await AXIOS.get(`${APIs.GET_PROFILE_INFO}`);

    if (data.success) {
      dispatch({
        type: SET_PROFILE_DETAIL,
        payload: data.data || {},
      });
    } else {
      dispatch({
        type: SET_PROFILE_DETAIL,
        payload: {},
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_PROFILE_ERROR,
      payload: err,
    });
  }
};

export const updateProfile =
  (payload, edit_profile, resetForm, history, setShow = null) =>
  async (dispatch) => {
    try {
      setToken();
      let fd = new FormData();
      for (let key in payload) {
        fd.append(key, payload[key]);
      }
      dispatch({
        type: FORM_SUBMITTING,
      });
      const { data } = await AXIOS({
        method: "post",
        url: APIs.UPDATE_PROFILE_INFO,
        data: fd,
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch({
        type: RESET_FORM_SUBMITTING,
      });
      if (data.success) {
        dispatch(getProfileInfo());
        if (!setShow) toast.success(data.message);
        if (resetForm) resetForm();
        if (setShow) setShow(true);
        sessionStorage.removeItem("is_profile");
        sessionStorage.setItem("sessionType", "LOGIN");

        if (history) history.push(NAVIGATION.LOBBY);

        if (edit_profile === 1) {
          dispatch({
            type: SET_PROFILE_UPDATE,
            payload: true,
          });
        }

        // toast.success(data.message);
        // if (history) history.push(NAVIGATION.PROFILE);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      dispatch({
        type: RESET_FORM_SUBMITTING,
      });
      toast.error(err.response.data.message);
    }
  };

export const modelUpdate = (payload) => async (dispatch) => {
  dispatch({
    type: SET_PROFILE_UPDATE,
    payload: payload,
  });
};

export const updateNewPassword =
  (payload, resetForm, onHide) => async (dispatch) => {
    try {
      dispatch({
        type: FORM_SUBMITTING,
      });
      const { data } = await AXIOS.post(APIs.CHANGE_PASSWORD, {
        ...payload,
      });
      dispatch({
        type: FORM_SUCCESS,
      });
      dispatch({
        type: RESET_FORM_SUBMITTING,
      });

      if (data.success) {
        resetForm();
        // onHide();
        toast.success(data.message);
      }
      if (!data.success) {
        toast.error(data.message);
      }
    } catch (err) {
      dispatch({
        type: RESET_FORM_SUBMITTING,
      });

      if (
        err.response &&
        (err.response.status === 412 || err.response.status === 401)
      ) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

export const getTimezones = () => async (dispatch) => {
  try {
    setToken();
    const { data } = await AXIOS.get(`${APIs.GET_TIMEZONE_LIST}`);
    if (data.success) {
      dispatch({
        type: SET_TIMEZONE_LIST,
        payload: data.data || [],
      });
    }
  } catch (err) {
    toast.error(err.message);
  }
};

export const sendContactQuery =
  (payload, resetForm, history) => async (dispatch) => {
    try {
      setToken();
      dispatch({
        type: FORM_SUBMITTING,
      });
      const { data } = await AXIOS.post(`${APIs.SEND_CONTACT_QUERY}`, {
        ...payload,
      });
      dispatch({
        type: RESET_FORM_SUBMITTING,
      });
      if (data.success) {
        toast.success(data.message);
        resetForm();
        history.push("/lobby/settings");
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      dispatch({
        type: RESET_FORM_SUBMITTING,
      });
      toast.error(err.response.data.message);
    }
  };

export const updateVolumeLevel = (payload) => async (dispatch) => {
  try {
    setToken();
    dispatch({
      type: FORM_SUBMITTING,
    });
    const { data } = await AXIOS.post(`${APIs.UPDATE_VOLUME_LEVEL}`, {
      ...payload,
    });
    dispatch({
      type: RESET_FORM_SUBMITTING,
    });
    return data;
  } catch (err) {
    dispatch({
      type: RESET_FORM_SUBMITTING,
    });
    toast.error(err.response.data.message);
  }
};

export const updateLoteryStatus = (payload) => async (dispatch) => {
  try {
    setToken();
    dispatch({
      type: FORM_SUBMITTING,
    });
    const { data } = await AXIOS.get(`${APIs.UPDATE_LOTERY_STATUS}`);
    dispatch({
      type: RESET_FORM_SUBMITTING,
    });
    return data;
  } catch (err) {
    dispatch({
      type: RESET_FORM_SUBMITTING,
    });
    toast.error(err.response.data.message);
  }
};
