export const sortedCards = {
  foundations: [
    [
      {
        rank: 1,
        suit: "H",
        color: 0,
        hidden: false,
        id: "1H",
        type: "CARD",
        index: 0,
        state: 2,
        isDragging: false,
      },
      {
        rank: 2,
        suit: "H",
        color: 0,
        hidden: false,
        id: "2H",
        type: "CARD",
        index: 0,
        state: 2,
        isDragging: false,
      },
      {
        rank: 3,
        suit: "H",
        color: 0,
        hidden: false,
        id: "3H",
        type: "CARD",
        index: 0,
        state: 2,
        isDragging: false,
      },
      {
        rank: 4,
        suit: "H",
        color: 0,
        hidden: false,
        id: "4H",
        type: "CARD",
        index: 0,
        state: 2,
        isDragging: false,
      },
      {
        rank: 5,
        suit: "H",
        color: 0,
        hidden: false,
        id: "5H",
        type: "CARD",
        index: 0,
        state: 2,
        isDragging: false,
      },
      {
        rank: 6,
        suit: "H",
        color: 0,
        hidden: false,
        id: "6H",
        type: "CARD",
        index: 0,
        state: 2,
        isDragging: false,
      },
      {
        rank: 7,
        suit: "H",
        color: 0,
        hidden: false,
        id: "7H",
        type: "CARD",
        index: 0,
        state: 2,
        isDragging: false,
      },
      {
        rank: 8,
        suit: "H",
        color: 0,
        hidden: false,
        id: "8H",
        type: "CARD",
        index: 0,
        state: 2,
        isDragging: false,
      },
      {
        rank: 9,
        suit: "H",
        color: 0,
        hidden: false,
        id: "9H",
        type: "CARD",
        index: 0,
        state: 2,
        isDragging: false,
      },
      {
        rank: 10,
        suit: "H",
        color: 0,
        hidden: false,
        id: "10H",
        type: "CARD",
        index: 0,
        state: 2,
        isDragging: false,
      },
      {
        rank: 11,
        suit: "H",
        color: 0,
        hidden: false,
        id: "11H",
        type: "CARD",
        index: 0,
        state: 2,
        isDragging: false,
      },
      {
        rank: 12,
        suit: "H",
        color: 0,
        hidden: false,
        id: "12H",
        type: "CARD",
        index: 0,
        state: 2,
        isDragging: false,
      },
      {
        rank: 13,
        suit: "H",
        color: 0,
        hidden: false,
        id: "13H",
        type: "CARD",
        index: 0,
        state: 2,
        isDragging: false,
      },
    ],
    [
      {
        rank: 1,
        suit: "D",
        color: 0,
        hidden: false,
        id: "1D",
        type: "CARD",
        index: 1,
        state: 2,
        isDragging: false,
      },
      {
        rank: 2,
        suit: "D",
        color: 0,
        hidden: false,
        id: "2D",
        type: "CARD",
        index: 1,
        state: 2,
        isDragging: false,
      },
      {
        rank: 3,
        suit: "D",
        color: 0,
        hidden: false,
        id: "3D",
        type: "CARD",
        index: 1,
        state: 2,
        isDragging: false,
      },
      {
        rank: 4,
        suit: "D",
        color: 0,
        hidden: false,
        id: "4D",
        type: "CARD",
        index: 1,
        state: 2,
        isDragging: false,
      },
      {
        rank: 5,
        suit: "D",
        color: 0,
        hidden: false,
        id: "5D",
        type: "CARD",
        index: 1,
        state: 2,
        isDragging: false,
      },
      {
        rank: 6,
        suit: "D",
        color: 0,
        hidden: false,
        id: "6D",
        type: "CARD",
        index: 1,
        state: 2,
        isDragging: false,
      },
      {
        rank: 7,
        suit: "D",
        color: 0,
        hidden: false,
        id: "7D",
        type: "CARD",
        index: 1,
        state: 2,
        isDragging: false,
      },
      {
        rank: 8,
        suit: "D",
        color: 0,
        hidden: false,
        id: "8D",
        type: "CARD",
        index: 1,
        state: 2,
        isDragging: false,
      },
      {
        rank: 9,
        suit: "D",
        color: 0,
        hidden: false,
        id: "9D",
        type: "CARD",
        index: 1,
        state: 2,
        isDragging: false,
      },
      {
        rank: 10,
        suit: "D",
        color: 0,
        hidden: false,
        id: "10D",
        type: "CARD",
        index: 1,
        state: 2,
        isDragging: false,
      },
      {
        rank: 11,
        suit: "D",
        color: 0,
        hidden: false,
        id: "11D",
        type: "CARD",
        index: 1,
        state: 2,
        isDragging: false,
      },
      {
        rank: 12,
        suit: "D",
        color: 0,
        hidden: false,
        id: "12D",
        type: "CARD",
        index: 1,
        state: 2,
        isDragging: false,
      },
      {
        rank: 13,
        suit: "D",
        color: 0,
        hidden: false,
        id: "13D",
        type: "CARD",
        index: 1,
        state: 2,
        isDragging: false,
      },
    ],
    [
      {
        rank: 1,
        suit: "C",
        color: 1,
        hidden: false,
        id: "1C",
        type: "CARD",
        index: 2,
        state: 2,
        isDragging: false,
      },
      {
        rank: 2,
        suit: "C",
        color: 1,
        hidden: false,
        id: "2C",
        type: "CARD",
        index: 2,
        state: 2,
        isDragging: false,
      },
      {
        rank: 3,
        suit: "C",
        color: 1,
        hidden: false,
        id: "3C",
        type: "CARD",
        index: 2,
        state: 2,
        isDragging: false,
      },
      {
        rank: 4,
        suit: "C",
        color: 1,
        hidden: false,
        id: "4C",
        type: "CARD",
        index: 2,
        state: 2,
        isDragging: false,
      },
      {
        rank: 5,
        suit: "C",
        color: 1,
        hidden: false,
        id: "5C",
        type: "CARD",
        index: 2,
        state: 2,
        isDragging: false,
      },
      {
        rank: 6,
        suit: "C",
        color: 1,
        hidden: false,
        id: "6C",
        type: "CARD",
        index: 2,
        state: 2,
        isDragging: false,
      },
      {
        rank: 7,
        suit: "C",
        color: 1,
        hidden: false,
        id: "7C",
        type: "CARD",
        index: 2,
        state: 2,
        isDragging: false,
      },
      {
        rank: 8,
        suit: "C",
        color: 1,
        hidden: false,
        id: "8C",
        type: "CARD",
        index: 2,
        state: 2,
        isDragging: false,
      },
      {
        rank: 9,
        suit: "C",
        color: 1,
        hidden: false,
        id: "9C",
        type: "CARD",
        index: 2,
        state: 2,
        isDragging: false,
      },
      {
        rank: 10,
        suit: "C",
        color: 1,
        hidden: false,
        id: "10C",
        type: "CARD",
        index: 2,
        state: 2,
        isDragging: false,
      },
      {
        rank: 11,
        suit: "C",
        color: 1,
        hidden: false,
        id: "11C",
        type: "CARD",
        index: 2,
        state: 2,
        isDragging: false,
      },
      {
        rank: 12,
        suit: "C",
        color: 1,
        hidden: false,
        id: "12C",
        type: "CARD",
        index: 2,
        state: 2,
        isDragging: false,
      },
      {
        rank: 13,
        suit: "C",
        color: 1,
        hidden: false,
        id: "13C",
        type: "CARD",
        index: 2,
        state: 2,
        isDragging: false,
      },
    ],
    [
      {
        rank: 1,
        suit: "S",
        color: 1,
        hidden: false,
        id: "1S",
        type: "CARD",
        index: 3,
        state: 2,
        isDragging: false,
      },
      {
        rank: 2,
        suit: "S",
        color: 1,
        hidden: false,
        id: "2S",
        type: "CARD",
        index: 3,
        state: 2,
        isDragging: false,
      },
      {
        rank: 3,
        suit: "S",
        color: 1,
        hidden: false,
        id: "3S",
        type: "CARD",
        index: 3,
        state: 2,
        isDragging: false,
      },
      {
        rank: 4,
        suit: "S",
        color: 1,
        hidden: false,
        id: "4S",
        type: "CARD",
        index: 3,
        state: 2,
        isDragging: false,
      },
      {
        rank: 5,
        suit: "S",
        color: 1,
        hidden: false,
        id: "5S",
        type: "CARD",
        index: 3,
        state: 2,
        isDragging: false,
      },
      {
        rank: 6,
        suit: "S",
        color: 1,
        hidden: false,
        id: "6S",
        type: "CARD",
        index: 3,
        state: 2,
        isDragging: false,
      },
      {
        rank: 7,
        suit: "S",
        color: 1,
        hidden: false,
        id: "7S",
        type: "CARD",
        index: 3,
        state: 2,
        isDragging: false,
      },
      {
        rank: 8,
        suit: "S",
        color: 1,
        hidden: false,
        id: "8S",
        type: "CARD",
        index: 3,
        state: 2,
        isDragging: false,
      },
      {
        rank: 9,
        suit: "S",
        color: 1,
        hidden: false,
        id: "9S",
        type: "CARD",
        index: 3,
        state: 2,
        isDragging: false,
      },
    ],
  ],
  columnPiles: [
    [
      {
        rank: 10,
        suit: "S",
        color: 1,
        hidden: false,
        id: "10S",
        type: "CARD",
        index: 0,
        state: 0,
        isDragging: false,
      },
    ],
    [
      {
        rank: 11,
        suit: "S",
        color: 1,
        hidden: false,
        id: "11S",
        type: "CARD",
        index: 1,
        state: 0,
        isDragging: false,
      },
    ],
    [
      {
        rank: 12,
        suit: "S",
        color: 1,
        hidden: false,
        id: "12S",
        type: "CARD",
        index: 2,
        state: 0,
        isDragging: false,
      },
    ],
    [
      {
        rank: 13,
        suit: "S",
        color: 1,
        hidden: false,
        id: "13S",
        type: "CARD",
        index: 3,
        state: 0,
        isDragging: false,
      },
    ],
    [],
    [],
    [],
  ],
  deckPile: [],
  flippedPile: [],

  // gameMoves: 10,
  // gameTime: 0,
  gamePreviousMoves: [],
  gameNextMoves: [],
  isGameOver: false,
  isGameStart: true,
  // currentGameHand: 1,
};
