import React from "react";
import ColumnPile from "../Piles/ColumnPile";
import { useSelector } from "react-redux";

const GameColumnWrapper = () => {
  const { columnPiles } = useSelector((state) => state.gameBoard);
  return (
    <div className="mini_open_op">
      <div className="mine_craft w-100">
        {columnPiles.map((columnPile, i) => (
          <ColumnPile cards={columnPile} key={i.toString()} index={i} />
        ))}
      </div>
    </div>
  );
};

export default GameColumnWrapper;
