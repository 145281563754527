import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { sliderimages } from "../../store/common/utils";


export const SliderPlay = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setCurrentIndex(selectedIndex);
  };

  const handlePrev = () => {
    if (currentIndex === 0) {
      setCurrentIndex(sliderimages.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex === sliderimages.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };


  return (

    <div className="min_lamp">
      <div className="play_ins">
        <Carousel
          fade
          wrap={false}
          touch={true}
          interval={3000}
          activeIndex={currentIndex} 
          onSelect={handleSelect}
          data-aos="fade-left"
          data-aos-duration="3000"
          className="mb-3 how_to_play_slider"
        >
          {sliderimages.map((item) => (
            <Carousel.Item key={item.id}>
              <div className="katil">
                <div className="row">
                  <div className="col-sm-8">
                    <div className="caption h5">{item.content}</div>
                    <div className="mt-3">
                      {item.head1 && (
                        <div style={{ color: "red", textAlign: "left" }}>
                          {item.head1}
                        </div>
                      )}
                      <ul className="mt-2">
                        {item.li99 && <li style={{ textAlign: 'left' }}>{item.li99} <strong>{item.strong1}</strong></li>}
                        {item.li88 && <li style={{ textAlign: 'left' }}>{item.li88}<strong>{item.strong2}</strong></li>}
                        {item.li77 && <li style={{ textAlign: 'left' }}>{item.li77}<strong>{item.strong2}</strong></li>}
                        {item.li66 && <li style={{ textAlign: 'left' }}>{item.li66}<strong>{item.strong2}</strong></li>}
                        {item.li55 && <li style={{ textAlign: 'left' }}>{item.li55}<strong>{item.strong2}</strong></li>}
                        {item.li44 && <li style={{ textAlign: 'left' }}>{item.li44}<strong>{item.strong2}</strong></li>}
                        {item.li33 && <li style={{ textAlign: 'left' }}>{item.li33}<strong>{item.strong2}</strong></li>}
                        {item.li22 && <li style={{ textAlign: 'left' }}>{item.li22}<strong>{item.strong1}</strong></li>}
                        {item.li11 && <li style={{ textAlign: 'left' }}>{item.li11}<strong>{item.strong2}</strong></li>}

                      </ul>
                      {item.head2 && (
                        <div style={{ color: "red", textAlign: "left" }}>
                          {item.head2}
                        </div>
                      )}
                      <ul className="mt-2">
                        {item.li3 && (
                          <li style={{ textAlign: 'left' }}>
                            {item.li3} <strong>{item.strong3}</strong>
                          </li>
                        )}
                        {item.li4 && (
                          <li style={{ textAlign: 'left' }}>
                            {item.li4} <strong>{item.strong4}</strong>{item.li1000}
                          </li>
                        )}
                      </ul>  {
                        item.head3 && (
                          <div style={{ color: "red", textAlign: "left" }}>
                            {item.head3}
                          </div>
                        )}
                      <div style={{ textAlign: 'left', marginTop: 4 }}>{item.text}</div>
                      <ul className="mt-2">
                        {item.li0 && (
                          <li style={{ textAlign: 'left' }}>
                            {item.li0} <strong>{item.strong4}</strong>
                          </li>
                        )}
                        {item.li01 && (
                          <li style={{ textAlign: 'left' }}>
                            {item.li01} <strong>{item.strong4}</strong>
                          </li>
                        )}  {item.li02 && (
                          <li style={{ textAlign: 'left' }}>
                            {item.li02} <strong>{item.strong4}</strong>
                          </li>
                        )}  {item.li03 && (
                          <li style={{ textAlign: 'left' }}>
                            {item.li03} <strong>{item.strong4}</strong>
                          </li>
                        )}  {item.li04 && (
                          <li style={{ textAlign: 'left' }}>
                            {item.li04} <strong>{item.strong4}</strong>
                          </li>
                        )}  {item.li05 && (
                          <li style={{ textAlign: 'left' }}>
                            {item.li05} <strong>{item.strong4}</strong>
                          </li>
                        )}

                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <img className="d-block w-100" src={item.image} alt="" />
                  </div>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>


      </div>
    </div>
  );
};
