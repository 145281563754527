import { toast } from "react-toastify";
import AXIOS, { setToken } from "../common/api_helper";
import { APIs, LOBBY_GAME_STATUS } from "../common/constants";
import {
  FETCH_GAME_HISTORY_ERROR,
  FETCH_GAME_HISTORY_LIST,
  FETCH_GAME_RESULT_ERROR,
  FETCH_GAME_RESULT_LIST,
  FETCH_GAME_ROUND_TIME_DIFFRENCE,
  FETCH_GAME_ROUND_TIME_DIFFRENCE_ERROR,
  FETCH_RANK_INFO,
  FETCH_RANK_INFO_ERROR,
  SET_GAME_HISTORY_LIST,
  SET_GAME_RESULT_LIST,
  SET_GAME_ROUND_TIME_DIFFRENCE,
  SET_RANK_INFO,
} from "../common/types";
import { getTempLobbyId, setGameLobbyInfo } from "../common/utils";
import gameBoardActions from "../gameBoard/gameBoard.actions";
import { updateLobbyOnFirstore } from "./firebaseAction";
import { TOTAL_ALLOCATE_GAME_HAND } from "../gameBoard/game.constants";
import { getProfileInfo } from "./authAction";

export const joinGame = (user_count) => async (dispatch) => {
  try {
    setToken();
    let lobby_id = getTempLobbyId();
    const { data } = await AXIOS.post(`${APIs.JOIN_GAME}`, {
      status: LOBBY_GAME_STATUS.STARTED,
      lobby_id,
      user_count: user_count,
    });
    if (data.success) {
      setGameLobbyInfo(lobby_id);
      dispatch(getProfileInfo());
      return lobby_id;
    } else {
      toast.error(data.message);
      return false;
    }
  } catch (err) {
    toast.error(err.message);
  }
};

//function to stored score after each round
export const storeGameScore = (payload) => async (dispatch, getState) => {
  try {
    let {
      profile: { details },
    } = getState();

    // setToken();
    const { data } = await AXIOS.post(APIs.SAVE_ROUND_SCORE, {
      ...payload,
    });
    if (data.success) {
      dispatch(gameBoardActions.storeScoreHistory(payload));
      //store data on firebase as well as
      if (payload.round_no === TOTAL_ALLOCATE_GAME_HAND)
        await updateLobbyOnFirstore(payload.lobby_id, {
          user_id: details.id,
          user_name: details.name,
          ...payload,
        });
    } else {
      // toast.error(data.message);
    }
  } catch (err) {
    toast.error(err.message);
  }
};

//function to get result records

export const getGameResults = (lobbyId) => async (dispatch) => {
  try {
    setToken();
    dispatch({ type: FETCH_GAME_RESULT_LIST });
    const { data } = await AXIOS.get(`${APIs.GET_GAME_RESULT}/${lobbyId}`);

    if (data.success) {
      dispatch({
        type: SET_GAME_RESULT_LIST,
        payload: data.data || [],
        lobby_info: data.lobby_info || { time: "", date: "" },
      });
    } else {
      dispatch({
        type: SET_GAME_RESULT_LIST,
        payload: [],
        lobby_info: data.lobby_info || { time: "", date: "" },
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_GAME_RESULT_ERROR,
      payload: err,
    });
  }
};

//function to get history records
export const getGameHistories = () => async (dispatch) => {
  try {
    setToken();
    dispatch({ type: FETCH_GAME_HISTORY_LIST });
    const { data } = await AXIOS.get(`${APIs.GET_GAME_HISTORY}`);

    if (data.success) {
      dispatch({
        type: SET_GAME_HISTORY_LIST,
        payload: data.data || [],
      });
    } else {
      dispatch({
        type: SET_GAME_HISTORY_LIST,
        payload: [],
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_GAME_HISTORY_ERROR,
      payload: err,
    });
  }
};

//function to complete game
export const completeGame = () => async (dispatch) => {
  try {
    let lobby_id = getTempLobbyId();
    const { data } = await AXIOS.post(`${APIs.JOIN_GAME}`, {
      status: LOBBY_GAME_STATUS.COMPLETE,
      lobby_id,
    });
    if (data.success) {
      dispatch(getProfileInfo());
      return lobby_id;
    } else {
      toast.error(data.message);
      return false;
    }
  } catch (err) {
    toast.error(err.message);
  }
};

export const updateWinLossAmount = async () => {
  try {
    let lobby_id = getTempLobbyId();
    const { data } = await AXIOS.get(
      `${APIs.UPDATE_WIN_LOSS_AMOUNT}/${lobby_id}`
    );
    if (data.success) {
      return data;
    } else {
      toast.error(data.message);
      return false;
    }
  } catch (err) {
    toast.error(err.message);
  }
};

export const getRankInGame = (lobbyId, setShow) => async (dispatch) => {
  try {
    setToken();
    dispatch({ type: FETCH_RANK_INFO });
    const { data } = await AXIOS.get(`${APIs.GET_RANK_IN_GAME}/${lobbyId}`);
    if (data.success) {
      dispatch({
        type: SET_RANK_INFO,
        payload: data.data || {},
      });
    } else {
      dispatch({
        type: SET_RANK_INFO,
        payload: {},
      });
    }
    return data;
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_RANK_INFO_ERROR,
      payload: err,
    });
  }
};

export const getGameRoundTimeDiffrence = (lobbyId) => async (dispatch) => {
  try {
    setToken();
    dispatch({ type: FETCH_GAME_ROUND_TIME_DIFFRENCE });
    const { data } = await AXIOS.get(`${APIs.GET_GAME_ROUND_TIME}/${lobbyId}`);
    if (data.success) {
      dispatch({
        type: SET_GAME_ROUND_TIME_DIFFRENCE,
        payload: data?.data?.timer || 0,
      });
      dispatch(
        gameBoardActions.setGameCurrentHand(data?.data?.complete_round + 1)
      );
    } else {
      dispatch({
        type: SET_GAME_ROUND_TIME_DIFFRENCE,
        payload: {},
      });
    }
    return data;
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_GAME_ROUND_TIME_DIFFRENCE_ERROR,
      payload: err,
    });
  }
};
