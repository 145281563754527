import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { Icon } from "@iconify/react";
import {
  getProfileInfo,
  updateVolumeLevel,
} from "../../store/actions/authAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const VolumeControllModal = ({ show, handleClose }) => {
  const [volumeLevel, setVolumeLevel] = useState(0.5);
  const dispatch = useDispatch();

  // Define your debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Wrap your handleChangeVolume function with debounce
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleChangeVolume = useCallback(
    debounce((newVolume) => {
      dispatch(updateVolumeLevel({ sound: newVolume }))
        .then((result) => {
          if (result.success) {
            dispatch(getProfileInfo());
          } else {
            toast.error(result.message);
          }
        })
        .catch((err) => {});
    }, 500), // Adjust delay as needed
    [dispatch]
  );

  // Function to handle volume change
  const handleChangeVolume = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolumeLevel(newVolume);
    debouncedHandleChangeVolume(newVolume); // Call the debounced version of handleChangeVolume
  };

  return (
    <>
      <Modal
        className="border-0 border-radius-0 custom_modal"
        show={show}
        centered
        size="md"
        onHide={handleClose}
      >
        <Modal.Body className="border-0">
          <div className="row">
            <div className="col-sm-5">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  maxWidth: "150px",
                  height: "60px",
                  background: "#E6EBF0",
                }}
              >
                <h4 className="mb-0">Volume</h4>
              </div>
            </div>
            <div className="col-sm-7">
              <div className="d-flex align-items-center justify-content-center gap-2 h-100">
                <Icon
                  icon="fa:volume-up"
                  style={{ fontSize: "30px", color: "#000" }}
                />
                <input
                  className="custom-range"
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={volumeLevel}
                  onChange={handleChangeVolume}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VolumeControllModal;
