import React from "react";
import { Modal } from "react-bootstrap";
import imageurl from "../common/images";
import { useHistory } from "react-router-dom";

const TransferSuccessModal = ({ show, amount, handleClose }) => {
  const history = useHistory();
  return (
    <>
      <Modal
        className="border-0 border-radius-0 custom_modal"
        show={show}
        centered
        size="md"
        onHide={handleClose}
      >
        <Modal.Body className="border-0 p-0">
          <div className="p-5">
            <div className="text-center">
              <img
                src={imageurl.success_tick}
                alt="success-icon"
                className="m-auto mb-3"
              />
              <h5>Successfully transferred:</h5>
              <h4 className="amount pb-4 mb-3 dashed_border_bottom">
                ${amount}
              </h4>

              <p>Transactions may take 3-5 business days.</p>
            </div>
          </div>
          <div className="d-flex align-items-center main_btn close_kl button bank_btn">
            <button onClick={handleClose}>Close</button>
            <button
              onClick={() => {
                history.push("/lobby/my-wallet");
              }}
            >
              View Transactions
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransferSuccessModal;
