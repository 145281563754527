// import { Button, Col, Form, Row } from "react-bootstrap";
// import { storeSession } from "../store/common/utils";
// import { Link, useHistory, useLocation } from "react-router-dom";

// import { imageObj } from "../utiles/images";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { getSession } from "../store/actions/authAction";
// import { paymentWallet } from "../store/actions/paymentAction";

import "./Css-file.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import SiteLoader from "./shared/SiteLoader/SiteLoader";
import FundAddedModal from "./models/FundAddedModal";
import "bootstrap/dist/css/bootstrap.min.css";
import imageurl from "./common/images";

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const currentMonth = new Date().getMonth() + 1;
// const currentYear = new Date().getFullYear();
// const phoneRegExp =
//   /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4}[ \-]*)){0,1}([0-9]{3,4}[ \-]*){1,2}[0-9]{3,4}$/;

function PaymentMood() {
  // const history = useHistory();
  // const { state } = useLocation();
  // const { redirectPath } = state ?? "";
  // const dispatch = useDispatch();
  // let { user_id } = getSession();

  const [open, setOpen] = useState();
  const { loading } = useSelector((state) => state.payment);
  // const formik = useFormik({
  //   initialValues: {
  //     card_number: "",
  //     card_exp_month: "",
  //     card_exp_year: "",
  //     card_cvc: "",
  //     amount: "",
  //     user_id: user_id,
  //     card_holder: "",
  //   },

  //   validationSchema: Yup.object({
  //     card_number: Yup.string()
  //       .required("Card number is required")
  //       .matches(phoneRegExp, "Card number is not valid"),



  //     card_exp_year: Yup.string()
  //       .max(4, "Expire year must be at most 4 characters")
  //       .min(4, "Expire year must be at least 4 characters")
  //       .required("Expire year is required")
  //       .test("is-valid-year", "Please fill in a valid year", (value) => {
  //         const numericValue = parseInt(value, 10);
  //         return !isNaN(numericValue) && numericValue >= currentYear;
  //       }),

  //     card_exp_month: Yup.number()
  //       .typeError("Please enter a valid month")
  //       .integer("Please enter a valid month")
  //       .min(1, "Month must be between 1 and 12")
  //       .max(12, "Month must be between 1 and 12")
  //       .required("Expire month is required"),


  //     card_cvc: Yup.string()
  //       .max(4, "CVV number must be at most 4 characters")
  //       .min(3, "CVV number must be at least 3 characters")
  //       .required("CVV number is required"),
  //     amount: Yup.string().required("Amount is required"),

  //     card_holder: Yup.string().required("Name is required"),
  //   }),

  //   onSubmit: (values, action) => {
  //     dispatch(paymentWallet(values, history, redirectPath))
  //       .then((result) => {
  //         if (result.success) {
  //           if (redirectPath === "/profile") {
  //             storeSession("is_stripe", 1);
  //             storeSession("is_profile", 1);
  //             history.push("/profile");
  //           } else {
  //             setOpen(true);
  //             action.resetForm();
  //           }
  //         } else {
  //           setOpen(false);
  //         }
  //       })
  //       .catch((err) => { });
  //   },
  // });

  // useEffect(() => {
  //   if (paymentData.status) {
  //     history.push('/lobby')
  //   }
  // }, [paymentData])

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <section className="card-box-section d-flex justify-content-center pb-5 login_box">
        <div
          className=" card_box_1 d-flex align-items-center justify-content-center mt-5 "
          style={{ background: "white" }}
        >
          {loading ? <SiteLoader /> : null}
          <div className="card_box_2  mb-2">
            {/* <Form onSubmit={formik.handleSubmit}>
          

              <Form.Group>
                <Form.Label
                  className=" heading_label p-2 "
                  style={{ fontSize: "18px", color: "#000" }}
                >
                  Card Number{" "}
                  <span style={{ fontSize: "12px" }}>
                    The 16 digits on front of your card
                  </span>
                </Form.Label>

                <Form.Control
                  type="text"
                  name="card_number"
                  value={formik.values.card_number}
                  onChange={formik.handleChange}
                  minLength={12}
                  maxLength={16}
                />

                {formik.touched.card_number && formik.errors.card_number && (
                  <span className="text-danger">
                    {formik.errors.card_number}
                  </span>
                )}
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label
                      className="heading_label p-2 "
                      style={{ fontSize: "18px" }}
                    >
                      Month / Year
                    </Form.Label>
                    <div className="d-flex align-items-center gap-2">
                      <div>
                        <Form.Control
                          type="text"
                          name="card_exp_month"
                          value={formik.values.card_exp_month}
                          onChange={formik.handleChange}
                          placeholder="Month"
                          minLength={2}
                          maxLength={2}
                        />

                        {formik.touched.card_exp_month &&
                          formik.errors.card_exp_month && (
                            <span className="text-danger">
                              {formik.errors.card_exp_month}
                            </span>
                          )}
                      </div>

                      <div>
                        <Form.Control
                          type="text"
                          name="card_exp_year"
                          value={formik.values.card_exp_year}
                          onChange={formik.handleChange}
                          minLength={4}
                          maxLength={4}
                          placeholder="Year"
                        />
                        {formik.touched.card_exp_year &&
                          formik.errors.card_exp_year && (
                            <span className="text-danger">
                              {formik.errors.card_exp_year}
                            </span>
                          )}
                      </div>
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label
                      className="heading_label p-2"
                      style={{ fontSize: "18px" }}
                    >
                      CVV
                    </Form.Label>
                    <div className="d-flex gap-4 ">
                      <Form.Control
                        className="mb-5"
                        type="text"
                        name="card_cvc"
                        value={formik.values.card_cvc}
                        onChange={formik.handleChange}
                        placeholder="CVV"
                        minLength={3}
                        maxLength={4}
                      />

                    </div>
                    {formik.touched.card_cvc && formik.errors.card_cvc && (
                      <span className="text-danger">
                        {formik.errors.card_cvc}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group>
                <Form.Label
                  className="heading_label p-2"
                  style={{ fontSize: "18px" }}
                >
                  Full Name on a Card
                </Form.Label>
                <Form.Control
                  type="text"
                  name="card_holder"
                  value={formik.values.card_holder}
                  onChange={formik.handleChange}
                  placeholder="Enter Holder Name"
                />
                {formik.touched.card_holder && formik.errors.card_holder && (
                  <span className="text-danger">
                    {formik.errors.card_holder}
                  </span>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label
                  className="heading_label p-2"
                  style={{ fontSize: "18px" }}
                >
                  Amount
                </Form.Label>
                <Form.Control
                  type="text"
                  name="amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  placeholder="Enter amount"
                />
                {formik.touched.amount && formik.errors.amount && (
                  <span className="text-danger">{formik.errors.amount}</span>
                )}
              </Form.Group>

              <Row>
                <Col>
                  <Link
                    to={redirectPath ? redirectPath : "/lobby/my-wallet"}
                    style={{
                      width: "90%",
                      marginTop: "10px",
                      background: "#E6EBF0",
                      color: "#000000",
                      fontWeight: "bold",
                    }}
                    className="btn btn-primary"
                  >
                    Cancel
                  </Link>
                </Col>
                <Col>
                  <Button
                    type="submit"
                    style={{
                      width: "90%",
                      marginTop: "10px",
                      marginLeft: "30px",
                      background: "#000",
                    }}
                  >
                    Complete Transaction{" "}
                  </Button>
                </Col>
              </Row>
            </Form> */}
            <img style={{ width: "25%", }} src={imageurl.coin} alt="" />

            <div className="h4">
              Currency & Funds
            </div>
            <div className="h6 text-center">
              As of the current version of Casino Solitiare, all funds used within the platform are game currency,not USD currency.
            </div>
          </div>
        </div>
      </section>
      <FundAddedModal show={open} handleClose={handleClose} />
    </>
  );
}

export default PaymentMood;
