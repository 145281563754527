import React from "react";
import { Cardplaceholder } from "./Cardplaceholder";
import { Cards } from "./Cards";

export const Gameboard = () => {
  return (
    <div>
      <div className="game_bord">
        <div className="open_border_bord">
          <div className="open_border_bord">
            <div className="w-100">
              <div className="mini_open_op mb-3">
                <Cardplaceholder />
                <div className="mine_craft">
                  <Cards /> <Cards />
                </div>
              </div>
              <div className="mini_open_op">
                <div className="mine_craft w-100">
                  <Cards /> <Cards /> <Cards /> <Cards /> <Cards /> <Cards />{" "}
                  <Cards />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="design_box"></div>
      </div>
      <span className="time_red_watch">51:22</span>
    </div>
  );
};
