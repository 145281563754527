import React from "react";
import { Router } from "./routes/Router";
import ConnectionLostModal from "./components/models/ConnectionLostModal";
import LotteryModal from "./components/models/LotteryModal";
import { useSelector } from "react-redux";

const App = () => {
  const profile = useSelector((state) => state.profile.details);
  return (
    <div className="App">
      <Router />
      <ConnectionLostModal />
      <LotteryModal show={profile?.lottery_status ?? false} />
    </div>
  );
};

export default App;
