import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import imageurl from "../../common/images";
import { LogoutModal } from "../Userprofile/Modal/LogoutModal";
const Settings = ({ history }) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  return (
    <div className="settiings">
      <h5>
        <span >Settings</span>
      </h5>
      <ul className="list-style-none">
        <li>
          <Link to="/lobby/settings/accountsetting">
            <img src={imageurl.acc_set} alt="" />
            <span>Account Settings</span>
          </Link>
        </li>
        <li>
          <Link to="/lobby/settings/trem-condition">
            <img src={imageurl.terms} alt="" />
            <span>Terms & Conditions</span>
          </Link>
        </li>
        <li>
          <Link to="/lobby/settings/privacy">
            <img src={imageurl.privacy} alt="" />
            <span>Privacy Policy</span>
          </Link>
        </li>
        <li>
          <Link to="/lobby/settings/contactus">
            <img src={imageurl.contact} alt="" />
            <span>Contact Us</span>
          </Link>
        </li>
        <li>
          <div
            // to="/login"
            // as={Button}
            // onClick={() => dispatch(logout(history))}
            style={{ cursor: "pointer" }}
          >
            <img
              src={imageurl.logout}
              alt=""
              style={{ width: "100%", maxWidth: "30px" }}
            />
            <span className="ms-3" onClick={() => setShowLogoutModal(true)}>
              Log Out
            </span>
          </div>
        </li>
      </ul>
      {/* {loading ? <SiteLoader /> : null} */}
      {showLogoutModal && (
        <LogoutModal
          show={showLogoutModal}
          onHide={() => setShowLogoutModal(false)}
        />
      )}
      {/* title={["Your funds were", "added to your wallet."]} */}
    </div>
  );
};
export default withRouter(Settings);
