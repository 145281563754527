const NAVIGATION = {
  PROFILE: "/profile",
  DASHBOARD: "/dashboard",
  LOBBY: "/lobby",
  YOUR_LOBBY: "/lobby/your-lobby",
  LOGIN: "/login",
  GAME_BOARD: "/lobby/game-board",
  GAME_RESULT: "/lobby/results",
  SUCCESS:"/registration-success"
  
};
const AUTO_REFRESH_LIMIT = 1000 * 60 * 5; //5 minutes

const LOBBY_STATUS = {
  // WAITING: "1",
  // FINISHED: "2",
  // RUNNING: "3",
  WAITING: 0, //use for join
  RUNNING: 1, //use for running /in progress
  FINISHED: 2,
  NOT_STARTED: 3,
  JOINED: 4,
};

const LOBBY_BUTTON_STATUS = {
  0: "JOIN",
  1: "IN PROGRESS",
  2: "COMPLETED",
  3: "CLOSED",
  4: "",
};

//Waiting
//Running
//Not Started
//Finished
const LOBBY_STATUS_CLASS = {
  Waiting: "status_success",
  Running: "",
  "Not Started": "",
  Finished: "status_mini",
};

const GAME_STARTING_REQUIREMENT = {
  MINIMUM_PARTICOPANT: 2, //Minimum Player limit per game
  MAXIMUM_PARITCIPANT: 3, //maximum player limit per game
  WAITING_TIME_EXTENDED_LIMIT: 2, //If minimum player did not joined the game will extend for 5 min for 2 times
  WAITING_EXTENDED_MINUTES: 5 * 60, // this is calculation of 5 min
};

const MESSAGES = {
  LOBBY_LEAVE_MSG: "Are you sure you want to leave?",
};

const TRANSACTION_STATUS = {
  PAYIN: "1",
  PAYOUT: "2",
  GAME_PAYOUT: "3",
  LOBBY_REIMBURSMENT: "4",
  JOIN_LOBBY: "5",
};

export {
  NAVIGATION,
  AUTO_REFRESH_LIMIT,
  LOBBY_STATUS,
  LOBBY_STATUS_CLASS,
  LOBBY_BUTTON_STATUS,
  GAME_STARTING_REQUIREMENT,
  MESSAGES,
  TRANSACTION_STATUS,
};
