import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {} from "react-bootstrap";
import { Icon } from "@iconify/react";
import { connect } from "react-redux";
import { getTransactionLists } from "../../../store/actions/userAction";
import SiteLoader from "../../shared/SiteLoader/SiteLoader";

const YourTransaction = ({ loading, lists, getTransactionLists }) => {
  useEffect(() => {
    getTransactionLists();
  }, [getTransactionLists]);

  return (
    <div className="settiings my-5">
      <h5>
        <span>
          <Link className="pe-1" to="/lobby/my-wallet">
            <Icon icon="teenyicons:arrow-left-small-outline" />
          </Link>{" "}
          Your Transactions
        </span>
      </h5>
      {loading ? <SiteLoader /> : null}
      <div className="transaction_his">
        <h4>
          <Icon icon="bytesize:filter" />
          <span>Recent</span>
        </h4>
      </div>

      <ul className="wallet_list transactionlist">
        {lists &&
          lists.length > 0 &&
          lists.map((list, i) => (
            <li key={`transaction_${i}`}>
              <div className="game_detail_card">
                <h3 className="mb-0">{list.msg}</h3>
                <div className="nine_game">
                  <div className="loc_box" style={{ textAlign: "right" }}>
                    <span>{list.date}</span>
                    {list.type === "2" ? (
                      <h4 className="time_red ">-${list.amount}</h4>
                    ) : list.type === "3" ? (
                      list.game_status === "loser" ? (
                        <h4 className="time_red ">-${list.amount}</h4>
                      ) : (
                        <h4 className="points">+${list.amount}</h4>
                      )
                    ) : (
                      <h4 className="points">+${list.amount}</h4>
                    )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        {lists.length === 0 && (
          <li className="text-center">No transaction found</li>
        )}
      </ul>
    </div>
  );
};

const mapStateToProps = ({ transaction: { lists, loading } }) => {
  return {
    loading,
    lists,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTransactionLists: (payload) => dispatch(getTransactionLists(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(YourTransaction);
