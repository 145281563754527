import imageurl from "../../components/common/images";
import { SESSION_KEYS } from "./constants";

export const storeSession = (key, value) => sessionStorage.setItem(key, value);

export const fetchSession = (key) => sessionStorage.getItem(key);

export const storeTempLobbyId = (value) => {
  sessionStorage.setItem(SESSION_KEYS.TEMP_LOBBY_UNIQUE_KEY, value);
};

export const getTempLobbyId = () =>
  sessionStorage.getItem(SESSION_KEYS.TEMP_LOBBY_UNIQUE_KEY);

export const setGameLobbyInfo = (lobby_id) => {
  sessionStorage.setItem(
    SESSION_KEYS.GAME_BOARD_LOBBY_UNIQUE_IDENTIFIER,
    lobby_id
  );
  sessionStorage.setItem(SESSION_KEYS.GAME_CURRENT_HAND_COUNT, 1);
};

export const getGameLobbyInfo = () => {
  return {
    lobby_unique_identifier: sessionStorage.getItem(
      SESSION_KEYS.GAME_BOARD_LOBBY_UNIQUE_IDENTIFIER
    ),
    current_session_lobby: sessionStorage.getItem(
      SESSION_KEYS.TEMP_LOBBY_UNIQUE_KEY
    ),
    lobby_game_current_hand: Number(
      sessionStorage.getItem(SESSION_KEYS.GAME_CURRENT_HAND_COUNT) || 1
    ),
  };
};

export const resetGameLobbyInfo = () => {
  sessionStorage.removeItem(SESSION_KEYS.GAME_BOARD_LOBBY_UNIQUE_IDENTIFIER);
  sessionStorage.removeItem(SESSION_KEYS.GAME_CURRENT_HAND_COUNT);
};

export const updateCurrentHand = (val) => {
  sessionStorage.setItem(SESSION_KEYS.GAME_CURRENT_HAND_COUNT, val);
};

//set toggle sound value
export const getToggleSoundVal = (val) =>
  sessionStorage.getItem(SESSION_KEYS.SET_SOUND_ON_OFF);

export const getOrdinalSuffix = (number) => {
  const j = number % 10,
    k = number % 100;
  if (j === 1 && k !== 11) {
    return number + "st";
  }
  if (j === 2 && k !== 12) {
    return number + "nd";
  }
  if (j === 3 && k !== 13) {
    return number + "rd";
  }
  return number + "th";
};

export const sliderimages = [
  {
    id: "1.",
    image: imageurl.banner1,
    content: "Game Setup & Entry",
    head1: "Number Of Players",
    head2: "Entry Fee and Wallet Requirements",
    li11: 'Maximum: ',
    li22: 'Minimum: ',
    li3: '  Each player pays an entry fee of',
    li4: '  Each player must have at least',
    strong3: "$10.00",
    strong4: "$300.00",
    strong1: "10 Players",
    strong2: "31 Players",
    li1000: ' in their wallet to participate.'
  },
  {
    id: "2.",
    image: imageurl.banner2,
    content: "Game Structure",
    head1: 'Hands and Rounds',
    li11: "The number of cards left in each player's deck is counted at the end of each hand.",
    li22: "Each hand is played until no more moves can be made (the game is blocked).",
    li33: "The game consists of seven (7) hands.",
  },
  {
    id: "3.",
    image: imageurl.banner3,
    content: "Scoring and Card Value",
    head1: "Card Value",
    head2: "Collecting Payments",
    head3: "Hands and Rounds",
    li11: "Each card is valued at ",
    li3: "At the end of the game (after seven hands), the player with the least number of cards collects $1.00 per card from players with more cards.",
    li01: 'The game consists of seven (7) hands.',
    li02: 'Each hand is played until no more moves can be made (the game is blocked).',
    li03: "The number of cards left in each player's deck is counted at the end of each hand.",
    strong2: "$1.00"
  },
  {
    id: "4.",
    image: imageurl.banner4,
    content: "Dealing Hands",
    head1: "Dealing Options",
    li11: "The 7th hand must be played.",
    li22: "Players can choose to erase and redeal their hand up to six times if they are unsatisfied.",
    li33: "Each player can deal themselves up to seven (7) hands at the start of the game.",

  },
  {
    id: "5.",
    image: imageurl.banner5,
    content: "Winning and Points System",
    head1: "Point Distribution",
    head2: "True Solitaire Wins",
    head3: "Ties",
    li99: "Least number of cards: 100 points",
    li88: "2nd least number of cards: 75 points",
    li77: "3rd least number of cards: 50 points",
    li66: "4th least number of cards: 25 points",
    li55: "5th least number of cards: 20 points",
    li44: "6th least number of cards: 15 points",
    li33: "7th least number of cards: 10 points",
    li22: "8th least number of cards: 5 points",
    li11: "Winning a true Solitaire: 100 points",
    li3: "Winning a true Solitaire earns the player $10.00 from each other player.",
    li4: "10% of this total goes to the lottery pool.",
    li0: "Players who tie receive the same points.",
  },
  {
    id: "6.",
    image: imageurl.banner6,
    content: "Lottery Pool & Payouts",
    head1: "Lottery Pool Contributions",
    head2: "Payout structure",
    head3: "Example Payouts",
    li11: "10% of the payments made for true Solitaire wins go into the lottery pool.",
    li3: "The lottery is paid out to players when the pool reaches a certain amount, based on points accumulated.",
    li01: "1st Place: 25%",
    li02: "2nd Place: 20%",
    li03: "3rd Place: 15%",
    li04: "4th Place: 10%",
    li05: "5th Place: 5%",
    text: 'Of a $10,000.00 total pool:'
  },
  {
    id: "7.",
    image: imageurl.banner7,
    content: "Ways to Win",
    head1: "Winning Conditions",
    li33: "Have the least number of cards at the end of the game.",
    li22: "Win a true Solitaire.",
    li11: "Accumulate the most points when the lottery is paid out.",

  },
];