import { toast } from "react-toastify";
import AXIOS, { setToken } from "../common/api_helper";
import { APIs } from "../common/constants";

import { LIST_GUIDLINE } from "../common/types";

export const getGuidlines = () => async (dispatch) => {
  try {
    setToken();
    const { data } = await AXIOS.get(`${APIs.GET_GUIDLINE_LIST}`);

    if (data.success) {
      dispatch({
        type: LIST_GUIDLINE,
        payload: data.data,
      });
    }
  } catch (err) {
    toast.error(err.message);
  }
};
