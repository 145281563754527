import React from "react";

export const Cardplaceholder = () => {
  return (
    <div className="crd_main">
      <div className="card_put"></div>
      <div className="card_put"></div>
      <div className="card_put"></div>
      <div className="card_put"></div>
    </div>
  );
};
