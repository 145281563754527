import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getProfileInfo,
  updateLoteryStatus,
} from "../../store/actions/authAction";
import { toast } from "react-toastify";

const LotteryModal = ({ show }) => {
  const history = useHistory();
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const handleUpdateStatus = () => {
    dispatch(updateLoteryStatus())
      .then((result) => {
        if (result.success) {
          dispatch(getProfileInfo());
        } else {
          toast.error("Something went wrong! sfdsfdsf");
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <Modal
        className="border-0 border-radius-0 custom_modal"
        show={show}
        centered
        backdrop="static"
        size="md"
      >
        <Modal.Body className="border-0 p-0">
          <div className="p-5">
            <div className="text-center">
              <h6 className="mb-2">Congratulations!</h6>
              <h5 className="mb-3">Lottery Winner!</h5>
              <p>NEXT POOL ENDS MONDAY 5/21</p>
              <h4 className="amount ">${profile.lottery_amount}</h4>
              <p className="pb-4 mb-3 dashed_border_bottom">
                has been added to your balance.
              </p>

              <p className="mt-3">
                If the balance was not updated, try refreshing the page.
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center main_btn close_kl button bank_btn">
            <button onClick={handleUpdateStatus}>Close</button>
            <button
              onClick={() => {
                history.push("/lobby/lottery");
              }}
            >
              View Transactions
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LotteryModal;
