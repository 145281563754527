import React from "react";
import { Modal } from "react-bootstrap";
import {
  DEALY_AFTER_GAME_OVER,
  TOTAL_ALLOCATE_GAME_HAND,
  TOTAL_ALLOCATE_GAME_TIME,
} from "../../../../../store/gameBoard/game.constants";
import CountDownTimer from "../../../../common/CountDownTimer";
import EmptyTimer from "../../../../common/EmptyTimer";
import { displayTimer } from "../../../../common/_helper";
import { useSelector } from "react-redux";

const GameOverModal = ({ show, onHide, info }) => {
  const initialCount =
    info.gameOverType !== "OVER"
      ? TOTAL_ALLOCATE_GAME_TIME - info.gameTime
      : DEALY_AFTER_GAME_OVER;

  const { stockPiles, foundations } = useSelector(({ gameBoard }) => ({
    foundations: gameBoard.foundations,
    stockPiles: gameBoard.stockPiles,
  }));

  function removeMatchingCards(allCard, foundationCard) {
    const foundationIds = foundationCard.flatMap((stack) =>
      stack.map((card) => card.id)
    );
    return allCard.filter((card) => !foundationIds.includes(card.id));
  }

  const updatedDeckpile = removeMatchingCards(stockPiles, foundations);

  // useEffect(() => {
  //   let interval;
  //   if (show) {
  //     interval = setInterval(() => {
  //       setCount((prevTime) => prevTime - 1);
  //     }, 1000);
  //   }
  //   return () => {
  //     clearInterval(interval);
  //     console.log("CLEARED COUNT DOWN MODAL");
  //   };
  // }, [show]);

  // useEffect(() => {
  //   if (count <= 0) {
  //     onHide();
  //   }
  // }, [count, onHide]);

  return (
    <>
      <Modal
        centered
        className="border-0 border-radius-0 lobby_modal"
        show={show}
        backdrop
      >
        <Modal.Body className="border-0 p-0">
          <CountDownTimer initialCount={initialCount} onHide={onHide} />
          {info.numberOfCardLeft > 0 && (
            <div className="pass_word_c p_0">
              <EmptyTimer time={displayTimer(info.gameTime)} />
              <p>
                Hand {info.currentGameHand} of {TOTAL_ALLOCATE_GAME_HAND}
              </p>
              <p>Moves: {info.gameMoves}</p>
              {/* <p>Cards left: {info.numberOfCardLeft}</p> */}
              <p>Cards left: {updatedDeckpile.length}</p>
              <p className="mt-3">Time’s up, proceed to next hand</p>
            </div>
          )}

          {info.numberOfCardLeft === 0 && (
            <div className="pass_word_c p_0">
              <p className="mt-3"> Congratulations! Solitaire Winner! </p>
              <EmptyTimer time={displayTimer(info.gameTime)} />
            </div>
          )}

          <div className="main_btn  mt-3 bg__black">
            <button className="w-100 black__btn" onClick={onHide}>
              Continue to next hand
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GameOverModal;
