import React from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { HeroSection } from "./HeroSection";
import { PlaySolitire } from "./PlaySolitire";
import "./landingpage.css";

export const Landingpagelayout = () => {
  return (
    <div>
      <Header />
      {/* <Fullpage>
        <FullPageSections>
          <FullpageSection className="page_web"> */}
            <HeroSection />
          {/* </FullpageSection> */}
         <div id="play">
         {/* <FullpageSection  className="page_web"> */}
            <PlaySolitire />
          {/* </FullpageSection> */}
         </div>
          {/* <FullpageSection */}
          
          {/* className="page_web"
          > */}
            <Footer />
          {/* </FullpageSection>
        </FullPageSections>
      </Fullpage> */}
    </div>
  );
};
