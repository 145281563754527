import { Icon } from "@iconify/react";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { updateProfile } from "../../../store/actions/authAction";
import BtnSpinner from "../../common/BtnSpinner";
import TextError from "../../common/TextError";
import imageurl from "../../common/images";
import {
  updateProfileValidation
} from "../../common/validation";
import { UpdateProfilePic } from "./Modal/UpdateProfilePic";
const EditProfile = ({ updateProfile, submitting, profile_info }) => {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { showModel } = useSelector((state) => state.auth);

  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    if (profile_info && profile_info.profile_img)
      setImage(profile_info.profile_img);
  }, [profile_info]);

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    updateProfile(
      { ...values, image: imageFile },
      1,
      resetForm,
      null,
      null,
      setShow
    );
    setSubmitting(false);
  };

  const resetModal = () => {
    setShowModal(false);
    setImage(profile_info.profile_img);
  };

  const saveImage = (newImage) => {
    setShowModal(false);
    if (newImage) setImage(newImage);
  };

  const history = useHistory();

  useEffect(() => {
    if (showModel) {
      history.push("/lobby/profile");
    }
  }, [showModel]);

  return (
    <div>
      <div className="settiings ">
        <h5>
          <span>Edit Profile</span>
        </h5>
        <Formik
          enableReinitialize={true}
          initialValues={{
            first_name: profile_info.first_name || "",
            last_name: profile_info.last_name || "",
            image: "",
          }}
          validationSchema={updateProfileValidation}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, setFieldValue, errors }) => (
            <Form onSubmit={handleSubmit} className="main_make mb-5">
              <div className="edit_pro ">
                <div className="img_pro">
                  <img src={image ? image : imageurl.profilrdefault} alt="" />
                  <span onClick={() => setShowModal(true)}>
                    {" "}
                    <Icon icon="ri:pencil-line" />
                  </span>
                </div>
                <div className="main_pot">
                  <Form.Group className="mb-3 group" controlId="formBasicEmail">
                    <Icon icon="mingcute:user-1-line" />
                    <Field
                      type="text"
                      className="form-control"
                      placeholder="First name"
                      autoComplete="off"
                      name="first_name"
                    />{" "}
                    <ErrorMessage name="first_name" component={TextError} />
                  </Form.Group>
                  <Form.Group className=" group" controlId="formBasicEmail">
                    <Icon icon="mingcute:user-1-line" />
                    <Field
                      type="text"
                      className="form-control"
                      placeholder="Last name"
                      autoComplete="off"
                      name="last_name"
                    />{" "}
                    <ErrorMessage name="last_name" component={TextError} />
                  </Form.Group>
                </div>
              </div>
              <div className="main_btn save_pro bank_btn mt-3  m-auto">
                <Link to="/lobby/profile">
                  {" "}
                  <button
                    type="button"
                    style={{ color: "#242424" }}
                    className=""
                  >
                    Cancel
                  </button>
                </Link>
                <button type="submit" disabled={submitting}>
                  {submitting ? <BtnSpinner /> : <span>Save</span>}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {/* {show && (
          <Profilesuccess
            show={show}
            onHide={() => setShow(false)}
            // history={history}
            h={show}
          />
        )} */}

        {showModal && (
          <UpdateProfilePic
            show={showModal}
            image={image}
            setImage={setImage}
            setImageFile={setImageFile}
            onHide={resetModal}
            saveImage={saveImage}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ form, profile: { details } }) => {
  return {
    submitting: form.submitting,
    profile_info: details,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (payload, resetForm, history, setShow) =>
      dispatch(updateProfile(payload, resetForm, history, setShow)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
